export const productCategoryForm = {
  name: {
    fieldName: "name",
    label: "Name / Category Code",
    inputType: "text",
    placeholder: "Enter the name or code of the product category",
    defaultValue: "",
    config: {
      required: "Name is required",
      validate: (value) => value.length >= 2 || "Name is required and must be at least 2 characters long",
    },
  },
  description: {
    fieldName: "description",
    label: "Description",
    inputType: "textarea",
    placeholder: "Enter the product category description",
    defaultValue: "",
    config: {
      required: "Description is required",
      validate: (value) => value.length >= 2 || "Description is required and must be at least 2 characters long",
    },
  },
};
