import { useCallback, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Input, InputNumber, Select } from "antd";
import { AddressAutofill } from "@mapbox/search-js-react";
import { MaskedInput } from "antd-mask-input";

export const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

export const DynamicField = ({ inputType, fieldName, defaultValue, placeholder, value, options, config, optionsPass, control, setValue, ...rest }) => {
  const { register } = useFormContext();

  useEffect(() => {
    if (inputType === "address") {
      const accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
      config.accessToken = accessToken;
    }
  }, []);

  const handleRetrieve = useCallback((res, fieldName) => {
    let tmpFeature = res.features[0];
    setValue(fieldName + ".city", tmpFeature.properties.address_level2);
    setValue(fieldName + ".state", tmpFeature.properties.address_level1);
    setValue(fieldName + ".zip", tmpFeature.properties.postcode);
  });

  if (inputType === "divider") {
    return <div className="w-full my-2 border-t border-gray-200" />;
  } else if (inputType === "divider-label") {
    return (
      <div className="flex flex-row items-center justify-center w-full gap-3 my-2">
        <div className="w-full border-t border-gray-200" />
        <p className="w-full text-sm font-medium text-center uppercase min-w-fit">{rest.label}</p>
        <div className="w-full border-t border-gray-200" />
      </div>
    );
  } else if (inputType === "label") {
    return <p className="w-full text-sm font-semibold text-center text-gray-600 uppercase">{rest.label}</p>;
  } else if (inputType === "text") {
    return (
      <Controller
        control={control}
        name={fieldName}
        rules={config}
        defaultValue={defaultValue}
        render={(props) => (
          <Input
            placeholder={placeholder}
            ref={props.field.ref}
            name={fieldName}
            onBlur={props.field.onBlur}
            onChange={(e) => {
              props.field.onChange(e.target.value);
            }}
            defaultValue={value}
            disabled={rest.disabled}
            {...props}
            className="block w-full px-4 py-1.5 font-sans text-sm border-gray-300 rounded-md focus:border-blue-500 focus:ring-0"
          />
        )}
      />
    );
  } else if (inputType === "address") {
    return (
      <div className="flex flex-col items-start justify-start w-full gap-2">
        <Controller
          control={control}
          name={fieldName + ".address1"}
          rules={config}
          defaultValue={value && value.address1 ? value.address1 : null}
          render={(props) => (
            <AddressAutofill
              accessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              onRetrieve={(r) => handleRetrieve(r, fieldName)}
              options={{ language: "en", country: "US" }}
              className="w-full"
            >
              <Input
                placeholder="Start typing the address"
                ref={props.field.ref}
                name={fieldName + ".address1"}
                onBlur={props.field.onBlur}
                onChange={(e) => {
                  props.field.onChange(e.target.value);
                }}
                defaultValue={value && value.address1 ? value.address1 : null}
                autoComplete="address-line1"
                id="mapbox-autofill"
                {...props}
                className="w-full px-4 py-1.5 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
              />
            </AddressAutofill>
          )}
        />
        <Controller
          control={control}
          name={fieldName + ".address2"}
          rules={config}
          defaultValue={value && value.address2 ? value.address2 : null}
          render={(props) => (
            <Input
              placeholder="Apartment, suite, etc. (optional)"
              ref={props.field.ref}
              name={fieldName + ".address2"}
              onBlur={props.field.onBlur}
              onChange={(e) => {
                props.field.onChange(e.target.value);
              }}
              defaultValue={value && value.address2 ? value.address2 : null}
              {...props}
              className="w-full px-4 py-1.5 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
              autoComplete="address-line2"
            />
          )}
        />
        <div className="flex items-center justify-between w-full gap-2">
          <Controller
            control={control}
            name={fieldName + ".city"}
            rules={config}
            defaultValue={value && value.city ? value.city : null}
            render={(props) => (
              <Input
                placeholder="City"
                ref={props.field.ref}
                name={fieldName + ".city"}
                onBlur={props.field.onBlur}
                onChange={(e) => {
                  props.field.onChange(e.target.value);
                }}
                defaultValue={value && value.city ? value.city : null}
                {...props}
                className="w-full px-4 py-1.5 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                autoComplete="address-level2"
              />
            )}
          />
          <Controller
            control={control}
            name={fieldName + ".state"}
            rules={config}
            defaultValue={value && value.state ? value.state : null}
            render={(props) => (
              <Input
                placeholder="State"
                ref={props.field.ref}
                name={fieldName + ".state"}
                onBlur={props.field.onBlur}
                onChange={(e) => {
                  props.field.onChange(e.target.value);
                }}
                defaultValue={value && value.state ? value.state : null}
                {...props}
                className="w-full px-4 py-1.5 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                autoComplete="address-level1"
              />
            )}
          />
          <Controller
            control={control}
            name={fieldName + ".zip"}
            rules={config}
            defaultValue={value && value.zip ? value.zip : null}
            render={(props) => (
              <Input
                placeholder="ZIP"
                ref={props.field.ref}
                name={fieldName + ".zip"}
                onBlur={props.field.onBlur}
                onChange={(e) => {
                  props.field.onChange(e.target.value);
                }}
                defaultValue={value && value.zip ? value.zip : null}
                {...props}
                className="w-full px-4 py-1.5 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                autoComplete="postal-code"
                maxLength={5}
              />
            )}
          />
        </div>
      </div>
    );
  } else if (inputType === "contact") {
    return (
      <div className="flex flex-col items-start justify-start w-full gap-2">
        <Controller
          control={control}
          name={fieldName + ".name"}
          rules={config}
          defaultValue={value && value.name ? value.name : null}
          render={(props) => (
            <Input
              placeholder="Name"
              ref={props.field.ref}
              name={fieldName + ".name"}
              onBlur={props.field.onBlur}
              onChange={(e) => {
                props.field.onChange(e.target.value);
              }}
              defaultValue={value && value.name ? value.name : null}
              {...props}
              className="w-full px-4 py-1.5 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
              autoComplete="name"
            />
          )}
        />
        <div className="flex items-center justify-between w-full gap-2">
          <Controller
            control={control}
            name={fieldName + ".phoneNumber"}
            rules={config}
            defaultValue={value && value.phoneNumber ? value.phoneNumber : null}
            render={(props) => (
              <MaskedInput
                mask="(000) 000-0000"
                className="w-full px-4 py-1.5 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                placeholder="Phone Number"
                ref={props.field.ref}
                name={fieldName + ".phoneNumber"}
                onBlur={props.field.onBlur}
                onChange={(e) => {
                  props.field.onChange(e.unmaskedValue);
                }}
                defaultValue={value && value.phoneNumber ? value.phoneNumber : null}
                autoComplete="tel"
              />
            )}
          />
          <Controller
            control={control}
            name={fieldName + ".email"}
            rules={config}
            defaultValue={value && value.email ? value.email : null}
            render={(props) => (
              <Input
                placeholder="Email"
                ref={props.field.ref}
                name={fieldName + ".email"}
                onBlur={props.field.onBlur}
                onChange={(e) => {
                  props.field.onChange(e.target.value);
                }}
                defaultValue={value && value.email ? value.email : null}
                {...props}
                className="w-full px-4 py-1.5 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                autoComplete="email"
              />
            )}
          />
          <Controller
            control={control}
            name={fieldName + ".fax"}
            rules={config}
            defaultValue={value && value.fax ? value.fax : null}
            render={(props) => (
              <MaskedInput
                mask="(000) 000-0000"
                className="w-full px-4 py-1.5 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                placeholder="Fax Number"
                ref={props.field.ref}
                name={fieldName + ".fax"}
                onBlur={props.field.onBlur}
                onChange={(e) => {
                  props.field.onChange(e.unmaskedValue);
                }}
                defaultValue={value && value.fax ? value.fax : null}
              />
            )}
          />
        </div>
      </div>
    );
  } else if (inputType === "number") {
    return (
      <Controller
        control={control}
        name={fieldName}
        rules={config}
        defaultValue={defaultValue}
        render={(props) => (
          <InputNumber
            placeholder={placeholder}
            ref={props.field.ref}
            name={fieldName}
            onBlur={props.field.onBlur}
            onChange={props.field.onChange}
            defaultValue={value ? value : defaultValue ? defaultValue : null}
            {...props}
            className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
            controls={false}
          />
        )}
      />
    );
  } else if (inputType === "select") {
    return (
      <Controller
        control={control}
        name={fieldName}
        rules={config}
        defaultValue={defaultValue}
        render={(props) => (
          <Select
            placeholder={placeholder}
            ref={props.field.ref}
            name={fieldName}
            onBlur={props.field.onBlur}
            onChange={props.field.onChange}
            options={options}
            defaultValue={value ? value : defaultValue ? defaultValue : null}
            {...props}
            className="w-full font-sans"
            controls={false}
            showSearch
            filterOption={filterOption}
          />
        )}
      />
    );
  } else if (inputType === "textarea") {
    return (
      <Controller
        control={control}
        name={fieldName}
        rules={config}
        defaultValue={defaultValue}
        render={(props) => (
          <Input.TextArea
            placeholder={placeholder}
            ref={props.field.ref}
            name={fieldName}
            onBlur={props.field.onBlur}
            onChange={(e) => {
              props.field.onChange(e.target.value);
            }}
            rows={4}
            defaultValue={value}
            {...props}
            autoSize={false}
            className="w-full !resize-none font-sans py-2 px-4 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
          />
        )}
      />
    );
  } else {
    return <input className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6" type="text" />;
  }
};
