import { useState, useEffect } from "react";
import { CreateANewProductCategory, QueryProductCategories } from "../../actions/products";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Table } from "antd";
import FormController from "../../components/FormController";
import { productCategoryForm } from "../../forms/products/category";

const ProductCategories = ({ authState }) => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState({
    name: "",
    description: "",
  });
  const [createModal, setCreateModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    QueryProductCategories({})
      .then((res) => {
        setCategories(res.data.results);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.response : "An error occurred");
        navigate("/");
      });
    // eslint-disable-next-line
  }, []);

  const closeCreateModal = () => {
    setCreateModal(false);
    setNewCategory({
      name: "",
      description: "",
    });
  };

  const createCategory = (values) => {
    setNewCategory({
      name: values.name,
      description: values.description,
    });
    let payload = {
      name: values.name,
      description: values.description,
    };
    setLoading(true);
    CreateANewProductCategory(payload)
      .then((res) => {
        toast.success("Product category created successfully");
        navigate("/products/categories/" + res.data.categoryId);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.response : "An error occurred");
        setTimeout(() => setLoading(false), 700);
      });
  };

  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <div className="flex-grow flow-root w-full px-2">
        <div className="inline-block w-full min-w-full py-2 align-middle">
          {loading ? (
            <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">Loading...</p>
          ) : (
            <div className="flex flex-col items-center justify-start w-full h-full">
              <div className="flex flex-row items-center justify-between w-full pb-4 border-b border-gray-200">
                <div className="flex flex-row items-center justify-start gap-2">
                  <div className="flex flex-col items-start justify-center mr-4">
                    <p className="text-xl font-semibold">Invoices</p>
                    <p className="text-sm text-gray-500 font-base">A comprehensive overview of all customer invoices</p>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-end gap-2">
                  <Button onClick={() => setCreateModal(true)}>New Product Category</Button>
                </div>
              </div>
              <Table dataSource={categories} loading={loading} rowKey="categoryId" className="w-full" sticky={true}>
                <Table.Column title="Name" dataIndex="name" key="name" width="120px" />
                <Table.Column title="Description" dataIndex="description" key="description" className="truncate" />
                <Table.Column
                  title=""
                  dataIndex="categoryId"
                  key="categoryId"
                  align="right"
                  width="100px"
                  render={(v) => <Button onClick={() => navigate(`/products/categories/${v}`)}>Open</Button>}
                />
              </Table>
            </div>
          )}
        </div>
      </div>
      <Modal
        open={createModal}
        title="Create a Product Category"
        onCancel={closeCreateModal}
        centered
        destroyOnClose
        width={850}
        okText="Create Product Category"
        footer={[]}
      >
        <FormController
          onSubmit={createCategory}
          fields={productCategoryForm}
          values={newCategory}
          defaultValues={newCategory}
          buttonText="Create Product Category"
          fullWidth={true}
          back={true}
          backFunction={() => closeCreateModal()}
          backText="Cancel"
        />
      </Modal>
    </div>
  );
};

export default ProductCategories;
