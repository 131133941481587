import { Button, Checkbox, Divider, Input, Modal, Select } from "antd";
import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import FormController from "../../../components/FormController";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { invoiceBasicsForm } from "../../../forms/invoice/invoiceBasics";
import { CreateACustomer, QueryCustomers, ValidateCustomerNo } from "../../../actions/customer";
import { LoaderCircle } from "lucide-react";
import { AddressAutofill } from "@mapbox/search-js-react";
import { MaskedInput } from "antd-mask-input";
import validator from "validator";
import { SubmitNewInvoice } from "../../../actions/invoice";

const CreateInvoice = () => {
  const [step, setStep] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [data, setData] = useState({
    customerLabel: "",
    customerId: "",
    dateInvoiced: dayjs().toJSON(),
    taxExempt: false,
    dateOrdered: null,
    customerPo: "",
    terms: "",
    dueDate: null,
    soldTo: {
      name: "",
      contactPerson: "",
      address: {
        name: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        primary: true,
        notes: [],
      },
      phone: "",
      email: "",
      customerId: "",
    },
  });
  const [newCustomer, setNewCustomer] = useState(false);
  const [customerNoCheck, setCustomerNoCheck] = useState(false);
  const [customerNoError, setCustomerNoError] = useState(null);
  const [newCustomerData, setNewCustomerData] = useState({
    customerNo: null,
    name: null,
    company: false,
    addressString: null,
    address: {
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
    },
    contact: {
      name: null,
      phoneNumber: null,
      email: null,
      position: null,
    },
    priceCode: null,
    terms: null,
    category: null,
  });

  const navigate = useNavigate();

  const formFields = invoiceBasicsForm;

  const createInvoice = (vals) => {
    let customer = customers.find((c) => c.customerId === data.customerId);
    if (!data.customerId || data.customerId.length < 10 || !customer) {
      toast.error("Something went wrong, please select a customer again");
    } else if (customer.purchaseOrder === "Required" && (!vals.customerPo || vals.customerPo.length < 3)) {
      toast.error("A PO number is required for this customer");
    } else {
      SubmitNewInvoice(data)
        .then((res) => {
          toast.success("Invoice created successfully!");
          navigate(`/operations/invoices/${res.data.invoiceId}`);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.response : "An error occurred while attempting to create this invoice");
        });
    }
  };

  const handleCustomerSearch = (query) => {
    if (query?.length > 2) {
      QueryCustomers({ search: query })
        .then((res) => {
          setCustomers(res.data.results);
        })
        .catch((err) => {
          toast.error("Customer search failed, please try again");
          setCustomers([]);
        });
    }
  };

  const updateCustomer = (v) => {
    let tmp = data;
    let customer = customers.find((c) => c.customerId === v);
    if (customer) {
      tmp.customerId = v;
      tmp.terms = customer.terms;
      tmp.soldTo = {
        name: customer.name ?? "",
        contactPerson: customer.contacts.find((c) => c.primary)?.name ?? "",
        address: customer.addresses.find((a) => a.primary) ?? {
          name: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          primary: true,
          notes: [],
        },
        phone: customer.contacts.find((c) => c.primary)?.phoneNumber ?? "",
        email: customer.contacts.find((c) => c.primary)?.email ?? "",
        customerId: customer.customerId,
      };
      if (tmp.terms) {
        if (tmp.terms === "COD") {
          tmp.dueDate = dayjs().endOf("day").toJSON();
        } else {
          tmp.dueDate = dayjs()
            .add(parseInt(tmp.terms.split(" ")[1]), "days")
            .endOf("day")
            .toJSON();
        }
      } else {
        tmp.dueDate = dayjs().add(10, "days").endOf("day").toJSON();
      }
      tmp.dateInvoiced = dayjs().toJSON();
      tmp.customerLabel = `${customer.customerNo} | ${customer.name && customer.name.length > 0 ? " " + customer.name : ""}${
        customer.company === true ? " [CORP]" : ""
      }`;
      if (customer.purchaseOrder === "Required") {
        formFields.customerPo.label = (
          <>
            Customer PO# <span className="text-xs font-medium uppercase text-wbs-red">(REQUIRED)</span>
          </>
        );
        formFields.customerPo.config.required = "A PO # is required for this customer";
        formFields.customerPo.config.validate = (value) => value.length >= 3 || "A PO # is required for this customer";
      } else {
        formFields.customerPo.label = "Customer PO#";
        formFields.customerPo.config = { required: false };
      }
      setData(tmp);
      setStep(1);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const checkCustomerNo = (value) => {
    setNewCustomerData((prev) => ({ ...prev, customerNo: "" }));
    setCustomerNoError(null);
    if (value.length > 3) {
      setCustomerNoCheck(true);
      ValidateCustomerNo(value)
        .then((res) => {
          setNewCustomerData((prev) => ({ ...prev, customerNo: value }));
          setCustomerNoError(null);
          setTimeout(() => setCustomerNoCheck(false), 350);
        })
        .catch((err) => {
          setCustomerNoError("Customer No is already in use");
          setCustomerNoCheck(false);
        });
    } else {
      setCustomerNoError("Customer No must be at least 4 characters");
      setCustomerNoCheck(false);
    }
  };

  const handleAddressRetrieve = useCallback((res) => {
    let tmpFeature = res.features[0];
    let address = {
      address1: tmpFeature.properties.address_line1,
      address2: "",
      city: tmpFeature.properties.address_level2,
      state: tmpFeature.properties.address_level1,
      zip: tmpFeature.properties.postcode,
    };
    setNewCustomerData((prev) => ({ ...prev, addressString: tmpFeature.properties.full_address, address: address }));
  });

  const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const renderStep = () => {
    if (step === 0) {
      return (
        <div className="flex flex-col items-center justify-center w-full h-full max-w-4xl gap-4">
          <h5 className="w-full text-lg font-semibold leading-none tracking-wide text-black">Select a customer you wish to invoice</h5>
          <Select
            placeholder="Search Customers"
            onChange={(v) => updateCustomer(v)}
            onSelect={(v) => updateCustomer(v)}
            value={data.customerId && data.customerId.length > 10 ? data.customerId : null}
            options={(customers || []).map((p) => ({
              label: `${p.customerNo} | ${p.name && p.name.length > 0 ? " " + p.name : ""}${p.company === true ? " [CORP]" : ""}`,
              value: p.customerId,
            }))}
            className="w-full mb-2 font-sans"
            notFoundContent="No customers found, start typing to search"
            controls={false}
            showSearch
            filterOption={false}
            defaultActiveFirstOption={false}
            onSearch={handleCustomerSearch}
            allowClear
          />
          <Divider className="!my-0 text-gray-600 text-sm tracking-wider">OR</Divider>
          <Button onClick={() => setNewCustomer(true)} type="primary">
            Create a new customer
          </Button>
          <Modal
            open={newCustomer}
            title="Create a new customer"
            onCancel={() => closeNewCustomer()}
            destroyOnClose
            centered
            width={760}
            onOk={() => submitNewCustomer()}
          >
            <div className="flex flex-col items-center justify-start w-full h-full gap-4 p-4">
              <div className="grid w-full grid-cols-2 gap-2">
                <div className="flex flex-col items-start justify-start w-full gap-1">
                  <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Customer No</p>
                  <Input
                    placeholder="Customer no."
                    className="w-full px-4 py-1.5 font-sans text-sm border-gray-300 rounded-md focus:border-blue-500 focus:ring-0"
                    suffix={customerNoCheck ? <LoaderCircle size={14} className="animate-spin" /> : null}
                    onBlur={({ target: { value } }) => checkCustomerNo(value)}
                    disabled={customerNoCheck}
                  />
                  <p className="w-full text-xs text-right text-wbs-red min-h-4">{customerNoError}</p>
                </div>
                <div className="flex flex-col items-start justify-start w-full gap-1">
                  <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Customer Name</p>
                  <Input
                    placeholder="Customer name"
                    className="w-full px-4 py-1.5 font-sans text-sm border-gray-300 rounded-md focus:border-blue-500 focus:ring-0"
                    onChange={({ target: { value } }) => setNewCustomerData((prev) => ({ ...prev, name: value }))}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between w-full gap-1 -mt-5">
                <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Is this a company account?</p>
                <Checkbox checked={newCustomerData.company} onChange={(e) => setNewCustomerData((prev) => ({ ...prev, company: e.target.checked }))} />
              </div>
              <div className="flex items-center justify-between w-full gap-2">
                <p className="text-sm font-semibold leading-normal tracking-wide uppercase">Address</p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
              <div className="flex flex-col items-start justify-start w-full gap-1">
                <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Customer's Address</p>
                <AddressAutofill
                  accessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                  onRetrieve={(r) => handleAddressRetrieve(r)}
                  options={{ language: "en", country: "US" }}
                  className="w-full"
                >
                  <Input
                    placeholder="Start typing the address"
                    value={newCustomerData.addressString}
                    onChange={({ target: { value } }) => setNewCustomerData((prev) => ({ ...prev, addressString: value }))}
                    autoComplete="address-line1"
                    id="mapbox-autofill"
                    className="w-full px-4 py-1.5 font-sans text-sm border-gray-300 rounded-md focus:border-blue-500 focus:ring-0"
                  />
                </AddressAutofill>
              </div>
              <div className="flex items-center justify-between w-full gap-2">
                <p className="text-sm font-semibold leading-normal tracking-wide uppercase">Contact</p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
              <div className="grid w-full grid-cols-2 gap-2">
                <div className="flex flex-col items-start justify-start w-full gap-1">
                  <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Contact Person's Name</p>
                  <Input
                    placeholder="Contact name"
                    className="w-full px-4 py-1.5 font-sans text-sm border-gray-300 rounded-md focus:border-blue-500 focus:ring-0"
                    onChange={({ target: { value } }) => setNewCustomerData((prev) => ({ ...prev, contact: { ...prev.contact, name: value } }))}
                  />
                </div>
                <div className="flex flex-col items-start justify-start w-full gap-1">
                  <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Their Position</p>
                  <Input
                    placeholder="Contact person's position"
                    className="w-full px-4 py-1.5 font-sans text-sm border-gray-300 rounded-md focus:border-blue-500 focus:ring-0"
                    onChange={({ target: { value } }) => setNewCustomerData((prev) => ({ ...prev, contact: { ...prev.contact, position: value } }))}
                  />
                </div>
                <div className="flex flex-col items-start justify-start w-full gap-1">
                  <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Phone Number</p>
                  <MaskedInput
                    value={newCustomerData?.contact?.phoneNumber}
                    placeholder="Contact phone number"
                    mask={"(000) 000-0000"}
                    className="py-1 text-gray-900 border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 phoneInput"
                    onChange={(e) => setNewCustomerData((prev) => ({ ...prev, contact: { ...prev.contact, phoneNumber: e.unmaskedValue } }))}
                  />
                </div>
                <div className="flex flex-col items-start justify-start w-full gap-1">
                  <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Email Address</p>
                  <Input
                    placeholder="Contact email address"
                    className="w-full px-4 py-1.5 font-sans text-sm border-gray-300 rounded-md focus:border-blue-500 focus:ring-0"
                    type="email"
                    value={newCustomerData?.contact?.email}
                    onChange={(e) => setNewCustomerData((prev) => ({ ...prev, contact: { ...prev.contact, email: e.target.value } }))}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between w-full gap-2">
                <p className="text-sm font-semibold leading-normal tracking-wide uppercase">Pricing</p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
              <div className="grid w-full grid-cols-3 gap-2">
                <div className="flex flex-col items-start justify-start w-full gap-1">
                  <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Default Pricing Tier</p>
                  <Select
                    placeholder="Select pricing tier"
                    onChange={(v) => setNewCustomerData((prev) => ({ ...prev, priceCode: v }))}
                    options={[
                      { label: "Tier 1 Pricing - 200% Markup", value: "tier1" },
                      { label: "Tier 2 Pricing - 150% Markup", value: "tier2" },
                      { label: "Tier 3 Pricing - 50% Markup", value: "tier3" },
                    ]}
                    value={newCustomerData.priceCode}
                    className="w-full font-sans"
                    controls={false}
                    showSearch
                    filterOption={filterOption}
                  />
                </div>
                <div className="flex flex-col items-start justify-start w-full gap-1">
                  <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Payment Terms</p>
                  <Select
                    placeholder="Select payment terms"
                    onChange={(v) => setNewCustomerData((prev) => ({ ...prev, terms: v }))}
                    options={[
                      { label: "NET 10", value: "NET 10" },
                      { label: "NET 15", value: "NET 15" },
                      { label: "NET 30", value: "NET 30" },
                      { label: "NET 45", value: "NET 45" },
                      { label: "NET 60", value: "NET 60" },
                      { label: "NET 90", value: "NET 90" },
                      { label: "Collect on Delivery", value: "COD" },
                    ]}
                    value={newCustomerData.terms}
                    className="w-full font-sans"
                    controls={false}
                    showSearch
                    filterOption={filterOption}
                  />
                </div>
                <div className="flex flex-col items-start justify-start w-full gap-1">
                  <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Category</p>
                  <Select
                    placeholder="Select a category"
                    onChange={(v) => setNewCustomerData((prev) => ({ ...prev, category: v }))}
                    options={[
                      { label: "Contractor", value: "Contractor" },
                      { label: "Builder", value: "Builder" },
                      { label: "Retail", value: "Retail" },
                      { label: "Wholesale", value: "Wholesale" },
                      { label: "Government", value: "Government" },
                      { label: "Non-Profit", value: "Non-Profit" },
                      { label: "Educational", value: "Educational" },
                      { label: "Medical", value: "Medical" },
                      { label: "Industrial", value: "Industrial" },
                      { label: "Commercial", value: "Commercial" },
                      { label: "Residential", value: "Residential" },
                      { label: "Religious", value: "Religious" },
                      { label: "Individual", value: "Individual" },
                      { label: "Agricultural", value: "Agricultural" },
                      { label: "Other", value: "Other" },
                      { label: "Unknown", value: "" },
                    ]}
                    value={newCustomerData.category}
                    className="w-full font-sans"
                    controls={false}
                    showSearch
                    filterOption={filterOption}
                  />
                </div>
              </div>
            </div>
          </Modal>
        </div>
      );
    } else if (step === 1) {
      return (
        <FormController
          onSubmit={createInvoice}
          fields={formFields}
          values={data}
          buttonText={"Next"}
          fullWidth={true}
          back={true}
          backFunction={() => setStep(0)}
        />
      );
    }
  };

  const closeNewCustomer = () => {
    setNewCustomer(false);
  };

  const submitNewCustomer = () => {
    let c = newCustomerData;
    if (!c.customerNo || c.customerNo.length < 3) {
      toast.error("Please enter a valid customer number");
    } else if (!c.name || c.name.length < 3) {
      toast.error("Please enter a name for this customer");
    } else if (!c.addressString || c.addressString.length < 3) {
      toast.error("Please provide a valid address for this customer");
    } else if (!c.contact.name || c.contact.name.length < 3) {
      toast.error("Please provide a contact name for this customer");
    } else if ((!c.contact.phoneNumber || !validator.isMobilePhone(c.contact.phoneNumber)) && (!c.contact.email || !validator.isEmail(c.contact.email))) {
      toast.error("Please provide a valid phone number or an email address for this customer");
    } else if (!c.priceCode || c.priceCode.length < 3) {
      toast.error("Please select a pricing tier for this customer");
    } else {
      if (!c.terms || c.terms.length < 3) {
        c.terms = "NET 30";
      }
      if (!c.category) {
        c.category = "";
      }
      let payload = {
        customerNo: c.customerNo,
        name: c.name,
        company: c.company,
        address: [{ ...c.address }],
        contact: [{ ...c.contact }],
        shipTo: c.address,
        purchaseOrder: "",
        customerType: "Cash Customer",
        statementType: "Print both statements and invoices",
        priceCode: c.priceCode,
        creditLimit: 0,
        holdStatus: "No Credit Hold",
        terms: c.terms,
        category: c.category,
        dateOpened: dayjs().toJSON(),
        creditCheck: {
          references: [],
          notes: [],
        },
      };
      CreateACustomer(payload)
        .then((res) => {
          setNewCustomer(false);
          let customer = res.data;
          let tmp = data;
          tmp.customerId = customer.customerId;
          tmp.terms = customer.terms;
          tmp.soldTo = {
            name: customer.contacts.find((c) => c.primary)?.name ?? "",
            contactPerson: customer.contacts.find((c) => c.primary)?.name ?? "",
            address: customer.addresses.find((a) => a.primary) ?? {
              name: "",
              address1: "",
              address2: "",
              city: "",
              state: "",
              zip: "",
              primary: true,
              notes: [],
            },
            phone: customer.contacts.find((c) => c.primary)?.phoneNumber ?? "",
            email: customer.contacts.find((c) => c.primary)?.email ?? "",
            customerId: customer.customerId,
          };
          if (tmp.terms) {
            if (tmp.terms === "COD") {
              tmp.dueDate = dayjs().endOf("day").toJSON();
            } else {
              tmp.dueDate = dayjs()
                .add(parseInt(tmp.terms.split(" ")[1]), "days")
                .endOf("day")
                .toJSON();
            }
          } else {
            tmp.dueDate = dayjs().add(10, "days").endOf("day").toJSON();
          }
          tmp.dateInvoiced = dayjs().toJSON();
          tmp.customerLabel = `${customer.customerNo} | ${customer.name && customer.name.length > 0 ? " " + customer.name : ""}${
            customer.company === true ? " [CORP]" : ""
          }`;
          setData(tmp);
          if (customer.purchaseOrder === "Required") {
            formFields.customerPo.label = (
              <>
                Customer PO# <span className="text-xs font-medium uppercase text-wbs-red">(REQUIRED)</span>
              </>
            );
            formFields.customerPo.config.required = "A PO # is required for this customer";
            formFields.customerPo.config.validate = (value) => value.length >= 3 || "A PO # is required for this customer";
          } else {
            formFields.customerPo.label = "Customer PO#";
            formFields.customerPo.config = { required: false };
          }
          toast.success("Customer created successfully");
          setStep(1);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.response : "An error occurred while adding the customer");
        });
    }
  };

  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <div className="flex-grow flow-root w-full h-full px-2">
        <div className="inline-block w-full h-full min-w-full py-2 align-middle">
          <div className="flex flex-col items-center justify-start w-full h-full">
            <div className="flex flex-row items-center justify-between w-full pb-4 border-b border-gray-200">
              <div className="flex flex-row items-center justify-start gap-2">
                <div className="flex flex-col items-start justify-center mr-4">
                  <p className="text-xl font-semibold">New Invoice</p>
                  <p className="text-sm text-gray-500 font-base">Create a new invoice</p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-end gap-2">
                <Button onClick={() => navigate("/operations/invoices")}>Cancel</Button>
              </div>
            </div>
            <div className="flex flex-col items-center justify-start w-full h-full gap-4 p-4">
              <h3 className="w-full text-lg font-semibold leading-none tracking-wide text-black">Invoice data</h3>
              {renderStep()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateInvoice;
