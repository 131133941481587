import axiosInstance from "./axios";
import qs from "qs";

export const CalculateTax = (data) => {
  return axiosInstance.post("/payment/tax", data);
};

export const ProcessPayment = (data) => {
  return axiosInstance.post("/payment/process", data);
};

export const GetTransactionStatusById = (id) => {
  return axiosInstance.get(`/payment/status/${id}`);
};

export const GetTransactionById = (id) => {
  return axiosInstance.get(`/payment/transaction/${id}`);
};

export const IssueRefund = (transactionId, data) => {
  return axiosInstance.put(`/payment/refund/${transactionId}`, data);
};

export const QueryTransactions = (data) => {
  let queryParam = {};
  if (data.search && data.search.length > 0) {
    queryParam.search = data.search;
  }
  if (data.page && !isNaN(data.page)) {
    queryParam.page = data.page;
  }
  if (data.limit && !isNaN(data.limit)) {
    queryParam.limit = data.limit;
  }
  const queryString = qs.stringify(queryParam);
  return axiosInstance.get(`/payment/query?${queryString}`);
};
