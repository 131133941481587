import { Button, Input } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FormController from "../../components/FormController";
import { newCustomerForm } from "../../forms/customer/newCustomer";
import dayjs from "dayjs";
import { Trash } from "iconoir-react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { MaskedInput } from "antd-mask-input";
import { ErrorMessage } from "@hookform/error-message";
import toast from "react-hot-toast";
import validator from "validator";
import { formatCurrency, formatPhoneNumber } from "../../data/utils";
import { states } from "../../data/states";
import { CreateACustomer } from "../../actions/customer";

const NewCustomerView = () => {
  const [step, setStep] = useState(0);
  const [customer, setCustomer] = useState({
    customerNo: "",
    name: "",
    company: false,
    address: {
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
    },
    contact: {
      name: "",
      phoneNumber: "",
      email: "",
      fax: "",
    },
    shipTo: {
      name: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
    },
    purchaseOrder: "Not Required",
    customerType: "",
    statementType: "Print only statements",
    priceCode: "tier1",
    creditLimit: 0,
    holdStatus: "No Credit Hold",
    terms: "NET 30",
    category: "",
    dateOpened: dayjs().toJSON(),
    creditCheck: {
      references: [{ contact: { name: null, phoneNumber: null, email: null, fax: null }, notes: [] }],
      notes: [],
    },
  });

  const navigate = useNavigate();

  const submitCustomer = (data) => {
    setCustomer(data);
    setStep(1);
  };

  const steps = [
    { id: "01", name: "Customer Details", description: "Create a Customer", status: step > 0 ? "complete" : "current" },
    { id: "02", name: "Application form", description: "Create a Customer", href: "#", status: step < 1 ? "upcoming" : step > 1 ? "complete" : "current" },
    { id: "03", name: "Preview", description: "Create a Customer", href: "#", status: step < 2 ? "upcoming" : "current" },
  ];
  const { register, control, handleSubmit, formState } = useForm({
    defaultValues: customer.creditCheck,
    values: customer.creditCheck,
  });
  const { errors } = formState;
  let { fields, append, remove } = useFieldArray({ control, name: "references" });

  const validateReference = (ref) => {
    if (ref.contact.name || ref.contact.phoneNumber || ref.contact.email || ref.contact.fax) {
      let valid = true;
      if (ref.contact.phoneNumber && !validator.isMobilePhone(ref.contact.phoneNumber)) {
        toast.error("Please enter a valid phone number for this reference");
        valid = false;
      }
      if (ref.contact.email && !validator.isEmail(ref.contact.email)) {
        toast.error("Please enter a valid email address for this reference");
        valid = false;
      }
      if (ref.contact.fax && !validator.isMobilePhone(ref.contact.fax)) {
        toast.error("Please enter a valid fax number for this reference");
        valid = false;
      }
      if (ref.contact.name && ref.contact.name.length < 2) {
        toast.error("Please enter a valid name for this reference");
        valid = false;
      }
      return true;
    }
    if (ref.notes[0].note) {
      toast.error("Please add some information about this reference before proceeding or remove the note");
      return false;
    } else {
      return false;
    }
  };

  const submitReferences = (data) => {
    let references = data.references.filter((ref) => validateReference(ref));
    if (references.length === data.references.length) {
      setCustomer({ ...customer, creditCheck: { references: references, notes: customer.creditCheck.notes } });
      setStep(2);
    } else if (references.length === 0) {
      toast("No references to add - proceeding to next step");
      setCustomer({ ...customer, creditCheck: { references: [], notes: [] } });
      setStep(2);
    } else {
      toast.error("Please correct the errors before proceeding");
    }
  };

  const renderStep = () => {
    if (step === 0) {
      return <FormController onSubmit={submitCustomer} fields={newCustomerForm} values={customer} buttonText={"Next"} fullWidth={true} />;
    } else if (step === 1) {
      return (
        <form className="flex flex-col items-start justify-start w-full gap-2" onSubmit={handleSubmit(submitReferences)} key="customer-references">
          <div className="flex flex-col items-start justify-start w-full gap-2">
            {fields.map((field, index) => {
              return (
                <div className="w-full py-6 bg-white border rounded-lg shadow-sm px-7 border-neutral-200/60" key={field.id}>
                  <h5 className="text-xl font-bold leading-none tracking-tight text-neutral-900">Reference #{index + 1}</h5>
                  <div key={field.id} className="flex flex-col items-center justify-between w-full gap-2 py-7">
                    <div className="grid w-full grid-cols-2 gap-4">
                      <div className="flex flex-col items-start justify-start w-full gap-1">
                        <p className="mb-2 text-lg font-semibold">Contact Information</p>
                        <div className="flex flex-col items-start justify-start w-full">
                          <label htmlFor={`references[${index}].contact.name`} className={`text-xs uppercase pb-2 text-gray-600`}>
                            Reference's Name:
                          </label>
                          <Controller
                            control={control}
                            name={`references[${index}].contact.name`}
                            defaultValue={field.contact.name}
                            render={(props) => (
                              <Input
                                placeholder="Name"
                                ref={props.field.ref}
                                name={`references[${index}].contact.name`}
                                onBlur={props.field.onBlur}
                                onChange={(e) => {
                                  props.field.onChange(e.target.value);
                                }}
                                defaultValue={field.contact.name}
                                {...props}
                                className="w-full px-4 py-1.5 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                              />
                            )}
                          />
                          <ErrorMessage errors={errors} name={`references[${index}].contact.name`} as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div className="flex flex-col items-start justify-start w-full">
                          <label htmlFor={`references[${index}].contact.phoneNumber`} className={`text-xs uppercase pb-2 text-gray-600`}>
                            Phone Number:
                          </label>
                          <Controller
                            control={control}
                            name={`references[${index}].contact.phoneNumber`}
                            defaultValue={field.contact.phoneNumber}
                            render={(props) => (
                              <MaskedInput
                                mask="(000) 000-0000"
                                className="w-full px-4 py-1.5 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                                placeholder="Phone Number"
                                ref={props.field.ref}
                                name={`references[${index}].contact.phoneNumber`}
                                onBlur={props.field.onBlur}
                                onChange={(e) => {
                                  props.field.onChange(e.unmaskedValue);
                                }}
                                defaultValue={
                                  field.contact && field.contact.phoneNumber && field.contact.phoneNumber.length === 10 ? field.contact.phoneNumber : null
                                }
                                autoComplete="tel"
                              />
                            )}
                          />
                          <ErrorMessage errors={errors} name={`references[${index}].contact.phoneNumber`} as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div className="flex flex-col items-start justify-start w-full">
                          <label htmlFor={`references[${index}].contact.email`} className={`text-xs uppercase pb-2 text-gray-600`}>
                            Email:
                          </label>
                          <Controller
                            control={control}
                            name={`references[${index}].contact.email`}
                            defaultValue={field.contact.email}
                            render={(props) => (
                              <Input
                                placeholder="Email Address"
                                ref={props.field.ref}
                                name={`references[${index}].contact.email`}
                                onBlur={props.field.onBlur}
                                onChange={(e) => {
                                  props.field.onChange(e.target.value);
                                }}
                                defaultValue={field.contact.email}
                                type="email"
                                {...props}
                                className="w-full px-4 py-1.5 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                              />
                            )}
                          />
                          <ErrorMessage errors={errors} name={`references[${index}].contact.email`} as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                        <div className="flex flex-col items-start justify-start w-full">
                          <label htmlFor={`references[${index}].contact.fax`} className={`text-xs uppercase pb-2 text-gray-600`}>
                            Fax Number:
                          </label>
                          <Controller
                            control={control}
                            name={`references[${index}].contact.fax`}
                            defaultValue={field.contact.fax}
                            render={(props) => (
                              <MaskedInput
                                mask="(000) 000-0000"
                                className="w-full px-4 py-1.5 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                                placeholder="Fax Number"
                                ref={props.field.ref}
                                name={`references[${index}].contact.fax`}
                                onBlur={props.field.onBlur}
                                onChange={(e) => {
                                  props.field.onChange(e.unmaskedValue);
                                }}
                                defaultValue={field.contact && field.contact.fax && field.contact.fax.length === 10 ? field.contact.fax : null}
                                autoComplete="tel"
                              />
                            )}
                          />
                          <ErrorMessage errors={errors} name={`references[${index}].contact.fax`} as="p" className="px-1 pt-1 text-xs text-red-500" />
                        </div>
                      </div>
                      <div className="flex flex-col items-start justify-start w-full h-full gap-2">
                        <p className="text-lg font-semibold mb-7">Reference Notes</p>
                        <Controller
                          control={control}
                          name={`references[${index}].notes[0].note`}
                          defaultValue={field.notes.note}
                          render={(props) => (
                            <Input.TextArea
                              placeholder="Add a note to this customer"
                              ref={props.field.ref}
                              name={`references[${index}].notes[0].note`}
                              onBlur={props.field.onBlur}
                              onChange={(e) => {
                                props.field.onChange(e.target.value);
                              }}
                              rows={10}
                              defaultValue={field.notes && field.notes.note && field.notes.note.length > 0 ? field.notes.note : null}
                              {...props}
                              autoSize={false}
                              className="w-full !resize-none font-sans py-2 px-4 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-end w-full col-span-2 mt-4 -mb-2">
                      <button
                        type="button"
                        className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium tracking-wide text-red-500 transition-colors duration-100 rounded-md focus:ring-2 focus:ring-offset-2 focus:ring-red-100 bg-red-50 hover:text-red-600 hover:bg-red-100"
                        onClick={() => remove(index)}
                      >
                        Remove Reference
                        <Trash className="w-auto h-5 ml-2" strokeWidth={1.75} />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="flex items-center justify-end w-full py-2 my-2 border-gray-200 border-y">
              <Button onClick={() => append({ contact: { name: null, phoneNumber: null, email: null, fax: null }, notes: [] })}>Add another reference</Button>
            </div>
          </div>
          <div className="flex items-center justify-end w-full">
            <Button htmlType="submit" type="primary">
              Next
            </Button>
          </div>
        </form>
      );
    } else if (step === 2) {
      return (
        <div className="flex flex-col items-center justify-start w-full h-full gap-4">
          <div className="flex flex-col items-start justify-start w-full gap-2 p-6 font-sans bg-white border rounded-md shadow-md border-gray-200/60 shadow-gray-200">
            <div className="flex flex-row items-center justify-between w-full">
              <p className="text-xl font-bold text-black">General Information</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
              <p className="font-semibold text-slate-800">Customer Number</p>
              <p>{customer.customerNo}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
              <p className="font-semibold text-slate-800">Account Name</p>
              <p>{customer.name}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
              <p className="font-semibold text-slate-800">Account Type</p>
              <p>{customer.company ? "Company Account" : "Individual Account"}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
              <p className="font-semibold text-slate-800">Customer Category</p>
              <p>{customer.category.length > 0 ? customer.category : "Category not set"}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
              <p className="font-semibold text-slate-800">Company Account</p>
              <p>{customer.company ? "Yes" : "No"}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
              <p className="font-semibold text-slate-800">Purchase Order</p>
              <p>{customer.purchaseOrder.length > 0 ? customer.purchaseOrder : "Preference not set"}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
              <p className="font-semibold text-slate-800">Statement Settings</p>
              <p>{customer.statementType.length > 0 ? customer.statementType : "Preference not set"}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
              <p className="font-semibold text-slate-800">Date Opened</p>
              <p>{customer.dateOpened && dayjs(customer.dateOpened).isValid() ? dayjs(customer.dateOpened).format("MM/DD/YYYY") : "Not Provided"}</p>
            </div>
          </div>
          <div className="flex items-center justify-between w-full gap-4">
            <div className="flex flex-col items-start justify-start w-1/2 gap-2 p-6 bg-white border rounded-md shadow-md shadow-gray-200 border-gray-200/60">
              <div className="flex flex-row items-center justify-between w-full">
                <p className="text-xl font-bold text-black">Contact</p>
              </div>
              <div className="flex flex-col items-center justify-center w-full h-full">
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">Name</p>
                  <p>{customer.contact.name ?? "Not Provided"}</p>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">Phone Number</p>
                  <p>{formatPhoneNumber(customer.contact.phoneNumber)}</p>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">Email</p>
                  <p>{customer.contact.email ?? "Not Provided"}</p>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">Fax</p>
                  <p>{formatPhoneNumber(customer.contact.fax)}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start w-1/2 gap-2 p-6 bg-white border rounded-md shadow-md shadow-gray-200 border-gray-200/60">
              <div className="flex flex-row items-center justify-between w-full">
                <p className="text-xl font-bold text-black">Address</p>
              </div>
              <div className="flex flex-col items-center justify-center w-full h-full">
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">Address</p>
                  <p>
                    {customer.address.address1 ? customer.address.address1 : "Not Provided"}
                    {customer.address.address2 && customer.address.address2.length > 0 ? `, ${customer.address.address2}` : ""}
                  </p>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">City</p>
                  <p>{customer.address.city ? customer.address.city : "Not Provided"}</p>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">State</p>
                  <p>{customer.address.state ? states.find((s) => s.value === customer.address.state)?.label : "Not Provided"}</p>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">ZIP Code</p>
                  <p>{customer.address.zip ? customer.address.zip : "Not Provided"}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between w-full gap-4">
            <div className="flex flex-col items-start justify-start w-1/2 gap-2 p-6 bg-white border rounded-md shadow-md shadow-gray-200 border-gray-200/60">
              <div className="flex flex-row items-center justify-between w-full">
                <p className="text-xl font-bold text-black">Shipping Address</p>
              </div>
              <div className="flex flex-col items-center justify-center w-full h-full">
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">Address</p>
                  <p>
                    {customer.shipTo.address1 ? customer.shipTo.address1 : "Not Provided"}
                    {customer.shipTo.address2 && customer.shipTo.address2.length > 0 ? `, ${customer.shipTo.address2}` : ""}
                  </p>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">City</p>
                  <p>{customer.shipTo.city ? customer.shipTo.city : "Not Provided"}</p>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">State</p>
                  <p>{customer.shipTo.state ? states.find((s) => s.value === customer.shipTo.state)?.label : "Not Provided"}</p>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">ZIP Code</p>
                  <p>{customer.shipTo.zip ? customer.shipTo.zip : "Not Provided"}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start w-1/2 gap-2 p-6 bg-white border rounded-md shadow-md shadow-gray-200 border-gray-200/60">
              <div className="flex flex-row items-center justify-between w-full">
                <p className="text-xl font-bold text-black">Credit Information</p>
              </div>
              <div className="flex flex-col items-center justify-center w-full h-full">
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">Customer Type</p>
                  <p>{customer.customerType}</p>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">Payment Terms</p>
                  <p>{customer.terms}</p>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">Credit Limit</p>
                  <p>{customer.creditLimit ? formatCurrency(customer.creditLimit) : "No Line of Credit Provided"}</p>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">Hold Status</p>
                  <p>{customer.holdStatus ? customer.holdStatus : "Not Set"}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between w-full px-6 py-4 my-4 border-b border-gray-300">
            <p className="text-xl font-bold text-black">Credit References</p>
            <p className="text-xl font-bold text-black">
              {customer.creditCheck && customer.creditCheck.references ? customer.creditCheck.references.length : "0"}
            </p>
          </div>
          <div className={`grid w-full ${customer.creditCheck.references && customer.creditCheck.references.length > 1 ? "grid-cols-2" : "grid-cols-1"} gap-4`}>
            {customer.creditCheck.references.length > 0 && customer.creditCheck.references.map((ref, index) => renderReference(ref, index))}
          </div>
          <div className="flex flex-row items-center justify-end w-full px-6 py-4 my-4 border-t border-gray-300">
            <Button onClick={() => addCustomer()} type="primary">
              Add Customer
            </Button>
          </div>
        </div>
      );
    }
  };

  const processCustomerData = (customerData) => {
    const contacts = [];
    const addresses = [];
    let shipTo = null;

    if (customerData.contact && Object.values(customerData.contact).some((value) => value)) {
      contacts.push({ ...customerData.contact, primary: true });
    }

    if (customerData.address && Object.values(customerData.address).some((value) => value)) {
      addresses.push({ ...customerData.address, primary: true });
    }

    if (customerData.shipTo && Object.values(customerData.shipTo).some((value) => value)) {
      shipTo = customerData.shipTo;
    }

    return { contacts, addresses, shipTo };
  };

  const addCustomer = () => {
    let payload = {
      customerNo: customer.customerNo,
      name: customer.name,
      company: customer.company,
      purchaseOrder: customer.purchaseOrder,
      customerType: customer.customerType,
      statementType: customer.statementType,
      priceCode: customer.priceCode,
      creditLimit: customer.creditLimit,
      holdStatus: customer.holdStatus,
      terms: customer.terms,
      dateOpened: customer.dateOpened,
      creditCheck: {
        references: customer.creditCheck.references,
        notes: customer.creditCheck.notes,
      },
      notes: [],
    };

    const { contacts, addresses, shipTo } = processCustomerData(customer);

    payload.contacts = contacts;
    payload.addresses = addresses;
    if (shipTo) {
      payload.shipTo = shipTo;
    }

    CreateACustomer(payload)
      .then((res) => {
        toast.success("Customer added successfully");
        navigate(`/customers/${res.data.customerId}`);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.response : "An error occurred while adding the customer");
      });
  };

  const renderReference = (data, index) => {
    return (
      <div className="flex flex-col items-start justify-start w-full gap-2 p-6 font-sans bg-white border rounded-md shadow-md border-gray-200/60 shadow-gray-200">
        <div className="flex flex-row items-center justify-between w-full">
          <p className="text-xl font-bold text-black">Credit Reference #{index + 1}</p>
        </div>
        <div className="flex flex-row items-center justify-between w-full">
          <p className="font-semibold text-slate-800">Name</p>
          <p>{data.contact.name ? data.contact.name : "Not Provided"}</p>
        </div>
        <div className="flex flex-row items-center justify-between w-full">
          <p className="font-semibold text-slate-800">Phone Number</p>
          <p>{data.contact.phoneNumber ? formatPhoneNumber(data.contact.phoneNumber) : "Not Provided"}</p>
        </div>
        <div className="flex flex-row items-center justify-between w-full">
          <p className="font-semibold text-slate-800">Email</p>
          <p>{data.contact.email ? data.contact.email : "Not Provided"}</p>
        </div>
        <div className="flex flex-row items-center justify-between w-full">
          <p className="font-semibold text-slate-800">Fax</p>
          <p>{data.contact.fax ? formatPhoneNumber(data.contact.fax) : "Not Provided"}</p>
        </div>
        {data.notes &&
          data.notes.length > 0 &&
          data.notes.map((note, i) => (
            <div className="flex flex-row items-center justify-between w-full">
              <p className="font-semibold text-slate-800">Note #{i + 1}</p>
              <p>{note.note}</p>
            </div>
          ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-start justify-between w-full h-full gap-4">
      <div className="flex items-center justify-between w-full pb-4 border-b border-gray-200">
        <h1 className="text-lg font-bold">Add a New Customer</h1>
        <nav className="flex items-center justify-center" aria-label="Progress">
          <p className="text-sm font-medium">
            Step {steps.findIndex((step) => step.status === "current") + 1} of {steps.length}
          </p>
          <ol role="list" className="flex items-center ml-8 space-x-5">
            {steps.map((step) => (
              <li key={step.name}>
                {step.status === "complete" ? (
                  <p className="block h-2.5 w-2.5 rounded-full bg-wbs-red" />
                ) : step.status === "current" ? (
                  <p className="relative flex items-center justify-center" aria-current="step">
                    <span className="absolute flex w-5 h-5 p-px" aria-hidden="true">
                      <span className="w-full h-full rounded-full bg-wbs-red/25" />
                    </span>
                    <span className="relative block h-2.5 w-2.5 rounded-full bg-wbs-red" aria-hidden="true" />
                  </p>
                ) : (
                  <p className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400" />
                )}
              </li>
            ))}
          </ol>
        </nav>
        <Button onClick={() => navigate(-1)}>Cancel & Go Back</Button>
      </div>
      <div className="flex flex-col items-start justify-start w-full h-full overflow-y-auto">{renderStep()}</div>
    </div>
  );
};

export default NewCustomerView;
