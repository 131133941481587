import { ErrorMessage } from "@hookform/error-message";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { DynamicField } from "./DynamicField";
import { Button, DatePicker, Switch } from "antd";
import dayjs from "dayjs";
import { MaskedInput } from "antd-mask-input";
import { useEffect } from "react";

const FormController = ({
  onSubmit,
  fields,
  buttonText,
  key,
  defaultValues,
  values,
  fullWidth,
  keepDefaults,
  row,
  hideSubmit,
  backFunction,
  back,
  backText = "Back",
}) => {
  const formMethods = useForm({ values: values });
  const {
    handleSubmit,
    formState: { isSubmitting, errors, isDirty, dirtyFields },
    control,
    setValue,
  } = formMethods;

  function submitForm(data, error) {
    data.formModified = isDirty;
    data.modifiedFields = dirtyFields;
    onSubmit(data);
    formMethods.reset({ keepDefaults: keepDefaults ? values : null });
  }

  let labelHidden = ["divider", "divider-label", "label"];

  const renderForm = Object.keys(fields).map((i) => {
    if (fields[i].inputType === "date") {
      return (
        <div key={i + `.${fields[i].fieldName}`} className="flex flex-col items-start justify-start w-full">
          <label htmlFor={fields[i].fieldName} className={`text-xs uppercase pb-2 text-gray-600 ${labelHidden.includes(fields[i].inputType) && "hidden"}`}>
            {fields[i].label}
          </label>
          <Controller
            control={formMethods.control}
            name={fields[i].fieldName}
            rules={{
              required: "Date is required",
              validate: (v) => dayjs(v).isValid() || "Please provide a date",
            }}
            defaultValue={values[fields[i].fieldName] ? dayjs(values[fields[i].fieldName]) : null}
            render={(props) => (
              <DatePicker
                placeholder={fields[i].placeholder}
                status={props.fieldState?.error ? "error" : undefined}
                ref={props.field.ref}
                name={fields[i].fieldName}
                onBlur={props.field.onBlur}
                format={"MM/DD/YYYY"}
                onChange={(date) => {
                  props.field.onChange(date ? date.toJSON() : null);
                }}
                defaultValue={values && values[fields[i].fieldName] && dayjs(values[fields[i].fieldName]).isValid() ? dayjs(values[fields[i].fieldName]) : null}
                {...props}
                className="w-full"
              />
            )}
          />
          <ErrorMessage errors={errors} name={fields[i].fieldName} as="p" className="px-1 pt-1 text-xs text-red-500" />
        </div>
      );
    } else if (fields[i].inputType === "switch") {
      let currField = fields[i];
      return (
        <div key={currField.fieldName} className="flex items-center justify-between w-full gap-4 my-3">
          <div className="text-sm leading-6">
            <label className="text-gray-900">{currField.label}</label>
          </div>
          <Controller
            control={control}
            defaultValue={null}
            name={currField.fieldName}
            rules={{
              required: false,
            }}
            render={({ field: { value, onChange, ref } }) => <Switch value={value} onChange={(e) => onChange(e)} />}
          />
          <ErrorMessage errors={errors} name={currField.fieldName} as="p" className="px-1 pt-1 text-xs text-red-500" />
        </div>
      );
    } else if (fields[i].inputType === "phoneNumber") {
      let currField = fields[i];
      return (
        <div key={`${currField.fieldName}`} className="flex flex-col items-start justify-start w-full">
          <div className="pb-1 text-xs leading-6 uppercase">
            <label className="text-gray-600">{currField.label}</label>
          </div>
          <Controller
            control={control}
            defaultValue={null}
            name={currField.fieldName}
            rules={currField.config}
            render={({ field: { value, onChange, ref } }) => (
              <MaskedInput
                id={currField.fieldName}
                defaultValue={values && values[currField.fieldName] ? values[currField.fieldName] : null}
                placeholder={currField.placeholder}
                mask={"(000) 000-0000"}
                className="py-1 text-gray-900 border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 phoneInput"
                onChange={(e) => onChange(e.unmaskedValue)}
              />
            )}
          />
          <ErrorMessage errors={errors} name={currField.fieldName} as="p" className="px-1 pt-1 text-xs text-red-500" />
        </div>
      );
    } else {
      let field = fields[i];
      return (
        <div key={i + `.${field.fieldName}`} className="flex flex-col items-start justify-start w-full">
          <label htmlFor={field.fieldName} className={`text-xs uppercase pb-2 text-gray-600 ${labelHidden.includes(field.inputType) && "hidden"}`}>
            {field.label}
          </label>
          <DynamicField {...field} value={values[i]} control={control} setValue={setValue} />
          <ErrorMessage errors={errors} name={field.fieldName} as="p" className="px-1 pt-1 text-xs text-red-500" />
        </div>
      );
    }
  });

  return (
    <form
      onSubmit={handleSubmit(submitForm)}
      className={`w-full ${!fullWidth ? "max-w-lg" : null} flex ${row ? "flex-row justify-between items-center" : "flex-col justify-start items-start"} gap-2`}
      key={key ?? "form"}
    >
      <FormProvider {...formMethods}>{renderForm}</FormProvider>
      {!hideSubmit && (
        <div className={`w-full flex flex-row ${!back ? "justify-end" : "justify-between"} items-center mt-3 mb-1`}>
          {back && <Button onClick={() => backFunction()}>{isSubmitting ? "Processing..." : backText}</Button>}
          <Button htmlType="submit" type="primary">
            {isSubmitting ? "Processing..." : buttonText}
          </Button>
        </div>
      )}
    </form>
  );
};

export default FormController;
