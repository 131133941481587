import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { formatCurrency } from "../../../data/utils";
import { Button } from "antd";

dayjs.extend(advancedFormat);

const Payment = ({ key, data }) => {
  return (
    <div className="flex flex-col items-start justify-center w-full gap-2 px-5 py-4 border border-gray-100 rounded-lg shadow-sm" key={key}>
      <div className="flex items-start justify-between w-full">
        <div className="flex flex-col items-start justify-center gap-1">
          <p className="font-sans text-sm font-semibold">
            {dayjs(data.paymentDate).format("MMMM Do YYYY")} - {data.paymentMethod} Payment
          </p>
          <p className="w-full text-lg font-bold">{formatCurrency(data.paymentAmount)}</p>
        </div>
        <div className="flex flex-col items-end justify-center gap-1 font-sans">
          <p className="text-sm mt-0.5 text-gray-400">Logged by {data.audit.createdBy}</p>
          <p className="text-xs text-gray-400">{dayjs(data.audit.createdAt).format("MMMM Do YYYY[, at ]hh:mm A")}</p>
        </div>
      </div>
      {/* <div className="flex items-center justify-end w-full gap-2 mt-1">
        <Button size="small">Edit</Button>
        <Button size="small" danger>
          Delete
        </Button>
      </div> */}
    </div>
  );
};

export default Payment;
