import dayjs from "dayjs";

const CustomerNote = ({ note, index }) => {
  return (
    <div key={index} className="flex flex-col items-start justify-start w-full gap-2 px-5 py-4 mx-auto bg-white border rounded-md border-slate-200">
      <div className="flex flex-col items-start justify-start w-full">
        <p className="text-base font-semibold text-gray-800">{note.note}</p>
      </div>
      <div className="flex flex-row items-center justify-between w-full">
        <p className="text-xs text-gray-500">{note.user}</p>
        <p className="text-xs text-gray-500">{dayjs(note.date).format("MM/DD/YYYY")}</p>
      </div>
    </div>
  );
};

export default CustomerNote;
