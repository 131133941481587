import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ConfigProvider } from "antd";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { AuthProvider, AuthConsumer } from "./context";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <AuthProvider>
    <AuthConsumer>
      {(authState) => (
        <BrowserRouter>
          <HelmetProvider>
            <ConfigProvider
              theme={{
                token: {
                  fontFamily: "Inter",
                },
                components: {
                  Menu: {
                    colorPrimary: "rgba(255, 255, 255, 0.88)",
                    colorPrimaryBorder: "rgb(252, 27, 28)",
                    itemSelectedBg: "rgba(255, 255, 255, 0.06)",
                    colorBgContainer: "rgb(36, 40, 50)",
                    colorText: "rgba(255, 255, 255, 0.88)",
                    itemColor: "rgba(255, 255, 255, 0.88)",
                    itemHoverColor: "rgb(252, 27, 28)",
                    itemHoverBg: "rgba(255, 255, 255, 0.06)",
                    itemActiveBg: "rgba(252, 27, 28, 0.6)",
                    groupTitleColor: "rgb(255, 255, 255)",
                    popupBg: "rgb(36, 40, 50)",
                  },
                },
              }}
            >
              <App authState={authState} authDispatch={authState.authDispatch} />
            </ConfigProvider>
          </HelmetProvider>
        </BrowserRouter>
      )}
    </AuthConsumer>
  </AuthProvider>,
);
