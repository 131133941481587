import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(advancedFormat);

const Note = ({ key, note }) => {
  return (
    <div className="flex flex-col items-start justify-center w-full gap-2 px-5 py-4 border border-gray-100 rounded-lg shadow-sm" key={key}>
      <div className="flex items-center justify-between w-full">
        <p className="font-sans text-lg font-semibold">{note.userName}</p>
        <p className="text-sm text-gray-400">{dayjs(note.date).format("MMMM Do YYYY")}</p>
      </div>
      <p className="w-full italic">{note.note}</p>
    </div>
  );
};

export default Note;
