import jsPDF from "jspdf";
import "jspdf-autotable";
import { v4 as uuidv4 } from "uuid";
import wbsLogo from "./assets/wbsLogo.png";
import dayjs from "dayjs";
import { formatCurrency } from "./utils";

export const GenerateTransactionReceipt = (data) => {
  try {
    const doc = new jsPDF("l", "pt", "letter");
    doc.setFontSize(40);
    let pageWidth = doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.getHeight();
    let imgWidth = 126.8;
    let imgHeight = 80;
    let imgXPos = imgWidth / 2;
    doc.addImage(wbsLogo, "PNG", imgXPos, 30, imgWidth, imgHeight, "wbsLogo", "NONE", 0);
    doc.setDrawColor("#fc1b1c");
    doc.setTextColor("#fc1b1c");
    doc.setLineWidth(0.8);
    doc.setFontSize(10);
    let y = 56;
    doc.text("1624 Barton Chapel Rd.", 270, y, { align: "center" });
    let remitTo = "REMIT PAYMENTS TO\nP.O. BOX 1576\nAUGUSTA, GA 30919-1756";
    let remitToDimensions = doc.getTextDimensions("AUGUSTA, GA 30919-1756");
    y += 10;
    doc.line(450 - remitToDimensions.w / 2 - 25, y - 15, 450 + remitToDimensions.w / 2 + 25, y - 15);
    doc.line(450 - remitToDimensions.w / 2 - 25, y + 2 + remitToDimensions.h * 3, 450 + remitToDimensions.w / 2 + 25, y + 2 + remitToDimensions.h * 3);
    doc.line(450 - remitToDimensions.w / 2 - 25, y - 15, 450 - remitToDimensions.w / 2 - 25, y + 2 + remitToDimensions.h * 3);
    doc.line(450 + remitToDimensions.w / 2 + 25, y - 15, 450 + remitToDimensions.w / 2 + 25, y + 2 + remitToDimensions.h * 3);
    doc.text(remitTo, 450, y, { align: "center" });
    y += 4;
    doc.text("Augusta, GA 30909", 270, y, { align: "center" });
    doc.text(`Transaction Date: ${dayjs(data.date).format("MM/DD/YYYY")}`, 570, y, { align: "left" });
    y += 14;
    doc.text("TEL: (706) 738-0751", 270, y, { align: "center" });
    doc.text(`Transaction ID: ${data.transactionId.replace("transaction_", "").toUpperCase()}`, 570, y, { align: "left" });
    y += 14;
    doc.text("FAX: (706) 736-4128", 270, y, { align: "center" });
    y += 14;
    y += 14;
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.line(30, y, pageWidth - 30, y);
    y += 14;
    doc.text("TRANSACTION RECEIPT", 45, y + 4, { align: "left" });
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(10);
    if (data.customerName && data.customerName.length > 0) {
      let soldText = `SOLD TO: ${data.customerName.toUpperCase()}`;
      if (data.soldTo && data.soldTo.length > 0) {
        soldText += ` - ${data.soldTo.toUpperCase()}`;
      }
      doc.text(soldText, pageWidth / 2, y + 3, { align: "center" });
    } else if (data.soldTo && data.soldTo.length > 0) {
      doc.text(`SOLD TO: ${data.soldTo.toUpperCase()}`, pageWidth / 2, y + 3, {
        align: "center",
      });
    } else {
      doc.text(``, pageWidth / 2, y + 3, { align: "center" });
    }
    doc.text(
      `${formatCurrency(data.total)} PAID WITH ${data?.cardBrand?.length > 0 ? data.cardBrand.toUpperCase() : "CARD"} ENDING IN ${data.cardLast4}`,
      pageWidth - 45,
      y + 3,
      { align: "right" },
    );
    y += 14;
    doc.line(30, y, pageWidth - 30, y);
    y += 14;
    // doc.text(`TRANSACTION ID: ${data.transactionId.replace("transaction_", "").toUpperCase()}`, pageWidth / 2, 110, {
    //   align: "center",
    // });
    let bodyData = [];
    data.items.forEach((item) => {
      bodyData.push([item.productNumber, item.item, item.description, item.quantity, formatCurrency(item.chargePrice), formatCurrency(item.total)]);
    });
    doc.autoTable({
      headStyles: {
        fillColor: "#ffffff",
        textColor: "#fc1b1c",
      },
      columnStyles: {
        0: { halign: "left" },
        1: { halign: "left" },
        2: { cellWidth: "auto" },
        3: { halign: "right" },
        4: { halign: "right" },
        5: { halign: "right" },
      },
      styles: {
        fontSize: 10,
        rowHeight: 2,
        cellPadding: 6,
        textColor: "#fc1b1c",
        lineWidth: 0.5,
        lineColor: "#fc1b1c",
      },
      theme: "plain",
      head: [
        [
          { content: "Product No.", styles: { halign: "left" } },
          { content: "Item", styles: { halign: "left" } },
          { content: "Description", styles: { halign: "left" } },
          { content: "Quantity", styles: { halign: "right" } },
          { content: "Unit Price", styles: { halign: "right" } },
          { content: "Amount", styles: { halign: "right" } },
        ],
      ],
      body: bodyData,
      margin: { top: y, left: 30, right: 30, bottom: 15 },
      didDrawPage: (d) => {
        y = d.cursor.y + 30;
      },
    });
    y = 500;
    doc.line(30, y - 18, pageWidth - 30, y - 18);
    doc.text(
      `TOOLS, ELECTRICAL & REPAIR PARTS CANNOT BE RETURNED. OTHER RETURNS (AFTER APPROVAL)\nMUST BE ACCOMPANIED BY AN INVOICE OR TRANSACTION RECEIPT WITHIN 30 DAYS.\nA 20% RESTOCKING FEE WILL BE CHARGED`,
      30,
      y,
      {
        align: "left",
      },
    );
    doc.setFontSize(12);
    doc.text(`SUBTOTAL: ${formatCurrency(data.saleAmount)}`, pageWidth - 30, y, { align: "right" });
    y += 14;
    doc.text(`SALES TAX: ${formatCurrency(data.salesTax)}`, pageWidth - 30, y, {
      align: "right",
    });
    y += 14;
    doc.text(`PROCESSING FEES: ${formatCurrency(data.processingFees)}`, pageWidth - 30, y, { align: "right" });
    y += 14;
    doc.setFontSize(10);
    doc.text("RECEIVED BY:\n\nSIGNATURE:", 30, y, { align: "left" });
    doc.line(120, y + 4, 350, y + 4);
    doc.line(120, y + 26, 350, y + 26);
    doc.setFontSize(12);
    doc.text(`TOTAL: ${formatCurrency(data.total)}`, pageWidth - 30, y, {
      align: "right",
    });
    y += 14;
    if (data.refunds && data.refunds.length > 0) {
      let refunded = 0;
      data.refunds.forEach((refund) => {
        refunded += refund.refundAmount;
      });
      doc.text(`REFUND ISSUED FOR: ${formatCurrency(refunded)}`, pageWidth - 30, y, { align: "right" });
    }

    const pageCount = doc.internal.getNumberOfPages();

    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(10);
      doc.text(
        `PAGE ${i.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })} / ${pageCount.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}`,
        pageWidth - 30,
        pageHeight - 20,
        {
          align: "right",
        },
      );
    }

    return doc;
  } catch (error) {
    console.log(error);
  }
};
