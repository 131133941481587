import axiosInstance from "./axios";
import qs from "qs";

export const CreateANewProduct = (data) => {
  return axiosInstance.post("/product/new", data);
};

export const GetAllProducts = () => {
  return axiosInstance.get("/product");
};

export const GetAProductByID = (id) => {
  return axiosInstance.get(`/product/${id}`);
};

export const UpdateAProductByID = (id, data) => {
  return axiosInstance.put(`/product/${id}`, data);
};

export const GetAllProductCategories = () => {
  return axiosInstance.get("/product-category");
};

export const CreateANewProductCategory = (data) => {
  return axiosInstance.post("/product-category", data);
};

export const GetAProductCategoryByID = (id) => {
  return axiosInstance.get(`/product-category/${id}`);
};

export const UpdateProductCategory = (id, data) => {
  return axiosInstance.put(`/product-category/${id}`, data);
};

export const QueryProducts = (data) => {
  let queryParam = {};
  if (data.search && data.search.length > 0) {
    queryParam.search = data.search;
  }
  if (data.page && !isNaN(data.page)) {
    queryParam.page = data.page;
  }
  if (data.limit && !isNaN(data.limit)) {
    queryParam.limit = data.limit;
  }
  const queryString = qs.stringify(queryParam);
  return axiosInstance.get(`/product/query?${queryString}`);
};

export const QueryProductCategories = (data) => {
  let queryParam = {};
  if (data.search && data.search.length > 0) {
    queryParam.search = data.search;
  }
  if (data.page && !isNaN(data.page)) {
    queryParam.page = data.page;
  }
  if (data.limit && !isNaN(data.limit)) {
    queryParam.limit = data.limit;
  }
  const queryString = qs.stringify(queryParam);
  return axiosInstance.get(`/product-category/query?${queryString}`);
};
