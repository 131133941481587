import axiosInstance from "./axios";
import qs from "qs";

export const CreateACustomer = (data) => {
  return axiosInstance.post("/customer/create", data);
};

export const GetAllCustomers = (lean) => {
  return axiosInstance.get(`/customer?lean=${lean ? "true" : "false"}`);
};

export const GetACustomerByID = (id) => {
  return axiosInstance.get(`/customer/${id}`);
};

export const QueryCustomers = (data) => {
  let queryParam = {};
  if (data.search && data.search.length > 0) {
    queryParam.search = data.search;
  }
  if (data.page && !isNaN(data.page)) {
    queryParam.page = data.page;
  }
  if (data.limit && !isNaN(data.limit)) {
    queryParam.limit = data.limit;
  }
  const queryString = qs.stringify(queryParam);
  return axiosInstance.get(`/customer/query?${queryString}`);
};

export const UpdateACustomerByID = (id, data) => {
  return axiosInstance.put(`/customer/${id}`, data);
};

export const SearchCustomers = (query) => {
  let search = `/customer/search?find=${query}`;
  search = encodeURI(search);
  return axiosInstance.get(search);
};

export const GetCreditInfo = (customerId) => {
  return axiosInstance.get(`/customer/creditInfo/${customerId}`);
};

export const ValidateCustomerNo = (query) => {
  let queryParam = {};
  if (query && query.length > 0) {
    queryParam.query = query;
  }
  const queryString = qs.stringify(queryParam);
  return axiosInstance.get(`/customer/validate/customerNo?${queryString}`);
};

export const AddNoteToCustomer = (customerId, data) => {
  return axiosInstance.put(`/customer/${customerId}/note`, data);
};
