import dayjs from "dayjs";
import validator from "validator";

export const invoiceBasicsForm = {
  customerLabel: {
    fieldName: "customerLabel",
    label: "Customer Search",
    inputType: "text",
    placeholder: "Pick a customer",
    defaultValue: "",
    config: {
      required: "Customer is required",
      validate: (value) => value.length >= 2 || "Customer is required and must be at least 2 characters long",
    },
    disabled: true,
  },
  dateInvoiced: {
    fieldName: "dateInvoiced",
    label: "Date Invoiced",
    inputType: "date",
    placeholder: "Date Invoiced",
    defaultValue: "",
    config: {
      required: "Invoiced date is required",
      validate: (value) => (value && dayjs(value).isValid()) || "Date invoiced is required",
    },
  },
  taxExempt: {
    fieldName: "taxExempt",
    label: "Tax Exempt",
    inputType: "switch",
    placeholder: "Tax Exempt",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  customerPo: {
    fieldName: "customerPo",
    label: "Customer PO#",
    inputType: "text",
    placeholder: "Provide the customer's PO number if applicable",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  terms: {
    fieldName: "terms",
    label: "Payment Terms",
    inputType: "select",
    placeholder: "Select the default payment terms for this customer",
    defaultValue: "NET 30",
    options: [
      { label: "NET 10", value: "NET 10" },
      { label: "NET 15", value: "NET 15" },
      { label: "NET 30", value: "NET 30" },
      { label: "NET 45", value: "NET 45" },
      { label: "NET 60", value: "NET 60" },
      { label: "NET 90", value: "NET 90" },
      { label: "Collect on Delivery", value: "COD" },
    ],
    config: {
      required: false,
    },
  },
  dueDate: {
    fieldName: "dueDate",
    label: "Due Date",
    inputType: "date",
    placeholder: "Due Date",
    defaultValue: "",
    config: {
      required: "Due date is required",
      validate: (value) => (value && dayjs(value).isValid()) || "Due Date is required",
    },
  },
};
