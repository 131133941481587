import { useState, useEffect } from "react";
import { GetAProductByID, GetAllProductCategories, UpdateAProductByID } from "../../actions/products";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input, InputNumber, Select } from "antd";
import unitsOfMeasure from "../../data/unitsOfMeasure";
import utilities from "../../components/utilities";

const ProductID = ({ authState }) => {
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [dummyLoading, setDummyLoading] = useState(false);
  const [rawData, setRawData] = useState(null);
  const [product, setProduct] = useState(null);
  const [productCategories, setProductCategories] = useState([]);

  // const navigate = useNavigate();
  const { productID } = useParams();

  useEffect(() => {
    GetAProductByID(productID)
      .then((res) => {
        setProduct(res.data);
        setRawData(JSON.parse(JSON.stringify(res.data)));
        GetAllProductCategories()
          .then((res) => {
            setProductCategories(res.data);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error(err.response.data ? err.response.data.message : "An error occurred while fetching product categories. Please try again.");
          });
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error occurred while fetching products. Please try again.");
      });
  }, []);

  const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const calculateMarkup = (price, markup) => {
    let markedUp = price + price * (markup / 100);
    markedUp = parseFloat(markedUp.toFixed(2));
    return markedUp;
  };

  const updatePriceTier = (tier, value) => {
    setDummyLoading(true);
    let tmp = product;
    tmp.priceTiers[tier] = value;
    setProduct(tmp);
    setTimeout(() => setDummyLoading(false), 500);
  };

  const updatePriceTotal = (tier, value) => {
    var difference = ((value - product.price) / product.price) * 100;
    var roundedPercent = Math.round(1000 * difference) / 1000;
    if (isNaN(roundedPercent)) {
      toast.error("Please enter a valid number.");
    }
    setDummyLoading(true);
    let tmp = product;
    tmp.priceTiers[tier] = roundedPercent;
    setProduct(tmp);
    setTimeout(() => setDummyLoading(false), 500);
  };

  const updateSelect = (field, value) => {
    setDummyLoading(true);
    let tmp = product;
    tmp[field] = value;
    setProduct(tmp);
    setTimeout(() => setDummyLoading(false), 500);
  };

  const saveChanges = () => {
    let difference = utilities.getJSONDiff(rawData, product);
    if (Object.keys(difference.priceTiers).length === 0) {
      delete difference.priceTiers;
    }
    if (difference && Object.keys(difference).length > 0) {
      setLoading(true);
      UpdateAProductByID(productID, difference)
        .then((res) => {
          toast.success("Product updated successfully.");
          GetAProductByID(productID)
            .then((res) => {
              setProduct(res.data);
              setRawData(JSON.parse(JSON.stringify(res.data)));
              GetAllProductCategories()
                .then((res) => {
                  setProductCategories(res.data);
                  setTimeout(() => setLoading(false), 700);
                })
                .catch((err) => {
                  toast.error(err.response.data ? err.response.data.message : "An error occurred while fetching product categories. Please try again.");
                });
            })
            .catch((err) => {
              toast.error(err.response.data ? err.response.data.message : "An error occurred while fetching products. Please try again.");
            });
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error occurred while updating the product. Please try again.");
          setLoading(false);
        });
    } else {
      toast.error("No changes detected.");
    }
  };

  return !loading ? (
    <div className="flex flex-col items-start justify-start w-full h-full mx-auto max-w-7xl">
      <h1 className="text-lg font-bold">Product Preview | #{product.item}</h1>
      <div className="grid w-full grid-cols-3 gap-3 py-4">
        <div className="flex flex-col items-start justify-center w-full gap-1">
          <p className="text-sm font-bold">Product Category</p>
          <Select
            value={product.categoryId}
            onChange={(v) => updateSelect("categoryId", v)}
            style={{ width: "100%" }}
            options={productCategories.map((pc) => ({ value: pc.categoryId, label: `${pc.name} | ${pc.description}` }))}
            placeholder="Select a category"
            showSearch
            filterOption={filterOption}
          />
        </div>
        <div className="flex flex-col items-start justify-center w-full gap-1">
          <p className="text-sm font-bold">Item #</p>
          <Input
            value={product.item}
            onChange={(e) => setProduct({ ...product, item: e.target.value })}
            style={{ width: "100%" }}
            type="text"
            placeholder="Item #"
          />
        </div>
        <div className="flex flex-col items-start justify-center w-full gap-1">
          <p className="text-sm font-bold">Product #</p>
          <Input
            value={product.productNumber}
            onChange={(e) => setProduct({ ...product, productNumber: e.target.value })}
            style={{ width: "100%" }}
            type="text"
            placeholder="Product #"
          />
        </div>
        <div className="flex flex-col items-start justify-center w-full col-span-2 row-span-2 gap-1">
          <p className="text-sm font-bold">Description</p>
          <Input.TextArea
            value={product.description}
            onChange={(e) => setProduct({ ...product, description: e.target.value })}
            autoSize={{ maxRows: 4, minRows: 4 }}
            style={{ width: "100%" }}
            type="text"
            placeholder="Product Description"
          />
        </div>
        <div className="flex flex-col items-start justify-center w-full gap-1">
          <p className="text-sm font-bold">Unit of Measure</p>
          <Select
            value={product.unitOfMeasure}
            onChange={(v) => updateSelect("unitOfMeasure", v)}
            style={{ width: "100%" }}
            options={unitsOfMeasure}
            placeholder="Pick a Unit of Measure"
            showSearch
            filterOption={filterOption}
          />
        </div>
        <div className="flex flex-col items-start justify-center w-full gap-1">
          <p className="text-sm font-bold">Base Price</p>
          <InputNumber
            value={product.price}
            onChange={(e) => setProduct({ ...product, price: e })}
            style={{ width: "100%" }}
            placeholder="Enter a price"
            prefix="$"
            step={0.01}
            min={0.0}
            controls={false}
          />
        </div>
        <div className="flex flex-col items-start justify-center w-full col-span-3 gap-5 pt-2">
          <p className="text-lg font-semibold">Price Tiers & Markups</p>
          <dl className="w-full border-gray-300 divide-y divide-gray-200 border-y">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4">
              <div className="flex items-center justify-start">
                <dt className="text-sm font-semibold">Tier 1</dt>
              </div>
              <dd className="flex mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 sm:gap-4">
                <div className="flex flex-col items-start justify-center w-1/2 gap-1">
                  <p className="text-sm font-bold">Markup</p>
                  <InputNumber
                    value={product.priceTiers.tier1}
                    style={{ width: "100%" }}
                    placeholder="Enter a markup percentage"
                    suffix="%"
                    step={0.001}
                    min={0.001}
                    controls={false}
                    onChange={(v) => updatePriceTier("tier1", v)}
                  />
                </div>
                <div className="flex flex-col items-start justify-center w-1/2 gap-1">
                  <p className="text-sm font-bold">Price</p>
                  <InputNumber
                    value={calculateMarkup(product.price, product.priceTiers.tier1)}
                    onBlur={(e) => updatePriceTotal("tier1", e.target.value)}
                    style={{ width: "100%" }}
                    placeholder="Price for Tier 1 customers"
                    prefix="$"
                    step={0.01}
                    min={0}
                    controls={false}
                  />
                </div>
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4">
              <div className="flex items-center justify-start">
                <dt className="text-sm font-semibold">Tier 2</dt>
              </div>
              <dd className="flex mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 sm:gap-4">
                <div className="flex flex-col items-start justify-center w-1/2 gap-1">
                  <p className="text-sm font-bold">Markup</p>
                  <InputNumber
                    value={product.priceTiers.tier2}
                    style={{ width: "100%" }}
                    placeholder="Enter a markup percentage"
                    suffix="%"
                    step={0.001}
                    min={0.001}
                    controls={false}
                    onChange={(v) => updatePriceTier("tier2", v)}
                  />
                </div>
                <div className="flex flex-col items-start justify-center w-1/2 gap-1">
                  <p className="text-sm font-bold">Price</p>
                  <InputNumber
                    value={calculateMarkup(product.price, product.priceTiers.tier2)}
                    onBlur={(e) => updatePriceTotal("tier2", e.target.value)}
                    style={{ width: "100%" }}
                    placeholder="Price for Tier 2 customers"
                    prefix="$"
                    step={0.01}
                    min={0}
                    controls={false}
                  />
                </div>
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4">
              <div className="flex items-center justify-start">
                <dt className="text-sm font-semibold">Tier 3</dt>
              </div>
              <dd className="flex mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 sm:gap-4">
                <div className="flex flex-col items-start justify-center w-1/2 gap-1">
                  <p className="text-sm font-bold">Markup</p>
                  <InputNumber
                    value={product.priceTiers.tier3}
                    style={{ width: "100%" }}
                    placeholder="Enter a markup percentage"
                    suffix="%"
                    step={0.001}
                    min={0.001}
                    controls={false}
                    onChange={(v) => updatePriceTier("tier3", v)}
                  />
                </div>
                <div className="flex flex-col items-start justify-center w-1/2 gap-1">
                  <p className="text-sm font-bold">Price</p>
                  <InputNumber
                    value={calculateMarkup(product.price, product.priceTiers.tier3)}
                    onBlur={(e) => updatePriceTotal("tier3", e.target.value)}
                    style={{ width: "100%" }}
                    placeholder="Price for Tier 3 customers"
                    prefix="$"
                    step={0.01}
                    min={0}
                    controls={false}
                  />
                </div>
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4">
              <div className="flex items-center justify-start">
                <dt className="text-sm font-semibold">Tier 4</dt>
              </div>
              <dd className="flex mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 sm:gap-4">
                <div className="flex flex-col items-start justify-center w-1/2 gap-1">
                  <p className="text-sm font-bold">Markup</p>
                  <InputNumber
                    value={product.priceTiers.tier4}
                    style={{ width: "100%" }}
                    placeholder="Enter a markup percentage"
                    suffix="%"
                    step={0.001}
                    min={0.001}
                    controls={false}
                    onChange={(v) => updatePriceTier("tier4", v)}
                  />
                </div>
                <div className="flex flex-col items-start justify-center w-1/2 gap-1">
                  <p className="text-sm font-bold">Price</p>
                  <InputNumber
                    value={calculateMarkup(product.price, product.priceTiers.tier4)}
                    onBlur={(e) => updatePriceTotal("tier4", e.target.value)}
                    style={{ width: "100%" }}
                    placeholder="Price for Tier 4 customers"
                    prefix="$"
                    step={0.01}
                    min={0}
                    controls={false}
                  />
                </div>
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4">
              <div className="flex items-center justify-start">
                <dt className="text-sm font-semibold">Tier 5</dt>
              </div>
              <dd className="flex mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 sm:gap-4">
                <div className="flex flex-col items-start justify-center w-1/2 gap-1">
                  <p className="text-sm font-bold">Markup</p>
                  <InputNumber
                    value={product.priceTiers.tier5}
                    style={{ width: "100%" }}
                    placeholder="Enter a markup percentage"
                    suffix="%"
                    step={0.001}
                    min={0.001}
                    controls={false}
                    onChange={(v) => updatePriceTier("tier5", v)}
                  />
                </div>
                <div className="flex flex-col items-start justify-center w-1/2 gap-1">
                  <p className="text-sm font-bold">Price</p>
                  <InputNumber
                    value={calculateMarkup(product.price, product.priceTiers.tier5)}
                    onBlur={(e) => updatePriceTotal("tier5", e.target.value)}
                    style={{ width: "100%" }}
                    placeholder="Price for Tier 5 customers"
                    prefix="$"
                    step={0.01}
                    min={0}
                    controls={false}
                  />
                </div>
              </dd>
            </div>
          </dl>
          <div className="flex items-center justify-end w-full px-4 md:px-5 lg:col-span-3">
            <Button onClick={() => saveChanges()}>Save Changes</Button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex items-center justify-center w-full h-screen overflow-x-hidden bg-slate-50">
      <p className="text-2xl font-bold animate-pulse text-wbs-gray">Loading...</p>
    </div>
  );
};

export default ProductID;
