import { Button, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatPhoneNumber } from "../../../../data/utils";
import toast from "react-hot-toast";

const CustomerDefaultContact = ({ customer }) => {
  const [defaultContact, setDefaultContact] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (customer) {
      const primaryContact = customer.contacts.find((contact) => contact.primary);
      if (primaryContact) {
        setDefaultContact(primaryContact);
      } else {
        if (customer.contacts.length > 0) {
          setDefaultContact(customer.contacts[0]);
        } else {
          setDefaultContact(null);
        }
      }
      setTimeout(() => setLoading(false), 700);
    }
  }, []);

  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-start justify-start w-full gap-2 p-6 mt-4 bg-white rounded-md shadow-md shadow-gray-200">
      <div className="flex flex-row items-center justify-between w-full">
        <p className="text-xl font-bold text-black">Default Contact</p>
        {/* <Button onClick={() => navigate(`/customers/${customer.customerId}/contacts`)}>Edit</Button> */}
        <Button onClick={() => toast("This feature is not yet available, check again soon!")}>Edit</Button>
      </div>
      {loading ? (
        <div className="flex flex-row items-center justify-center w-full h-full gap-2">
          <Skeleton active />
        </div>
      ) : defaultContact ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <div className="flex flex-row items-center justify-between w-full">
            <p className="font-semibold text-slate-800">Name</p>
            <p>{defaultContact.name ?? "Not Provided"}</p>
          </div>
          <div className="flex flex-row items-center justify-between w-full">
            <p className="font-semibold text-slate-800">Phone Number</p>
            <p>{formatPhoneNumber(defaultContact.phoneNumber)}</p>
          </div>
          <div className="flex flex-row items-center justify-between w-full">
            <p className="font-semibold text-slate-800">Email</p>
            <p>{defaultContact.email ?? "Not Provided"}</p>
          </div>
          <div className="flex flex-row items-center justify-between w-full">
            <p className="font-semibold text-slate-800">Fax</p>
            <p>{formatPhoneNumber(defaultContact.fax)}</p>
          </div>
          <div className="flex flex-row items-center justify-between w-full">
            <p className="font-semibold text-slate-800">Position / Role</p>
            <p>{defaultContact.position ?? "Not Provided"}</p>
          </div>
          <div className="flex flex-row items-center justify-between w-full">
            <p className="font-semibold text-slate-800">Primary Contact</p>
            <p>{defaultContact.primary ? "Primary" : "Alternative"}</p>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-sm font-semibold text-center text-gray-500 uppercase">No contact information found</p>
        </div>
      )}
    </div>
  );
};

export default CustomerDefaultContact;
