import { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { QueryCustomers } from "../../actions/customer";
import toast from "react-hot-toast";

const Customers = ({ authState }) => {
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      QueryCustomers({ search: "", page: 0, limit: 10 })
        .then((res) => {
          setCustomers(res.data.results);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.response : "An error occurred");
          navigate("/");
        });
    }
    return () => {
      inView = false;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <div className="flex-grow flow-root w-full px-2">
        <div className="inline-block w-full min-w-full py-2 align-middle">
          {loading ? (
            <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">Loading...</p>
          ) : (
            <div className="flex flex-col items-center justify-start w-full h-full">
              <div className="flex flex-row items-center justify-between w-full pb-4 border-b border-gray-200">
                <div className="flex flex-row items-center justify-start gap-2">
                  <div className="flex flex-col items-start justify-center mr-4">
                    <p className="text-xl font-semibold">Customers</p>
                    <p className="text-sm text-gray-500 font-base">A comprehensive overview of all customers</p>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-end gap-2">
                  <Button onClick={() => navigate("/customers/new")}>New Customer Profile</Button>
                </div>
              </div>
              <Table dataSource={customers} loading={loading} rowKey="customerId" className="w-full" sticky={true}>
                <Table.Column title="Customer No." dataIndex="customerNo" key="customerNo" />
                <Table.Column title="Name" dataIndex="name" key="name" />
                <Table.Column title="Category" dataIndex="category" key="category" />
                <Table.Column title="Customer Type" dataIndex="customerType" key="customerType" />
                <Table.Column
                  title=""
                  dataIndex="customerId"
                  key="customerId"
                  align="right"
                  width="100px"
                  render={(v) => <Button onClick={() => navigate(`/customers/${v}`)}>Open</Button>}
                />
              </Table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Customers;
