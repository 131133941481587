import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../views/dashboard";
import Navbar from "../components/navbar";
import Customers from "../views/customers";
import Products from "../views/products";
import ProductID from "../views/products/productId";
import NewCustomerView from "../views/customers/newCustomer";
import CustomerProfile from "../views/customers/view/customerProfile";
import ProductCategories from "../views/productCategories";
import OpenProductCategory from "../views/productCategories/openProductCategory";
import NewSale from "../views/terminal/newSale";
import Transactions from "../views/transactions";
import OpenTransaction from "../views/transactions/openTransaction";
import Invoices from "../views/operations/invoices";
import CreateInvoice from "../views/operations/invoices/create";
import OpenInvoice from "../views/operations/invoices/open";
import CustomerNotes from "../views/customers/view/customerNotes";
import Vendors from "../views/vendors";
import NewVendorView from "../views/vendors/newVendor";

const CoreLayout = ({ authState, authDispatch }) => {
  return authState.isAuth ? (
    <Routes>
      <Route path="*" element={<Navbar authState={authState} authDispatch={authDispatch} />}>
        <Route index element={<Dashboard authState={authState} />} />
        <Route path="customers" element={<Customers authState={authState} />} />
        <Route path="customers/new" element={<NewCustomerView authState={authState} />} />
        <Route path="customers/:customerId" element={<CustomerProfile authState={authState} />} />
        <Route path="customers/:customerId/notes" element={<CustomerNotes authState={authState} />} />
        <Route path="products" element={<Products authState={authState} />} />
        <Route path="products/categories" element={<ProductCategories authState={authState} />} />
        <Route path="products/categories/:categoryId" element={<OpenProductCategory authState={authState} />} />
        <Route path="products/:productID" element={<ProductID authState={authState} />} />
        <Route path="sale" element={<NewSale authState={authState} />} />
        <Route path="transactions" element={<Transactions authState={authState} />} />
        <Route path="transactions/:transactionId" element={<OpenTransaction authState={authState} />} />
        <Route path="operations/invoices" element={<Invoices authState={authState} />} />
        <Route path="operations/invoices/new" element={<CreateInvoice authState={authState} />} />
        <Route path="operations/invoices/:invoiceId" element={<OpenInvoice authState={authState} />} />
        <Route path="operations/vendors" element={<Vendors authState={authState} />} />
        <Route path="operations/vendors/new" element={<NewVendorView authState={authState} />} />
      </Route>
    </Routes>
  ) : (
    <Navigate to="/login" replace />
  );
};

export default CoreLayout;
