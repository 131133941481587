import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./views/auth/login";
import { GetUser } from "./actions/auth";
import { Toaster } from "react-hot-toast";
import CoreLayout from "./layouts";

const App = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("auth-token");
    if (token) {
      GetUser()
        .then((response) => {
          authDispatch({
            type: "valid-login",
            payload: {
              token: localStorage.getItem("auth-token"),
              role: response.data.role,
              user: response.data,
            },
          });
          setLoading(false);
        })
        .catch((err) => {
          authDispatch({ type: "logged-out" });
          localStorage.removeItem("auth-token");
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  return !loading ? (
    <div className="w-full h-screen overflow-x-hidden">
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 5000,
          style: {
            background: "rgba(36,40,50,0.8)",
            color: "#fff",
            borderRadius: "5px",
            backdropFilter: "blur(2px)",
          },
          className: "maxZIndex",
          success: {
            style: {
              paddingLeft: "15px",
            },
          },
          error: {
            style: {
              paddingLeft: "15px",
            },
          },
        }}
      />
      <Routes>
        <Route path="login" element={<Login authState={authState} authDispatch={authDispatch} />} />
        <Route path="/*" element={authState.isAuth ? <CoreLayout authDispatch={authDispatch} authState={authState} /> : <Navigate to="/login" replace />} />
        {/* <Route path="forgotPassword" element={<ForgotPassword authDispatch={authDispatch} authState={authState} />} />
        <Route path="resetPassword" element={<ResetPasswordView authDispatch={authDispatch} authState={authState} />} />
        <Route path="invite/:inviteId" element={<Invite authDispatch={authDispatch} authState={authState} />} />
        <Route path="/*" element={authState.isAuth ? <CoreLayout authState={authState} authDispatch={authDispatch} /> : <Navigate to="/login" />} /> */}
      </Routes>
    </div>
  ) : (
    <div className="flex items-center justify-center w-full h-screen overflow-x-hidden bg-slate-50">
      <p className="text-2xl font-bold animate-pulse text-wbs-gray">Loading...</p>
    </div>
  );
};

export default App;
