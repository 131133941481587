import axiosInstance from "./axios";
import qs from "qs";

export const GetInvoicesByCustomer = (customerId) => {
  return axiosInstance.get(`/invoice/customer/${customerId}`);
};

export const GetAllInvoices = () => {
  let query = {
    isLean: true,
  };
  return axiosInstance.get(`/invoice?${qs.stringify(query)}`);
};

export const SubmitNewInvoice = (data) => {
  return axiosInstance.post("/invoice/create", data);
};

export const GetInvoiceByID = (invoiceId) => {
  return axiosInstance.get(`/invoice/${invoiceId}`);
};

export const AddInvoiceLineItem = (invoiceId, data) => {
  return axiosInstance.post(`/invoice/${invoiceId}/lineItem`, data);
};

export const UpdateInvoiceLineItem = (invoiceId, lineItemId, data) => {
  return axiosInstance.put(`/invoice/${invoiceId}/lineItem/${lineItemId}`, data);
};

export const RemoveInvoiceLineItem = (invoiceId, lineItemId) => {
  return axiosInstance.delete(`/invoice/${invoiceId}/lineItem/${lineItemId}`);
};

export const AddNoteToInvoice = (invoiceId, data) => {
  return axiosInstance.put(`/invoice/${invoiceId}/note`, data);
};

export const UpdateInvoiceSoldTo = (invoiceId, data) => {
  return axiosInstance.put(`/invoice/${invoiceId}/soldTo`, data);
};

export const UpdateInvoiceDetails = (invoiceId, data) => {
  return axiosInstance.put(`/invoice/${invoiceId}/details`, data);
};

export const UpdateInvoiceTermsAndTax = (invoiceId, data) => {
  return axiosInstance.put(`/invoice/${invoiceId}/termsAndTax`, data);
};

export const LogInvoicePayment = (invoiceId, data) => {
  return axiosInstance.put(`/invoice/${invoiceId}/logPayment`, data);
};
