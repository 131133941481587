import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Button } from "antd";
import { GetAProductCategoryByID, UpdateProductCategory } from "../../actions/products";
import FormController from "../../components/FormController";
import { productCategoryForm } from "../../forms/products/category";

const OpenProductCategory = () => {
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(true);

  const { categoryId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    reloadData();
  }, [categoryId]);

  const reloadData = () => {
    setLoading(true);
    GetAProductCategoryByID(categoryId)
      .then((res) => {
        setCategory(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 700);
      })
      .catch((err) => {
        toast.error(err.message);
        navigate("/customers");
      });
  };

  const updateCategory = (data) => {
    if (data.formModified) {
      let payload = {};
      if (data.modifiedFields.name) {
        payload.name = data.name;
      }
      if (data.modifiedFields.description) {
        payload.description = data.description;
      }
      setLoading(true);
      UpdateProductCategory(categoryId, payload)
        .then((res) => {
          toast.success("Category updated successfully");
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.response : "Error occurred while updating product category");
          setTimeout(() => setLoading(false), 700);
        });
    } else {
      toast("No changes detected", { icon: "🔎" });
    }
  };

  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <div className="flex-grow flow-root w-full px-2">
        <div className="inline-block w-full min-w-full py-2 align-middle">
          {loading ? (
            <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">Loading...</p>
          ) : (
            <div className="flex flex-col items-center justify-start w-full h-full">
              <div className="flex flex-row items-center justify-between w-full pb-4 border-b border-gray-200">
                <div className="flex flex-row items-center justify-start gap-2">
                  <div className="flex flex-col items-start justify-center mr-4">
                    <p className="text-xl font-semibold">Product Category - {category.name}</p>
                    <p className="text-sm font-semibold text-gray-500">{category.description}</p>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-end gap-2">
                  <Button onClick={() => toast("This feature is not yet available, check again soon!")}>Reports</Button>
                  <Button onClick={() => toast("This feature is not yet available, check again soon!")} danger>
                    Delete Category
                  </Button>
                  {/* TODO: On delete - Check if there's products assigned to the category, if so - migrate those, otherwise, confirm, then delete. */}
                </div>
              </div>
              <div className="grid w-full grid-cols-1 gap-4 mt-4">
                <div className="flex flex-col items-start justify-start w-full gap-2 p-6 mt-4 bg-white rounded-md shadow-md shadow-gray-200">
                  <p className="text-xl font-semibold">Edit Category</p>
                  <FormController
                    onSubmit={updateCategory}
                    fields={productCategoryForm}
                    values={category}
                    defaultValues={category}
                    buttonText="Update"
                    fullWidth={true}
                  />
                </div>
                <div className="flex flex-col items-start justify-start w-full gap-2 p-6 mt-4 bg-white rounded-md shadow-md shadow-gray-200">
                  <p className="text-xl font-semibold">Products</p>
                  <p className="text-sm font-semibold text-gray-500">A list of products within this category</p>
                  {/* TODO: List of products */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OpenProductCategory;
