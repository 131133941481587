import { useState } from "react";
import { Button, Modal } from "antd";
import FormController from "../../../../components/FormController";
import { customerBasicInfo } from "../../../../forms/customer/basicInfo";
import toast from "react-hot-toast";
import { UpdateACustomerByID } from "../../../../actions/customer";

const CustomerGeneralInfo = ({ customer, triggerReload }) => {
  const [modal, setModal] = useState(false);

  const submitUpdate = (data) => {
    if (!data.formModified) {
      toast("No changes detected", { icon: "🔍" });
    } else {
      let payload = {};
      Object.keys(data.modifiedFields).forEach((key) => {
        if (data.modifiedFields[key] !== customer[key]) {
          payload[key] = data[key];
        }
      });
      UpdateACustomerByID(customer.customerId, payload)
        .then((res) => {
          toast.success("Customer updated successfully");
          closeModal();
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
    //
  };

  const renderModal = () => {
    return (
      <Modal
        open={modal}
        onClose={() => closeModal()}
        onCancel={() => closeModal()}
        footer={[]}
        title={`Customer ${customer.customerNo} | Edit General Information`}
        centered
        destroyOnClose
        width={850}
      >
        <FormController
          onSubmit={submitUpdate}
          fields={customerBasicInfo}
          values={customer}
          buttonText={"Update"}
          fullWidth={true}
          back={true}
          backFunction={() => closeModal()}
          backText="Cancel"
        />
      </Modal>
    );
  };

  const closeModal = () => {
    setModal(false);
    triggerReload();
  };

  return (
    <div className="flex flex-col items-start justify-start w-full gap-2 p-6 mt-4 font-sans bg-white rounded-md shadow-md shadow-gray-200">
      <div className="flex flex-row items-center justify-between w-full">
        <p className="text-xl font-bold text-black">General Information</p>
        <Button onClick={() => setModal(true)}>Edit</Button>
      </div>
      <div className="flex flex-row items-center justify-between w-full mt-4">
        <p className="font-semibold text-slate-800">Account Name</p>
        <p>{customer.name}</p>
      </div>
      <div className="flex flex-row items-center justify-between w-full">
        <p className="font-semibold text-slate-800">Customer Category</p>
        <p>{customer.category.length > 0 ? customer.category : "Category not set"}</p>
      </div>
      <div className="flex flex-row items-center justify-between w-full">
        <p className="font-semibold text-slate-800">Customer Type</p>
        <p>{customer.customerType}</p>
      </div>
      <div className="flex flex-row items-center justify-between w-full">
        <p className="font-semibold text-slate-800">Company Account</p>
        <p>{customer.company ? "Yes" : "No"}</p>
      </div>
      <div className="flex flex-row items-center justify-between w-full">
        <p className="font-semibold text-slate-800">Payment Terms</p>
        <p>{customer.terms}</p>
      </div>
      <div className="flex flex-row items-center justify-between w-full">
        <p className="font-semibold text-slate-800">Purchase Order</p>
        <p>{customer.purchaseOrder.length > 0 ? customer.purchaseOrder : "Preference not set"}</p>
      </div>
      <div className="flex flex-row items-center justify-between w-full">
        <p className="font-semibold text-slate-800">Statement Settings</p>
        <p>{customer.statementType.length > 0 ? customer.statementType : "Preference not set"}</p>
      </div>
      {renderModal()}
    </div>
  );
};

export default CustomerGeneralInfo;
