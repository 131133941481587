import { Helmet } from "react-helmet-async";

const Dashboard = ({ authState }) => {
  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <Helmet>
        <title>Dashboard | Wrightsboro Supply ERP</title>
      </Helmet>
      <h1 className="text-lg font-bold">Dashboard.</h1>
    </div>
  );
};

export default Dashboard;
