import { useState, useEffect } from "react";
import { GetAllProductCategories, GetAllProducts } from "../../actions/products";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Button, Table } from "antd";

const Products = ({ authState }) => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [productCategories, setProductCategories] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    GetAllProducts()
      .then((res) => {
        setProducts(res.data);
        GetAllProductCategories()
          .then((res) => {
            setProductCategories(res.data);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error(err.response.data ? err.response.data.message : "An error occurred while fetching product categories. Please try again.");
          });
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error occurred while fetching products. Please try again.");
      });
    // eslint-disable-next-line
  }, []);

  const findCategory = (cid) => {
    const category = productCategories.find((cat) => cat.categoryId === cid);
    return category ? category.name + " | " + category.description : "N/A";
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "categoryId",
      key: "categoryId",
      render: (cid) => findCategory(cid),
    },
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
    },
    {
      title: "Product #",
      dataIndex: "productNumber",
      key: "productNumber",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "40%",
    },
    {
      title: "",
      dataIndex: "productId",
      key: "productId",
      render: (pid) => (
        <div className="flex items-center justify-center w-full">
          <Button onClick={() => navigate(`/products/${pid}`)}>View</Button>
        </div>
      ),
    },
  ];

  return !loading ? (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <div className="flex items-center justify-between w-full px-5 pb-5 mb-5 border-b border-gray-200">
        <h1 className="text-lg font-bold">Products.</h1>
        <Button>Add a new Product</Button>
      </div>
      <Table dataSource={products} columns={columns} className="w-full px-5" loading={loading} bordered sticky />
    </div>
  ) : (
    <div className="flex items-center justify-center w-full h-screen overflow-x-hidden bg-slate-50">
      <p className="text-2xl font-bold animate-pulse text-wbs-gray">Loading...</p>
    </div>
  );
};

export default Products;
