import { useState, useEffect } from "react";
import { Input } from "antd";
import dayjs from "dayjs";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import toast from "react-hot-toast";
import { SignIn } from "../../actions/auth";

const Login = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      if (authState.isAuth) {
        navigate("/");
      }
    }
    return () => {
      inView = false;
    };
  }, []);

  const handleLogin = () => {
    if (!validator.isEmail(email)) {
      toast.error("Please enter a valid email address");
      return;
    }
    if (password === "" || password.length < 8) {
      toast.error("Please enter a valid password");
      return;
    }
    if (!loading) {
      setLoading(true);
      SignIn({ email, password })
        .then((res) => {
          let payload = {
            token: res.data.token,
            email: email,
            user: res.data.user,
          };
          localStorage.setItem("auth-token", res.data.token);
          authDispatch({
            type: "valid-login",
            payload: payload,
          });
          toast.success("Login successful");
          navigate("/", { replace: true });
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response.data ? err.response.data.response : "An error occurred while attempting to log you in");
        });
    }
  };

  return (
    <div className="flex items-center justify-center w-full h-full min-h-screen">
      <Helmet>
        <title>Login | Wrightsboro Supply ERP</title>
      </Helmet>
      <div className="absolute top-0 bottom-0 left-0 w-full h-full overflow-hidden leading-5 bg-wbs-gray bg-gradient-to-b from-zinc-950 via-wbs-gray to-zinc-950" />
      <div className="relative flex flex-row items-center justify-center w-full min-h-screen bg-transparent rounded-3xl lg:px-14 xl:justify-between xl:max-w-7xl">
        <div className="z-10 flex flex-col self-center sm:max-w-4xl xl:max-w-md">
          <div className="flex-col self-start hidden text-white lg:flex">
            <h1 className="my-3 text-4xl font-semibold">Welcome back</h1>
            <p className="pr-3 text-sm opacity-75">
              Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups
            </p>
          </div>
        </div>
        <div className="z-10 flex self-center justify-center ">
          <div className="p-12 mx-auto bg-white rounded-xl shadow-xl w-96 xl:w-[450px]">
            <div className="mb-7">
              <h3 className="text-3xl font-extrabold text-gray-800">Login </h3>
              <p className="text-sm text-gray-400">Access your WBS ERP account</p>
            </div>
            <div className="flex flex-col items-start justify-start">
              <div className="w-full">
                <Input
                  placeholder="Email"
                  type="email"
                  size="large"
                  className="w-full px-4 text-sm"
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={loading}
                  onKeyDown={(e) => e.key === "Enter" && handleLogin()}
                />
              </div>

              <div className="w-full mt-4">
                <Input.Password
                  placeholder="Password"
                  type="password"
                  size="large"
                  className="w-full px-4 text-sm"
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={loading}
                  onKeyDown={(e) => e.key === "Enter" && handleLogin()}
                />
              </div>

              <div className="flex items-center justify-end w-full mt-1">
                <div className="ml-auto text-xs font-medium">
                  <a href="/reset-password" className="text-wbs-red hover:text-wbs-red/70">
                    Forgot your password?
                  </a>
                </div>
              </div>
              <div className="w-full mt-8">
                <button
                  onClick={() => handleLogin()}
                  className="flex justify-center w-full p-3 font-semibold tracking-wide text-gray-100 transition duration-300 ease-in-out rounded-lg cursor-pointer bg-wbs-red hover:bg-wbs-red/60"
                >
                  Sign in
                </button>
              </div>
            </div>
            <div className="mt-5 text-xs text-center text-gray-400">
              <span>
                Copyright © 1977-{dayjs().format("YYYY")}{" "}
                <a href="https://wrightsborosupply.com" rel="noreferrer" target="_blank" className="text-wbs-red hover:text-wbs-red/70">
                  Wrightsboro Supply
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
