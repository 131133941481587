import { Button, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatPhoneNumber } from "../../../../data/utils";
import { states } from "../../../../data/states";
import toast from "react-hot-toast";

const CustomerDefaultAddress = ({ customer }) => {
  const [defaultAddress, setDefaultAddress] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (customer) {
      const primaryContact = customer.addresses.find((contact) => contact.primary);
      if (primaryContact) {
        setDefaultAddress(primaryContact);
      } else {
        if (customer.addresses.length > 0) {
          setDefaultAddress(customer.addresses[0]);
        } else {
          setDefaultAddress(null);
        }
      }
      setTimeout(() => setLoading(false), 700);
    }
  }, []);

  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-start justify-start w-full gap-2 p-6 mt-4 bg-white rounded-md shadow-md shadow-gray-200">
      <div className="flex flex-row items-center justify-between w-full">
        <p className="text-xl font-bold text-black">Default Address</p>
        {/* <Button onClick={() => navigate(`/customers/${customer.customerId}/addresses`)}>Edit</Button> */}
        <Button onClick={() => toast("This feature is not yet available, check again soon!")}>Edit</Button>
      </div>
      {loading ? (
        <div className="flex flex-row items-center justify-center w-full h-full gap-2">
          <Skeleton active />
        </div>
      ) : defaultAddress ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <div className="flex flex-row items-center justify-between w-full">
            <p className="font-semibold text-slate-800">Address Name / Label</p>
            <p>{defaultAddress.name ?? "Not Provided"}</p>
          </div>
          <div className="flex flex-row items-center justify-between w-full">
            <p className="font-semibold text-slate-800">Address</p>
            <p>
              {defaultAddress.address1}
              {defaultAddress.address2 && defaultAddress.address2.length > 0 ? `, ${defaultAddress.address2}` : ""}
            </p>
          </div>
          <div className="flex flex-row items-center justify-between w-full">
            <p className="font-semibold text-slate-800">City</p>
            <p>{defaultAddress.city ?? "Not Provided"}</p>
          </div>
          <div className="flex flex-row items-center justify-between w-full">
            <p className="font-semibold text-slate-800">State</p>
            <p>{(defaultAddress.state ?? "Not Provided") ? states.find((s) => s.value === defaultAddress.state)?.label : defaultAddress.state}</p>
          </div>
          <div className="flex flex-row items-center justify-between w-full">
            <p className="font-semibold text-slate-800">ZIP Code</p>
            <p>{defaultAddress.zip ?? "Not Provided"}</p>
          </div>
          <div className="flex flex-row items-center justify-between w-full">
            <p className="font-semibold text-slate-800">Primary Address</p>
            <p>{defaultAddress.primary ? "Primary" : "Alternative"}</p>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-sm font-semibold text-center text-gray-500 uppercase">No contact information found</p>
        </div>
      )}
    </div>
  );
};

export default CustomerDefaultAddress;
