import axiosInstance from "./axios";
import qs from "qs";

export const CreateAVendor = (data) => {
  return axiosInstance.post("/vendor/create", data);
};

export const GetVendorByID = (id) => {
  return axiosInstance.get(`/vendor/${id}`);
};

export const QueryVendors = (data) => {
  let queryParam = {};
  if (data.search && data.search.length > 0) {
    queryParam.search = data.search;
  }
  if (data.page && !isNaN(data.page)) {
    queryParam.page = data.page;
  }
  if (data.limit && !isNaN(data.limit)) {
    queryParam.limit = data.limit;
  }
  const queryString = qs.stringify(queryParam);
  return axiosInstance.get(`/vendor/query?${queryString}`);
};
