const unitsOfMeasure = [
  {
    value: "ea",
    label: "Each (ea)",
  },
  {
    value: "qt",
    label: "Quart (qt)",
  },
  {
    value: "gal",
    label: "Gallon (gal)",
  },
  {
    value: "kit",
    label: "Kit",
  },
  {
    value: "set",
    label: "Set",
  },
  {
    value: "lb",
    label: "Pound (lb)",
  },
  {
    value: "pr",
    label: "Pair (pr)",
  },
  {
    value: "tube",
    label: "Tube",
  },
  {
    value: "can",
    label: "Can",
  },
  {
    value: "ft",
    label: "Foot (ft)",
  },
  {
    value: "m",
    label: "Meter (m)",
  },
  {
    value: "l",
    label: "Liter (l)",
  },
  {
    value: "g",
    label: "Gram (g)",
  },
  {
    value: "kg",
    label: "Kilogram (kg)",
  },
];

export default unitsOfMeasure;
