export const customerBasicInfo = {
  name: {
    fieldName: "name",
    label: "Name",
    inputType: "text",
    placeholder: "Enter a name for the customer",
    defaultValue: "",
    config: {
      required: "Name is required",
      validate: (value) => value.length >= 2 || "Name is required and must be at least 2 characters long",
    },
  },
  category: {
    fieldName: "category",
    label: "Customer Category",
    inputType: "select",
    placeholder: "Select the category that best fits this customer",
    defaultValue: "",
    options: [
      { label: "Contractor", value: "Contractor" },
      { label: "Builder", value: "Builder" },
      { label: "Retail", value: "Retail" },
      { label: "Wholesale", value: "Wholesale" },
      { label: "Government", value: "Government" },
      { label: "Non-Profit", value: "Non-Profit" },
      { label: "Educational", value: "Educational" },
      { label: "Medical", value: "Medical" },
      { label: "Industrial", value: "Industrial" },
      { label: "Commercial", value: "Commercial" },
      { label: "Residential", value: "Residential" },
      { label: "Religious", value: "Religious" },
      { label: "Individual", value: "Individual" },
      { label: "Agricultural", value: "Agricultural" },
      { label: "Other", value: "Other" },
      { label: "Unknown", value: "" },
    ],
    config: {
      required: false,
    },
  },
  customerType: {
    fieldName: "customerType",
    label: "Customer Type",
    inputType: "select",
    placeholder: "Select the customer type",
    defaultValue: "Cash Customer",
    options: [
      { label: "Cash Customer", value: "Cash Customer" },
      { label: "Credit Customer", value: "Credit Customer" },
      { label: "COD Customer", value: "COD Customer" },
      { label: "Other", value: "Other" },
      { label: "Unknown", value: "" },
    ],
    config: {
      required: false,
    },
  },
  company: {
    fieldName: "company",
    label: "Is this a Commercial / Company account",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  terms: {
    fieldName: "terms",
    label: "Payment Terms",
    inputType: "select",
    placeholder: "Select the default payment terms for this customer",
    defaultValue: "NET 30",
    options: [
      { label: "No Credit Hold", value: "No Credit Hold" },
      { label: "On Hold", value: "On Hold" },
      { label: "Overridden", value: "Overridden" },
    ],
    config: {
      required: false,
    },
  },
  purchaseOrder: {
    fieldName: "purchaseOrder",
    label: "Purchase Order",
    inputType: "select",
    placeholder: "Select Purchase Order Requirements",
    defaultValue: "",
    options: [
      { label: "Not Required", value: "Not Required" },
      { label: "Optional", value: "Optional" },
      { label: "Required", value: "Required" },
      { label: "N/A", value: "N/A" },
    ],
    config: {
      required: false,
    },
  },
  statementType: {
    fieldName: "statementType",
    label: "Default Statement Type",
    inputType: "select",
    placeholder: "Select the default statement type for this customer",
    defaultValue: "Print only invoices",
    options: [
      { label: "Print only statements", value: "Print only statements" },
      { label: "Print only invoices", value: "Print only invoices" },
      { label: "Print both statements and invoices", value: "Print both statements and invoices" },
    ],
    config: {
      required: false,
    },
  },
};

export const customerCreditInfo = {
  creditLimit: {},
  creditHold: {},
  defaultPriceTies: {},
};
