import { Button, Table } from "antd";
import { useEffect, useState } from "react";
import { GetAllInvoices } from "../../actions/invoice";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { formatCurrency } from "../../data/utils";

const Invoices = ({ authState }) => {
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetAllInvoices()
        .then((res) => {
          setInvoices(res.data);
          setTimeout(() => {
            setLoading(false);
          }, 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.response : "An error occurred");
          navigate("/");
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <div className="flex-grow flow-root w-full px-2">
        <div className="inline-block w-full min-w-full py-2 align-middle">
          {loading ? (
            <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">Loading...</p>
          ) : (
            <div className="flex flex-col items-center justify-start w-full h-full">
              <div className="flex flex-row items-center justify-between w-full pb-4 border-b border-gray-200">
                <div className="flex flex-row items-center justify-start gap-2">
                  <div className="flex flex-col items-start justify-center mr-4">
                    <p className="text-xl font-semibold">Invoices</p>
                    <p className="text-sm text-gray-500 font-base">A comprehensive overview of all customer invoices</p>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-end gap-2">
                  <Button onClick={() => navigate("/operations/invoices/new")}>Create a new Invoice</Button>
                </div>
              </div>
              <Table dataSource={invoices} loading={loading} rowKey="invoiceId" className="w-full" sticky={true}>
                <Table.Column title="Invoice No." dataIndex="invoiceNo" key="invoiceNo" />
                <Table.Column title="Status" dataIndex="invoiceStatus" key="invoiceStatus" />
                <Table.Column title="Customer" dataIndex="customerLabel" key="customerLabel" />
                <Table.Column
                  title="Invoice Dates"
                  dataIndex="dateInvoiced"
                  key="dateInvoiced"
                  render={(_v, r) => (
                    <div className="flex flex-col items-start justify-center gap-0">
                      <p className="text-xs font-medium uppercase">Invoiced: {dayjs(r.dateInvoiced).format("MM/DD/YYYY")}</p>
                      <p className="text-xs font-medium uppercase">Due Date: {dayjs(r.dueDate).format("MM/DD/YYYY")}</p>
                    </div>
                  )}
                />
                <Table.Column title="Amount Due" dataIndex="amountDue" key="amountDue" render={(v) => formatCurrency(v)} align="right" />
                <Table.Column
                  title=""
                  dataIndex="invoiceId"
                  key="invoiceId"
                  align="right"
                  render={(v) => <Button onClick={() => navigate(`/operations/invoices/${v}`)}>Open</Button>}
                />
              </Table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Invoices;
