import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Button, Input, InputNumber, Modal, Table } from "antd";
import dayjs from "dayjs";
import { GetTransactionById, IssueRefund } from "../../actions/payment";
import { formatCurrency } from "../../data/utils";
import { GenerateTransactionReceipt } from "../../data/pdf";

const OpenTransaction = () => {
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refundData, setRefundData] = useState({ reason: "", amount: 0 });
  const [refundModal, setRefundModal] = useState(false);

  const { transactionId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    reloadData();
  }, [transactionId]);

  const reloadData = () => {
    setLoading(true);
    GetTransactionById(transactionId)
      .then((res) => {
        setTransaction(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 700);
      })
      .catch((err) => {
        toast.error(err.message);
        navigate("/transactions");
      });
  };

  const paymentStatus = {
    requires_payment_method: "Requires Payment Method",
    requires_confirmation: "Requires Confirmation",
    requires_action: "Requires Action",
    processing: "Processing...",
    succeeded: "Succeeded",
    failed: "Failed",
    canceled: "Cancelled",
    requires_capture: "Requires Capture",
    unknown: "Unknown",
  };

  const columns = [
    {
      title: "Product No.",
      dataIndex: "productNumber",
      key: "productNumber",
    },
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "40%",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Price",
      dataIndex: "total",
      key: "total",
      render: (total) => formatCurrency(total),
    },
    {
      title: "",
      dataIndex: "productId",
      key: "productId",
      render: (pid) => (
        <div className="flex items-center justify-center w-full">
          <Button onClick={() => window.open(`/products/${pid}`, "_blank")}>View</Button>
        </div>
      ),
    },
  ];

  const closeRefundModal = () => {
    setRefundModal(false);
    setRefundData({ reason: "", amount: 0 });
  };

  const issueRefund = () => {
    if (refundData.amount <= 0) {
      toast.error("Please enter a valid refund amount.");
      return;
    }
    if (refundData.amount > transaction.total) {
      toast.error("Refund amount cannot be greater than the total amount.");
      return;
    }
    if (refundData.reason.length <= 10) {
      toast.error("Please provide a valid and descriptive reason for the refund.");
    }
    setLoading(true);
    IssueRefund(transactionId, refundData)
      .then(() => {
        toast.success("Refund issued successfully!");
        closeRefundModal();
        reloadData();
      })
      .catch((err) => {
        toast.error(err.message);
        closeRefundModal();
      });
  };

  const calculateTotalRefunds = () => {
    let totalRefunds = 0;
    transaction.refunds.forEach((refund) => {
      totalRefunds += refund.refundAmount;
    });
    return totalRefunds;
  };

  const printReceipt = () => {
    try {
      let doc = GenerateTransactionReceipt(transaction);
      doc.setProperties({
        title: `Transaction Receipt - ${transactionId.replace("transaction_", "").toUpperCase()}`,
        subject: `Transaction Receipt - ${transactionId.replace("transaction_", "").toUpperCase()}`,
        author: "Hypertek Solutions LLC",
        keywords: "",
        creator: "contact@hypertek.dev",
      });
      window.open(doc.output("bloburl"), "_blank");
    } catch (err) {
      toast.error("An error occurred while generating the receipt.");
      console.error(err);
    }
  };

  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <div className="flex-grow flow-root w-full px-2">
        <div className="inline-block w-full min-w-full py-2 align-middle">
          {loading ? (
            <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">Loading...</p>
          ) : (
            <div className="flex flex-col items-center justify-start w-full h-full">
              <div className="flex flex-row items-center justify-between w-full pb-4 border-b border-gray-200">
                <div className="flex flex-row items-center justify-start gap-2">
                  <div className="flex flex-col items-start justify-center mr-4">
                    <p className="text-xl font-semibold">Transaction - {transactionId.replace("transaction_", "").toUpperCase()}</p>
                    <p className="text-sm font-semibold text-gray-500">
                      Processed by {transaction.processedBy} on {dayjs(transaction.date).format("MM/DD/YYYY [at] hh:m A")}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-end gap-2">
                  <Button onClick={() => setRefundModal(true)} danger>
                    Refund
                  </Button>
                  <Button onClick={() => printReceipt()} type="primary">
                    Receipt
                  </Button>
                </div>
              </div>
              <div className="grid w-full grid-cols-1 gap-4 mt-4">
                <div className="flex flex-col items-start justify-start w-full gap-2 p-6 mt-4 bg-white rounded-md shadow-md shadow-gray-200">
                  <p className="text-xl font-semibold">Transaction Information</p>
                  <div className="flex flex-row items-center justify-between w-full gap-2 pt-3">
                    <div className="flex flex-col items-center justify-center w-1/2 h-full">
                      <div className="flex flex-row items-center justify-start w-full gap-4">
                        <p className="font-semibold text-slate-800">Customer:</p>
                        <p>{transaction.customerName ? transaction.customerName : "Unknown"}</p>
                        {transaction.customerId && transaction.customerId.length > 0 && (
                          <Button onClick={() => window.open(`/customers/${transaction.customerId}`, "_blank")} size="small" style={{ marginTop: 2 }}>
                            View
                          </Button>
                        )}
                      </div>
                      <div className="flex flex-row items-center justify-start w-full gap-4">
                        <p className="font-semibold text-slate-800">Invoice:</p>
                        <p>{transaction.invoiceNo ? transaction.invoiceNo : "No invoice attached"}</p>
                        {transaction.invoiceId && transaction.invoiceId.length > 0 && (
                          <Button onClick={() => window.open(`/invoices/${transaction.invoiceId}`, "_blank")} size="small" style={{ marginTop: 2 }}>
                            View
                          </Button>
                        )}
                      </div>
                      <div className="flex flex-row items-center justify-start w-full gap-4">
                        <p className="font-semibold text-slate-800">Processed By:</p>
                        <p>{transaction.processedBy}</p>
                      </div>
                      <div className="flex flex-row items-center justify-start w-full gap-4">
                        <p className="font-semibold text-slate-800">Processed At:</p>
                        <p>{dayjs(transaction.date).format("MM/DD/YYYY [at] hh:m A")}</p>
                      </div>
                      <div className="flex flex-row items-center justify-start w-full gap-4">
                        <p className="font-semibold text-slate-800">Transaction Status:</p>
                        <p>{paymentStatus[transaction.paymentStatus]}</p>
                      </div>
                      <div className="flex flex-row items-center justify-start w-full gap-4">
                        <p className="font-semibold text-slate-800">Paid With:</p>
                        <p>
                          {transaction.cardBrand?.toUpperCase()} ending in {transaction?.cardLast4} - EXP{" "}
                          {transaction?.cardExpMonth < 10 ? `0${transaction?.cardExpMonth}` : transaction?.cardExpMonth}/{transaction?.cardExpYear}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-center w-1/2 h-full">
                      <div className="flex flex-row items-center justify-start w-full gap-4">
                        <p className="font-semibold text-slate-800">Sale Amount:</p>
                        <p>{formatCurrency(transaction.saleAmount)}</p>
                      </div>
                      <div className="flex flex-row items-center justify-start w-full gap-4">
                        <p className="font-semibold text-slate-800">Sales Tax:</p>
                        <p>{formatCurrency(transaction.salesTax)}</p>
                      </div>
                      <div className="flex flex-row items-center justify-start w-full gap-4">
                        <p className="font-semibold text-slate-800">Processing Fees:</p>
                        <p>{formatCurrency(transaction.processingFees)}</p>
                      </div>
                      <div className="flex flex-row items-center justify-start w-full gap-4">
                        <p className="font-semibold text-slate-800">Total:</p>
                        <p>{formatCurrency(transaction.total)}</p>
                      </div>
                      {transaction.refunds && transaction.refunds.length > 0 && (
                        <div className="flex flex-row items-center justify-start w-full gap-4">
                          <p className="font-semibold text-slate-800">Refunded Amount:</p>
                          <p>{formatCurrency(calculateTotalRefunds())}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start w-full gap-2 p-6 mt-4 bg-white rounded-md shadow-md shadow-gray-200">
                  <p className="text-xl font-semibold">Items</p>
                  <Table dataSource={transaction.items} columns={columns} className="w-full pt-3" loading={loading} sticky size="small" pagination={false} />
                </div>
                {transaction.refunds && transaction.refunds.length > 0 && (
                  <div className="flex flex-col items-start justify-start w-full gap-2 p-6 mt-4 bg-white rounded-md shadow-md shadow-gray-200">
                    <p className="text-xl font-semibold">Refunds</p>
                    <div className="grid items-center content-center justify-between w-full grid-cols-3 pt-3 pb-2 md:grid-cols-6">
                      <p className="text-sm font-semibold text-gray-800 uppercase">Refund Date</p>
                      <p className="hidden col-span-3 text-sm font-semibold text-gray-800 uppercase md:inline-flex">Reason</p>
                      <p className="hidden text-sm font-semibold text-gray-800 uppercase md:inline-flex">Amount</p>
                      <p className="hidden text-sm font-semibold text-gray-800 uppercase md:inline-flex">Issued By</p>
                    </div>
                    {transaction.refunds.map((refund, index) => (
                      <li className="grid items-center content-center justify-between w-full grid-cols-3 md:grid-cols-6" key={`${index}${refund.refundId}`}>
                        <p className="text-xs text-gray-500 uppercase font-base">{dayjs(refund.refundDate).format("MM/DD/YYYY [at] hh:m A")}</p>
                        <p className="hidden col-span-3 text-xs text-gray-500 uppercase font-base md:inline-flex">{refund.refundReason}</p>
                        <p className="hidden text-xs text-gray-500 uppercase font-base md:inline-flex">{formatCurrency(refund.refundAmount)}</p>
                        <p className="text-xs text-gray-500 uppercase font-base">{refund.refundedBy}</p>
                      </li>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        open={refundModal}
        title="Issue a Refund"
        onCancel={() => closeRefundModal()}
        onOk={() => issueRefund()}
        okText="Issue Refund"
        cancelText="Cancel"
        destroyOnClose
        centered
        width={650}
      >
        <div className="flex flex-col items-start justify-start w-full pt-3">
          <label className="pb-2 text-xs text-gray-600 uppercase">Refund Amount</label>
          <InputNumber
            placeholder="Refund Amount"
            name="refundAmount"
            onChange={(v) => setRefundData({ ...refundData, amount: v })}
            className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
            controls={false}
          />
        </div>
        <div className="flex flex-col items-start justify-start w-full pt-3">
          <label className="pb-2 text-xs text-gray-600 uppercase">Refund Reason</label>
          <Input.TextArea
            placeholder="Reason for issuing a refund"
            name="refundReason"
            onChange={(e) => {
              setRefundData({ ...refundData, reason: e.target.value });
            }}
            rows={4}
            autoSize={false}
            className="w-full !resize-none font-sans py-2 px-4 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
          />
        </div>
      </Modal>
    </div>
  );
};

export default OpenTransaction;
