export const newVendorForm = {
  name: {
    fieldName: "name",
    label: "Vendor / Account name",
    inputType: "text",
    placeholder: "Enter a name for the vendor",
    defaultValue: "",
    config: {
      required: "Vendor name is required",
      validate: (value) => value.length >= 2 || "Vendor name is required and must be at least 2 characters long",
    },
  },
  code: {
    fieldName: "code",
    label: "Vendor Code",
    inputType: "text",
    placeholder: "Enter a vendor code",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  divider1: {
    inputType: "divider",
  },
  address: {
    fieldName: "address",
    label: "Address",
    inputType: "address",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  divider2: {
    inputType: "divider",
  },
  contactName: {
    fieldName: "contact",
    label: "Contact Information",
    inputType: "contact",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  divider3: {
    inputType: "divider",
  },
  status: {
    fieldName: "status",
    label: "Vendor Status",
    inputType: "select",
    placeholder: "Select the status of this vendor",
    defaultValue: "Active",
    options: [
      { label: "Active", value: "Active" },
      { label: "Inactive", value: "Inactive" },
      { label: "Pending", value: "Pending" },
      { label: "Exploring", value: "Exploring" },
      { label: "Retired", value: "Retired" },
      { label: "Other", value: "" },
    ],
    config: {
      required: false,
    },
  },
  divider4: {
    inputType: "divider",
  },
  terms: {
    fieldName: "terms",
    label: "Payment Terms",
    inputType: "select",
    placeholder: "Select the default payment terms for this customer",
    defaultValue: "NET 30",
    options: [
      { label: "NET 10", value: "NET 10" },
      { label: "NET 15", value: "NET 15" },
      { label: "NET 30", value: "NET 30" },
      { label: "NET 45", value: "NET 45" },
      { label: "NET 60", value: "NET 60" },
      { label: "NET 90", value: "NET 90" },
      { label: "Collect on Delivery", value: "COD" },
    ],
    config: {
      required: false,
    },
  },
  divider5: {
    inputType: "divider",
  },
};
