import { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "../../data/utils";
import dayjs from "dayjs";
import { QueryTransactions } from "../../actions/payment";
import toast from "react-hot-toast";

const Transactions = ({ authState }) => {
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      QueryTransactions({})
        .then((res) => {
          setTransactions(res.data.results);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.response : "An error occurred");
          navigate("/");
        });
    }
    return () => {
      inView = false;
    };
    // eslint-disable-next-line
  }, []);

  const paymentStatus = {
    requires_payment_method: "Requires Payment Method",
    requires_confirmation: "Requires Confirmation",
    requires_action: "Requires Action",
    processing: "Processing...",
    succeeded: "Succeeded",
    failed: "Failed",
    canceled: "Cancelled",
    requires_capture: "Requires Capture",
    unknown: "Unknown",
    pending: "Pending",
  };

  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <div className="flex-grow flow-root w-full px-2">
        <div className="inline-block w-full min-w-full py-2 align-middle">
          {loading ? (
            <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">Loading...</p>
          ) : (
            <div className="flex flex-col items-center justify-start w-full h-full">
              <div className="flex flex-row items-center justify-between w-full pb-4 border-b border-gray-200">
                <div className="flex flex-row items-center justify-start gap-2">
                  <div className="flex flex-col items-start justify-center mr-4">
                    <p className="text-xl font-semibold">Transactions</p>
                    <p className="text-sm text-gray-500 font-base">A comprehensive overview of all processed transactions</p>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-end gap-2">
                  <Button onClick={() => navigate("/sale")}>New Transaction</Button>
                </div>
              </div>
              <Table dataSource={transactions} loading={loading} rowKey="transactionId" className="w-full" sticky={true}>
                <Table.Column title="Customer" dataIndex="customerName" key="customerName" />
                <Table.Column title="Status" dataIndex="paymentStatus" key="paymentStatus" render={(v) => paymentStatus[v]} />
                <Table.Column title="Date" dataIndex="date" key="date" render={(v) => dayjs(v).format("MM/DD/YYYY")} />
                <Table.Column title="Amount" dataIndex="total" key="total" render={(v) => formatCurrency(v)} align="right" width="160px" />
                <Table.Column
                  title=""
                  dataIndex="transactionId"
                  key="transactionId"
                  align="right"
                  width="100px"
                  render={(v) => <Button onClick={() => navigate(`/transactions/${v}`)}>Open</Button>}
                />
              </Table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Transactions;
