import { useState, useEffect } from "react";
import { Button, Skeleton } from "antd";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { GetInvoicesByCustomer } from "../../../../actions/invoice";

const CustomerInvoiceHistory = ({ customer }) => {
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!customer) return;
    GetInvoicesByCustomer(customer.customerId)
      .then((res) => {
        setInvoices(res.data);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error("Failed to retrieve invoice history");
      });
  }, []);

  return (
    <div className="flex flex-col items-start justify-start w-full row-span-2 gap-2 p-6 mt-4 bg-white rounded-md shadow-md shadow-gray-200">
      <div className="flex flex-row items-center justify-between w-full">
        <p className="text-xl font-bold text-black">Invoice History</p>
        {/* <Button onClick={() => navigate(`/customers/${customer.customerId}/history`)}>More</Button> */}
        <Button onClick={() => toast("This feature is not yet available, check again soon!")}>More</Button>
      </div>
      {loading ? (
        <div className="flex flex-row items-center justify-center w-full h-full gap-2">
          <Skeleton active paragraph={{ rows: 8 }} />
        </div>
      ) : invoices.length > 0 ? (
        <div className="flex flex-row items-center justify-center w-full h-full gap-2">
          <p className="text-sm font-semibold text-center text-gray-500 uppercase">Invoices found</p>
        </div>
      ) : (
        <div className="flex flex-row items-center justify-center w-full h-full gap-2">
          <p className="text-sm font-semibold text-center text-gray-500 uppercase">No invoices found</p>
        </div>
      )}
    </div>
  );
};

export default CustomerInvoiceHistory;
