import { useState } from "react";
import { Layout, Menu, Popover } from "antd";
import { BoxIso, EvPlug, Group, Home, LogOut, Settings, TerminalTag, Wrench, ShieldUpload, HandCard } from "iconoir-react";
import { Outlet, useNavigate } from "react-router-dom";

const { Header, Content, Sider } = Layout;

const Navbar = ({ authState, authDispatch }) => {
  const [collapsed, setCollapsed] = useState(true);

  const navigate = useNavigate();

  const logout = () => {
    authState.authDispatch({ type: "logged-out" });
    navigate("/");
  };

  const items = [
    { key: "/", icon: <Home style={{ marginTop: collapsed ? "8px" : "0px" }} className="w-5" />, children: null, label: "Dashboard" },
    { key: "/customers", icon: <Group style={{ marginTop: collapsed ? "8px" : "0px" }} className="w-5" />, children: null, label: "Customers" },
    {
      key: "/productsAll",
      icon: <BoxIso style={{ marginTop: collapsed ? "8px" : "0px" }} className="w-5" />,
      children: [
        { key: "/sale", icon: null, children: null, label: "Point of Sale" },
        { key: "/products", icon: null, children: null, label: "Inventory" },
        { key: "/products/categories", icon: null, children: null, label: "Categories" },
      ],
      label: "Products",
    },
    {
      key: "/transactions",
      icon: <HandCard style={{ marginTop: collapsed ? "8px" : "0px" }} className="w-5" />,
      children: null,
      label: "Transactions",
    },
    // TODO: Implement these routes
    {
      key: "/operations",
      icon: <Wrench style={{ marginTop: collapsed ? "8px" : "0px" }} className="w-5" />,
      children: [
        { key: "/operations/invoices", icon: null, children: null, label: "Invoices" },
        { key: "/operations/vendors", icon: null, children: null, label: "Vendors" },
        // { key: "/operations/quotes", icon: null, children: null, label: "Quotes" },
        // { key: "/operations/purchase-orders", icon: null, children: null, label: "Purchase Orders" },
        // { key: "/operations/accounts-receivable", icon: null, children: null, label: "Accounts Receivable" },
        // { key: "/operations/accounts-payable", icon: null, children: null, label: "Accounts Payable" },
      ],
      label: "Operations",
    },
    // {
    //   key: "/management",
    //   icon: <ShieldUpload style={{ marginTop: collapsed ? "8px" : "0px" }} className="w-5" />,
    //   children: [
    //     { key: "/management/reports", icon: null, children: null, label: "Reports" },
    //     { key: "/management/day-end-operations", icon: null, children: null, label: "Day-End" },
    //     { key: "/management/statements", icon: null, children: null, label: "Statements" },
    //     { key: "/management/credit", icon: null, children: null, label: "Credit" },
    //     { key: "/management/users", icon: null, children: null, label: "Users" },
    //   ],
    //   label: "Management",
    // },
    // { key: "/settings", icon: <Settings style={{ marginTop: collapsed ? "8px" : "0px" }} className="w-5" />, children: null, label: "Settings" },
  ];

  const menuSelect = (e) => {
    navigate(e.key);
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} style={{ background: "#242832" }} width={230}>
        <div
          className={`flex items-center ${collapsed ? "justify-center" : "justify-start"} w-full gap-2 ${
            collapsed ? "px-2 ml-2" : "px-6 ml-0"
          } py-4 text-white`}
        >
          <div className="flex items-center justify-center w-10 h-10 rounded-md bg-wbs-red">
            <EvPlug className="text-white" strokeWidth={2} />
          </div>
          <h1
            className={`${
              collapsed ? "scale-0 opacity-0 w-0 delay-0" : "scale-100 opacity-100 w-fit delay-150"
            } transition-all ease-in-out duration-75 text-lg font-logo font-bold `}
          >
            WBS ERP
          </h1>
        </div>
        <Menu defaultSelectedKeys={["dashboard"]} mode="inline" items={items} onClick={menuSelect} />
      </Sider>
      <Layout className="bg-gray-50">
        <Header
          style={{
            padding: "0px 15px",
            background: "white",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          className="border-b border-gray-200"
        >
          <div className="flex items-center justify-between w-full h-gull">
            <p className="font-medium pointer-events-none select-none">{/* Breadcrumb Location */}</p>
            <div className="flex items-center justify-end gap-2">
              <p className="text-xs font-normal text-gray-600">Powered by HyperERP</p>
              <Popover content="Point of Sale Terminal" placement="bottom">
                <div
                  className="flex items-center justify-center w-8 h-8 duration-300 ease-in-out rounded-full group hover:bg-slate-100"
                  onClick={() => navigate("/sale")}
                >
                  <TerminalTag className="w-5 h-5 text-wbs-gray group-hover:cursor-pointer" strokeWidth={1.2} />
                </div>
              </Popover>
              {/* <Popover content="Settings" placement="bottom">
                <div className="flex items-center justify-center w-8 h-8 duration-300 ease-in-out rounded-full group hover:bg-slate-100">
                  <Settings className="w-5 h-5 text-wbs-gray group-hover:cursor-pointer" strokeWidth={1.2} />
                </div>
              </Popover> */}
              <Popover content="Logout" placement="bottom">
                <div
                  className="flex items-center justify-center w-8 h-8 duration-300 ease-in-out rounded-full group hover:bg-slate-100"
                  onClick={() => logout()}
                >
                  <LogOut className="h-5 text-wbs-gray group-hover:cursor-pointer" strokeWidth={1.2} />
                </div>
              </Popover>
            </div>
          </div>
        </Header>
        <Content
          style={{
            margin: "20px 16px",
          }}
          className="bg-gray-50"
        >
          <div className="flex flex-col items-start justify-start w-full h-full min-h-full overflow-y-auto max-w-[1440px] mx-auto">
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Navbar;
