import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { Button, Input, InputNumber, Modal, Popover, Select, Skeleton } from "antd";
import { GetAProductByID, QueryProducts } from "../../actions/products";
import { v4 as uuidv4 } from "uuid";
import { Trash, WarningTriangle } from "iconoir-react";
import { formatCurrency } from "../../data/utils";
import { CalculateTax, GetTransactionById, GetTransactionStatusById, ProcessPayment } from "../../actions/payment";
import { GetACustomerByID, QueryCustomers } from "../../actions/customer";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { GenerateTransactionReceipt } from "../../data/pdf";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const NewSale = () => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [products, setProducts] = useState([]);
  const [dummyLoading, setDummyLoading] = useState(false);
  const [tax, setTax] = useState(0);
  const [processingFee, setProcessingFee] = useState(0);
  const [total, setTotal] = useState(0);
  const [calculating, setCalculating] = useState(false);
  const [zip, setZip] = useState(30909);
  const [customer, setCustomer] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [customerZipModal, setCustomerZipModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [soldTo, setSoldTo] = useState(null);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("customerId") && searchParams.get("customerId").length > 0) {
      let customerId = searchParams.get("customerId");
      setTimeout(() => pickCustomer(customerId), 1200);
    }
    reloadData();
  }, []);

  const reloadData = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 700);
  };

  const handleSearch = (query) => {
    QueryProducts({ search: query })
      .then((res) => {
        setProducts(res.data.results);
      })
      .catch((err) => {
        toast.error("Product search failed, please try again");
        setProducts([]);
      });
  };

  const handleCustomerSearch = (query) => {
    QueryCustomers({ search: query })
      .then((res) => {
        setCustomers(res.data.results);
      })
      .catch((err) => {
        toast.error("Customer search failed, please try again");
        setCustomers([]);
      });
  };

  const pickProduct = (value) => {
    let tmp = items;
    let newItem = {
      itemId: uuidv4(),
      productId: value,
      loaded: false,
      quantity: 1,
      productNumber: null,
      item: null,
      description: null,
      price: null,
      priceTiers: null,
      selectedPriceTier: "tier3",
      chargePrice: null,
      total: null,
    };
    tmp.push(newItem);
    setItems(tmp);
    setDummyLoading(true);
    loadProduct(newItem);
    setProducts([]);
  };

  const pickCustomer = (customerId) => {
    GetACustomerByID(customerId)
      .then((res) => {
        setCustomer(res.data);
        setCustomers([res.data]);
        if (res.data.addresses && res.data.addresses.length > 0) {
          let primary = res.data.addresses.find((a) => a.primary);
          if (primary && primary.zip && primary.zip.length === 5) {
            setCustomerZipModal(true);
          }
        }
      })
      .catch((err) => {
        toast.error("Failed to load customer, please try again");
        setCustomers([]);
      });
  };

  const loadProduct = (item) => {
    GetAProductByID(item.productId)
      .then((res) => {
        let product = res.data;
        let tmp = items;
        let index = tmp.findIndex((i) => i.itemId === item.itemId);
        tmp[index].productNumber = product.productNumber;
        tmp[index].item = product.item;
        tmp[index].description = product.description;
        tmp[index].price = product.price;
        tmp[index].priceTiers = product.priceTiers;
        let price = parseFloat((parseFloat(product.price.toFixed(2)) * parseFloat(1 + product.priceTiers.tier3 / 100)).toFixed(2));
        tmp[index].chargePrice = price;
        tmp[index].total = parseFloat((price * item.quantity).toFixed(2));
        tmp[index].loaded = true;
        setItems(tmp);
        setTimeout(() => setDummyLoading(false), 500);
      })
      .catch((err) => {
        toast.error("Failed to load product, please try again");
        let tmp = items.filter((i) => i.itemId !== item.itemId);
        setItems(tmp);
        setTimeout(() => setDummyLoading(false), 500);
      });
  };

  const removeItem = (itemId) => {
    setDummyLoading(true);
    let tmp = items.filter((i) => i.itemId !== itemId);
    setItems(tmp);
    toast("Item Removed");
    setTimeout(() => setDummyLoading(false), 500);
  };

  const updateQuantity = (itemId, quantity) => {
    setDummyLoading(true);
    let tmp = items;
    let index = tmp.findIndex((i) => i.itemId === itemId);
    tmp[index].quantity = quantity;
    tmp[index].total = parseFloat((tmp[index].chargePrice * quantity).toFixed(2));
    setItems(tmp);
    setTimeout(() => setDummyLoading(false), 500);
  };

  const updatePrice = (itemId, price) => {
    setDummyLoading(true);
    let tmp = items;
    let index = tmp.findIndex((i) => i.itemId === itemId);
    tmp[index].chargePrice = price;
    tmp[index].total = parseFloat((price * tmp[index].quantity).toFixed(2));
    setItems(tmp);
    setTimeout(() => setDummyLoading(false), 500);
  };

  useEffect(() => {
    let tmpTotal = getTotal();
    if (tmpTotal > 0) {
      setCalculating(true);
      CalculateTax({ amount: tmpTotal, zip: zip }).then((res) => {
        let tmpTax = res.data.taxAmount;
        setTax(tmpTax);
        let tmpProcessingFee = calculateProcessingFee(tmpTotal + tmpTax);
        tmpProcessingFee = parseFloat(tmpProcessingFee.toFixed(2));
        setProcessingFee(tmpProcessingFee);
        let preFinTotal = tmpTotal + tmpTax + tmpProcessingFee;
        setTotal(parseFloat(preFinTotal.toFixed(2)));
        setTimeout(() => setCalculating(false), 500);
      });
    } else {
      setTax(0);
      setProcessingFee(0);
      setTotal(0);
    }
  }, [dummyLoading]);

  const calculateProcessingFee = (amount) => {
    let fee = (amount + 0.3) / (1 - 0.029) - amount;
    return parseFloat(fee.toFixed(2));
  };

  const getTotal = () => {
    let total = 0;
    items.forEach((item) => {
      total += parseFloat((item.chargePrice * item.quantity).toFixed(2));
    });
    return parseFloat(total.toFixed(2));
  };

  const cancelZipAutofill = () => {
    setCustomerZipModal(false);
    setZip(30909);
  };

  const useCustomerZip = () => {
    setDummyLoading(true);
    let primary = customer.addresses.find((a) => a.primary);
    if (primary && primary.zip && primary.zip.length === 5) {
      setZip(parseInt(primary.zip));
      setCustomerZipModal(false);
    } else {
      setZip(30909);
      toast.error("Customer does not have a valid ZIP Code");
      setCustomerZipModal(false);
    }
    setTimeout(() => setDummyLoading(false), 700);
  };

  const renderZipModal = () => {
    return (
      <Modal
        open={customerZipModal}
        onCancel={cancelZipAutofill}
        onOk={useCustomerZip}
        centered
        destroyOnClose
        title="Sales Tax ZIP Code"
        width={600}
        cancelText="Ignore"
        okText="Use Customer's ZIP Code"
      >
        <p>Do you want to use the ZIP Code from customer's address ({customer.addresses.find((a) => a.primary)?.zip}) for sales tax calculation?</p>
      </Modal>
    );
  };

  const updateZip = (zipVal) => {
    if (zipVal.length !== 5) {
      toast.error("ZIP Code must be 5 digits");
    } else {
      let zipInt = parseInt(zipVal);
      if (zipInt > 9999 && zipInt < 100000) {
        setDummyLoading(true);
        setZip(zipInt);
        setTimeout(() => setDummyLoading(false), 500);
      } else {
        toast.error("ZIP Code must be 5 digits");
      }
    }
  };

  const cancelSale = () => {
    if (items.length > 0 || zip !== 30909 || customer) {
      Modal.confirm({
        title: "Are you sure you want to cancel this sale?",
        icon: <WarningTriangle className="px-2 text-4xl text-wbs-red" />,
        content: "All progress will be lost. This action cannot be undone.",
        onOk: () => restartPointOfSale(),
        onCancel: () => {},
        centered: true,
        width: 500,
        maskClosable: true,
      });
    } else {
      restartPointOfSale();
    }
  };

  const restartPointOfSale = (message) => {
    setLoading(true);
    setItems([]);
    setProducts([]);
    setDummyLoading(true);
    setTax(0);
    setProcessingFee(0);
    setTotal(0);
    setCalculating(false);
    setZip(30909);
    setCustomer(null);
    setCustomers([]);
    setCustomerZipModal(false);
    setPaymentModal(false);
    setClientSecret(null);
    setSoldTo(null);
    setTimeout(() => {
      setLoading(false);
      setDummyLoading(false);
      if (!message) {
        toast("Sale Cancelled");
      }
    }, 700);
  };

  const completeSale = () => {
    if (items.length === 0) {
      toast.error("No items added, please add items to complete sale");
      return;
    }
    if (soldTo === null || soldTo.length < 2) {
      toast.error("Please provide the name of the person you sold this to");
      return;
    }
    let payload = {
      items: items.filter((i) => i.quantity > 0),
      tax: tax,
      processingFee: processingFee,
      saleAmount: getTotal(),
      total: total,
      zip: zip,
      customer: customer,
      soldTo: soldTo,
    };
    setLoading(true);
    ProcessPayment(payload)
      .then((res) => {
        setClientSecret(res.data.clientSecret);
        setPaymentModal(true);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.response : "Error occurred while attempting to process payment");
        setTimeout(() => setLoading(false), 700);
      });
  };

  const renderPaymentModal = () => {
    const stripeOptions = {
      clientSecret: clientSecret,
      appearance: {
        theme: "stripe",
      },
    };
    return (
      <Modal
        open={paymentModal}
        onCancel={() => setPaymentModal(false)}
        centered
        destroyOnClose
        title="Collect Payment & Complete Sale"
        width={600}
        footer={[]}
      >
        <Elements stripe={stripePromise} options={stripeOptions}>
          <StripeForm clientSecret={clientSecret} cancelSale={cancelCardSale} nextStep={cardNextStep} />
        </Elements>
      </Modal>
    );
  };

  const cancelCardSale = () => {
    setClientSecret(null);
    setPaymentModal(false);
  };

  const cardNextStep = (intentId) => {
    GetTransactionStatusById(intentId)
      .then((res) => {
        const intentData = res.data;
        if (intentData.paymentStatus === "succeeded") {
          toast.success("Payment processed successfully, printing receipt...");
          setPaymentModal(false);
          printReceipt(intentData.transactionId);
          restartPointOfSale(true);
        } else {
          setTimeout(() => cardNextStep(intentId), 2500);
        }
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.response : "Error occurred while attempting to process payment");
      });
  };

  const printReceipt = (transactionId) => {
    GetTransactionById(transactionId)
      .then((res) => {
        let data = res.data;
        let doc = GenerateTransactionReceipt(data);
        doc.setProperties({
          title: `Transaction Receipt - ${transactionId.replace("transaction_", "").toUpperCase()}`,
          subject: `Transaction Receipt - ${transactionId.replace("transaction_", "").toUpperCase()}`,
          author: "Hypertek Solutions LLC",
          keywords: "",
          creator: "contact@hypertek.dev",
        });
        // window.open(`/transactions/${transactionId}`, "_blank");
        window.open(doc.output("bloburl"), "_blank");
      })
      .catch((err) => {
        console.error("Failed to generate receipt", err);
        toast.error("Failed to generate receipt, please try again");
      });
  };

  return (
    <div className="flex flex-col items-start justify-start flex-grow w-full">
      <div className="flex-1 flex-grow flow-root w-full px-2">
        <div className="inline-block w-full h-full min-w-full py-2 align-middle">
          {loading ? (
            <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">Loading...</p>
          ) : (
            <div className="flex flex-col items-center justify-start w-full h-full">
              <div className="flex flex-row items-center justify-between w-full pb-4 border-b border-gray-200">
                <div className="flex flex-row items-center justify-start gap-2">
                  <div className="flex flex-col items-start justify-center mr-4">
                    <p className="text-xl font-semibold">New Sale</p>
                    <p className="text-sm font-semibold text-gray-500">Process a OTC Sale</p>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-end gap-2">
                  <Button onClick={() => toast("This feature is not yet available, check again soon!")}>Reports</Button>
                </div>
              </div>
              <div className="grid w-full h-full grid-cols-3 gap-4 my-4">
                <div className="flex flex-col items-start justify-start w-full h-full col-span-2 gap-2 p-6 mt-4 bg-white rounded-md shadow-md shadow-gray-200">
                  <Select
                    placeholder="Search Products"
                    onChange={pickProduct}
                    value={null}
                    options={(products || []).map((p) => ({
                      label: `${p.productNumber} | ${p.item} | ${p.description.length > 40 ? p.description.substring(0, 40) + "..." : p.description}`,
                      value: p.productId,
                    }))}
                    className="w-full mb-2 font-sans"
                    notFoundContent="No products found, start typing to search"
                    controls={false}
                    showSearch
                    filterOption={false}
                    defaultActiveFirstOption={false}
                    onSearch={handleSearch}
                  />
                  {items.map((item) => (
                    <div className="flex items-center justify-between w-full px-4 py-3 rounded-md shadow-sm bg-slate-100/60">
                      {item.loaded ? (
                        <>
                          <div className="flex flex-col items-start justify-center">
                            <p className="text-base font-medium">
                              {item.item} - {item.productNumber}
                            </p>
                            <p className="text-xs text-gray-600">
                              {item.description.length > 40 ? item.description.substring(0, 40) + "..." : item.description}
                            </p>
                          </div>
                          <div className="flex items-center justify-end gap-3">
                            <div className="flex items-center justify-center gap-2">
                              <p className="text-xs font-medium text-gray-500">QTY:</p>
                              <InputNumber
                                placeholder="Quantity"
                                name={item.itemId + "_quantity"}
                                onBlur={(e) => updateQuantity(item.itemId, e.target.value)}
                                value={item.quantity}
                                className="font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                                controls={false}
                                min={0}
                              />
                            </div>
                            <div className="flex items-center justify-center gap-2">
                              <p className="text-xs font-medium text-gray-500">PRICE:</p>
                              <InputNumber
                                placeholder="Price"
                                name={item.itemId + "_price"}
                                onBlur={(e) => updatePrice(item.itemId, e.target.value)}
                                value={item.chargePrice}
                                className="font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                                controls={false}
                                step={0.01}
                                min={0}
                              />
                            </div>
                            <Popover content="Remove Item" placement="right">
                              <div
                                className="flex items-center justify-center w-8 h-8 duration-300 ease-in-out rounded-full group hover:bg-slate-200"
                                onClick={() => removeItem(item.itemId)}
                              >
                                <Trash
                                  className="h-5 duration-300 ease-in-out text-wbs-gray group-hover:cursor-pointer group-hover:text-wbs-red"
                                  strokeWidth={1.2}
                                />
                              </div>
                            </Popover>
                          </div>
                        </>
                      ) : (
                        <Skeleton active paragraph={{ rows: 2 }} title={false} />
                      )}
                    </div>
                  ))}
                </div>
                <div className="flex flex-col items-start justify-start w-full h-full col-span-1 gap-2 p-6 mt-4 bg-white rounded-md shadow-md shadow-gray-200">
                  <div className="flex items-center justify-between w-full pb-4 mb-4 border-b border-gray-400">
                    <div className="flex flex-col items-start justify-center w-full">
                      <p className="text-lg font-semibold">Order Summary</p>
                      <p className="text-sm font-medium text-gray-500">Additional information & totals</p>
                    </div>
                    <Button danger onClick={() => cancelSale()}>
                      Cancel Sale
                    </Button>
                  </div>
                  <div className="flex flex-col items-start justify-start w-full">
                    <label className="pb-2 text-xs text-gray-600 uppercase">Sales Tax ZIP Code</label>
                    <InputNumber
                      placeholder="Sales Tax ZIP Code"
                      onBlur={(e) => updateZip(e.target.value)}
                      value={zip}
                      className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                      controls={false}
                      max={99999}
                      maxLength={5}
                    />
                  </div>
                  <div className="flex flex-col items-start justify-start w-full mt-1">
                    <label className="pb-2 text-xs text-gray-600 uppercase">
                      Customer <span className="text-gray-400">(optional)</span>
                    </label>
                    <Select
                      placeholder="Search Customers"
                      onChange={pickCustomer}
                      value={customer ? customer.customerId : null}
                      options={(customers || []).map((p) => ({
                        label: `${p.customerNo} | ${p.name} | ${p.contacts.find((c) => c.primary)?.name}`,
                        value: p.customerId,
                      }))}
                      className="w-full mb-2 font-sans"
                      notFoundContent="No customers found, start typing to search"
                      controls={false}
                      showSearch
                      filterOption={false}
                      defaultActiveFirstOption={false}
                      onSearch={handleCustomerSearch}
                    />
                  </div>
                  <div className="flex flex-col items-start justify-start w-full">
                    <label className="pb-2 text-xs text-gray-600 uppercase">Sold To</label>
                    <Input
                      placeholder="Sold To"
                      onChange={(e) => setSoldTo(e.target.value)}
                      value={soldTo}
                      className="w-full px-4 py-1.5 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                    />
                  </div>
                  <div className="flex flex-row items-center justify-between w-full pt-4 mt-4 border-t border-gray-300">
                    <p className="font-semibold text-slate-800">Sale Amount</p>
                    <p>{formatCurrency(getTotal())}</p>
                  </div>
                  <div className="flex flex-row items-center justify-between w-full">
                    <p className="font-semibold text-slate-800">Sales Tax</p>
                    <p>{formatCurrency(tax)}</p>
                  </div>
                  <div className="flex flex-row items-center justify-between w-full pt-1 border-t border-gray-200">
                    <p className="font-semibold text-slate-800">Subtotal</p>
                    <p>{formatCurrency(getTotal() + tax)}</p>
                  </div>
                  <div className="flex flex-row items-center justify-between w-full">
                    <p className="font-semibold text-slate-800">Processing Fees</p>
                    <p>{formatCurrency(processingFee)}</p>
                  </div>
                  <div className="flex flex-row items-center justify-between w-full pt-1 border-t border-gray-200">
                    <p className="font-semibold text-black">Total</p>
                    <p className="font-semibold text-black">{formatCurrency(total)}</p>
                  </div>
                  <div className="flex flex-row items-center justify-between w-full pt-4 mt-4 border-t border-gray-300">
                    <Button block type="default" size="large" disabled={calculating} onClick={() => completeSale()}>
                      Complete Sale
                    </Button>
                  </div>
                </div>
              </div>
              {customer && renderZipModal()}
              {renderPaymentModal()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const StripeForm = ({ clientSecret, cancelSale, nextStep }) => {
  const [processing, setProcessing] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const saleCancel = () => {
    cancelSale();
  };

  const submitCard = async () => {
    if (!stripe || !elements) {
      toast.error("Stripe not initialized");
      return;
    }
    let cardElement = elements.getElement(CardElement);
    setProcessing(true);
    stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      })
      .then(function (result) {
        if (result.error) {
          setProcessing(false);
          toast.error(result.error.message);
        } else {
          let piId = result.paymentIntent.id;
          setTimeout(() => nextStep(piId), 1500);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setProcessing(false);
      });
  };

  return (
    <div className="flex flex-col items-center justify-start w-full">
      <div className="w-full p-2 mx-auto mt-1 border border-gray-300 rounded-md">
        <CardElement
          className="card"
          options={{
            showIcon: true,
            style: {
              base: {
                backgroundColor: "white",
              },
              paddingTop: "5px",
            },
            disableLink: true,
          }}
        />
      </div>
      <div className="flex flex-row items-center justify-between w-2/3 gap-2 mx-auto mt-5 -mb-2 sm:mt-6">
        <Button onClick={() => saleCancel()} disabled={processing}>
          CANCEL SALE
        </Button>
        <Button onClick={() => submitCard()} disabled={processing} type="primary">
          {processing ? "PROCESSING" : "SUBMIT"}
        </Button>
      </div>
    </div>
  );
};

export default NewSale;
