import dayjs from "dayjs";

export const newCustomerForm = {
  customerNo: {
    fieldName: "customerNo",
    label: "Customer #",
    inputType: "text",
    placeholder: "Enter a customer number",
    defaultValue: "",
    config: {
      required: "Customer # is required",
      validate: (value) => value.length >= 2 || "Customer # is required and must be at least 2 characters long",
    },
  },
  name: {
    fieldName: "name",
    label: "Name",
    inputType: "text",
    placeholder: "Enter a name for the customer",
    defaultValue: "",
    config: {
      required: "Name is required",
      validate: (value) => value.length >= 2 || "Name is required and must be at least 2 characters long",
    },
  },
  company: {
    fieldName: "company",
    label: "Is this a Commercial / Company account",
    inputType: "switch",
    placeholder: "",
    defaultValue: false,
    config: {
      required: false,
    },
  },
  divider1: {
    inputType: "divider",
  },
  address: {
    fieldName: "address",
    label: "Address",
    inputType: "address",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  divider2: {
    inputType: "divider",
  },
  contactName: {
    fieldName: "contact",
    label: "Contact Information",
    inputType: "contact",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  divider3: {
    inputType: "divider",
  },
  shipTo: {
    fieldName: "shipTo",
    label: "Ship To Address",
    inputType: "address",
    placeholder: "",
    defaultValue: "",
    config: {
      required: false,
    },
  },
  divider4: {
    inputType: "divider",
  },
  purchaseOrder: {
    fieldName: "purchaseOrder",
    label: "Purchase Order",
    inputType: "select",
    placeholder: "Select Purchase Order Requirements",
    defaultValue: "",
    options: [
      { label: "Not Required", value: "Not Required" },
      { label: "Optional", value: "Optional" },
      { label: "Required", value: "Required" },
      { label: "N/A", value: "N/A" },
    ],
    config: {
      required: false,
    },
  },
  divider5: {
    inputType: "divider",
  },
  customerType: {
    fieldName: "customerType",
    label: "Customer Type",
    inputType: "select",
    placeholder: "Select the customer type",
    defaultValue: "",
    options: [
      { label: "Cash Customer", value: "Cash Customer" },
      { label: "Credit Customer", value: "Credit Customer" },
      { label: "COD Customer", value: "COD Customer" },
      { label: "Other", value: "Other" },
    ],
    config: {
      required: "Customer Type is required",
      validate: (value) => value.length >= 2 || "Please select a valid customer type",
    },
  },
  divider6: {
    inputType: "divider",
  },
  statementType: {
    fieldName: "statementType",
    label: "Default Statement Type",
    inputType: "select",
    placeholder: "Select the default statement type for this customer",
    defaultValue: "Print only invoices",
    options: [
      { label: "Print only statements", value: "Print only statements" },
      { label: "Print only invoices", value: "Print only invoices" },
      { label: "Print both statements and invoices", value: "Print both statements and invoices" },
    ],
    config: {
      required: false,
    },
  },
  divider7: {
    inputType: "divider",
  },
  priceCode: {
    fieldName: "priceCode",
    label: "Default Pricing Tier",
    inputType: "select",
    placeholder: "Select the default price tier for this customer",
    defaultValue: "tier1",
    options: [
      { label: "Tier 1 Pricing - 200% Markup", value: "tier1" },
      { label: "Tier 2 Pricing - 150% Markup", value: "tier2" },
      { label: "Tier 3 Pricing - 50% Markup", value: "tier3" },
    ],
    config: {
      required: false,
    },
  },
  divider8: {
    inputType: "divider",
  },
  creditLimit: {
    fieldName: "creditLimit",
    label: "Credit Limit",
    inputType: "number",
    placeholder: "Enter a credit limit for this customer",
    defaultValue: 0,
    config: {
      required: false,
    },
  },
  divider9: {
    inputType: "divider",
  },
  holdStatus: {
    fieldName: "holdStatus",
    label: "Credit Hold Status",
    inputType: "select",
    placeholder: "Select the credit hold status for this customer",
    defaultValue: "No Credit Hold",
    options: [
      { label: "No Credit Hold", value: "No Credit Hold" },
      { label: "On Hold", value: "On Hold" },
      { label: "Overridden", value: "Overridden" },
    ],
    config: {
      required: false,
    },
  },
  divider10: {
    inputType: "divider",
  },
  terms: {
    fieldName: "terms",
    label: "Payment Terms",
    inputType: "select",
    placeholder: "Select the default payment terms for this customer",
    defaultValue: "NET 30",
    options: [
      { label: "NET 10", value: "NET 10" },
      { label: "NET 15", value: "NET 15" },
      { label: "NET 30", value: "NET 30" },
      { label: "NET 45", value: "NET 45" },
      { label: "NET 60", value: "NET 60" },
      { label: "NET 90", value: "NET 90" },
      { label: "Collect on Delivery", value: "COD" },
    ],
    config: {
      required: false,
    },
  },
  divider11: {
    inputType: "divider",
  },
  category: {
    fieldName: "category",
    label: "Customer Category",
    inputType: "select",
    placeholder: "Select the category that best fits this customer",
    defaultValue: "",
    options: [
      { label: "Contractor", value: "Contractor" },
      { label: "Builder", value: "Builder" },
      { label: "Retail", value: "Retail" },
      { label: "Wholesale", value: "Wholesale" },
      { label: "Government", value: "Government" },
      { label: "Non-Profit", value: "Non-Profit" },
      { label: "Educational", value: "Educational" },
      { label: "Medical", value: "Medical" },
      { label: "Industrial", value: "Industrial" },
      { label: "Commercial", value: "Commercial" },
      { label: "Residential", value: "Residential" },
      { label: "Religious", value: "Religious" },
      { label: "Individual", value: "Individual" },
      { label: "Agricultural", value: "Agricultural" },
      { label: "Other", value: "Other" },
      { label: "Unknown", value: "" },
    ],
    config: {
      required: false,
    },
  },
  divider12: {
    inputType: "divider",
  },
  dateOpened: {
    fieldName: "dateOpened",
    label: "Date Opened",
    inputType: "date",
    placeholder: "Select the date this customer was opened",
    defaultValue: dayjs(),
    config: {
      required: false,
    },
  },
  divider13: {
    inputType: "divider",
  },
};
