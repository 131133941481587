import { Button, DatePicker, Dropdown, Input, InputNumber, Modal, Select, Switch, Table } from "antd";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import {
  AddInvoiceLineItem,
  AddNoteToInvoice,
  GetInvoiceByID,
  LogInvoicePayment,
  RemoveInvoiceLineItem,
  UpdateInvoiceDetails,
  UpdateInvoiceLineItem,
  UpdateInvoiceSoldTo,
  UpdateInvoiceTermsAndTax,
} from "../../../actions/invoice";
import validator from "validator";
import { add, formatCurrency, formatPhoneNumber, multiply } from "../../../data/utils";
import dayjs from "dayjs";
import { ChevronDown, Edit, Trash } from "lucide-react";
import { validate } from "uuid";
import { QueryProducts } from "../../../actions/products";
import { GetACustomerByID } from "../../../actions/customer";
import { filterOption } from "../../../components/DynamicField";
import { WarningTriangle } from "iconoir-react";
import Note from "../components/note";
import { AddressAutofill } from "@mapbox/search-js-react";
import { MaskedInput } from "antd-mask-input";
import Payment from "../components/payment";
import { loadStripe } from "@stripe/stripe-js";
import CustomerNote from "../../customers/view/cards/note";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const OpenInvoice = () => {
  const [loading, setLoading] = useState(true);
  const [dummyLoading, setDummyLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [addProductModal, setAddProductModal] = useState(false);
  const [addServiceModal, setAddServiceModal] = useState(false);
  const [addCreditModal, setAddCreditModal] = useState(false);
  const [addProductQuery, setAddProductQuery] = useState([]);
  const [addProductData, setAddProductData] = useState({
    itemId: null,
    productId: null,
    status: "",
    shipped: "N/A",
    description: "",
    unitPrice: 0,
    quantity: 1,
    total: 0,
    type: "Product",
    taxable: true,
    label: null,
  });
  const [noteModal, setNoteModal] = useState(false);
  const [noteData, setNoteData] = useState(null);
  const [editSoldToModal, setEditSoldToModal] = useState(false);
  const [soldToData, setSoldToData] = useState({
    name: null,
    contactPerson: null,
    address: {
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
      addressId: null,
    },
    phone: null,
    email: null,
  });
  const [editDetails, setEditDetails] = useState(false);
  const [detailsData, setDetailsData] = useState({
    dateInvoiced: null,
    customerPo: null,
  });
  const [termsAndTaxData, setTermsAndTaxData] = useState({
    terms: null,
    taxExempt: null,
    shipping: 0,
  });
  const [termsAndTax, setTermsAndTax] = useState(false);
  const [logPayment, setLogPayment] = useState(false);
  const [logPaymentData, setLogPaymentData] = useState({
    paymentDate: null,
    paymentAmount: null,
    paymentMethod: null,
  });
  const [customerNotesModal, setCustomerNotesModal] = useState(false);

  const { invoiceId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      if (invoiceId && invoiceId.includes("invoice_")) {
        reloadData();
      } else {
        toast.error("Invalid invoice ID");
        navigate("/operations/invoices");
      }
    }
    return () => {
      inView = false;
    };
  }, []);

  const reloadData = () => {
    setLoading(true);
    GetInvoiceByID(invoiceId)
      .then((res) => {
        setInvoiceData(res.data);
        GetACustomerByID(res.data.customerId)
          .then((res) => {
            setCustomer(res.data);
            setTimeout(() => {
              setLoading(false);
            }, 700);
          })
          .catch((err) => {
            toast.error(err?.response?.data ? err.response.data.response : "An error occurred");
            setLoading(false);
          });
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.response : "An error occurred");
        navigate("/operations/invoices");
      });
  };

  const getTotal = () => {
    let total = invoiceData.items.reduce((acc, item) => (item.type !== "Credit" ? acc + item.total : acc - item.total), 0);
    return parseFloat(total.toFixed(2));
  };

  const getShippingTotal = () => {
    return parseFloat((invoiceData.shipping || 0).toFixed(2));
  };

  const handleMenuClick = (type) => {
    if (type === "product") {
      setAddProductData((prev) => ({ ...prev, type: "Product" }));
      setAddProductModal(true);
    } else if (type === "service") {
      setAddProductData((prev) => ({ ...prev, type: "Service" }));
      setAddServiceModal(true);
    } else if (type === "credit") {
      setAddProductData((prev) => ({ ...prev, type: "Credit" }));
      setAddCreditModal(true);
    } else if (type === "other") {
      setAddProductData((prev) => ({ ...prev, type: "Other" }));
      setAddServiceModal(true);
    } else {
      toast.error("Unknown error has occurred");
    }
  };

  const items = [
    {
      label: "Add a Product",
      key: "product",
      onClick: () => handleMenuClick("product"),
    },
    {
      label: "Add a Service",
      key: "service",
      onClick: () => handleMenuClick("service"),
    },
    {
      label: "Add a Credit",
      key: "credit",
      onClick: () => handleMenuClick("credit"),
    },
    {
      label: "Add Other Charges",
      key: "other",
      onClick: () => handleMenuClick("other"),
    },
  ];

  const menuProps = {
    items,
  };

  const handleProductSearch = (query) => {
    if (query?.length > 1) {
      QueryProducts({ search: query })
        .then((res) => {
          setAddProductQuery(res.data.results);
        })
        .catch((err) => {
          toast.error("Product search failed, please try again");
          setAddProductQuery([]);
        });
    }
  };

  const updateProduct = (v) => {
    setDummyLoading(true);
    let tmp = addProductData;
    let product = addProductQuery.find((c) => c.productId === v);
    if (!product) {
      toast.error("Product not found, please try again");
      return;
    }
    tmp.label = `[${product.categoryCode}]${product.productNumber && product.productNumber.length > 0 ? " - " + product.productNumber : ""}${
      product.item && product.item.length > 0 ? " - " + product.item : ""
    }${product.description && product.description.length > 0 ? " - " + product.description : ""}`;
    tmp.productId = v;
    tmp.description = product.description;
    let price = parseFloat((parseFloat(product.price.toFixed(2)) * parseFloat(1 + product.priceTiers[customer?.priceCode ?? "tier3"] / 100)).toFixed(2));
    tmp.unitPrice = price;
    tmp.quantity = 1;
    tmp.total = price;
    setAddProductData(tmp);
    setTimeout(() => setDummyLoading(false), 500);
  };

  const updateNewProductQuantity = (v) => {
    let intValue = v.target.value;
    setDummyLoading(true);
    intValue = parseFloat(intValue);
    let tmp = addProductData;
    if (isNaN(intValue) || intValue <= 0) {
      setTimeout(() => setDummyLoading(false), 500);
      return;
    }
    tmp.quantity = intValue;
    tmp.total = parseFloat((tmp.unitPrice * intValue).toFixed(2));
    setAddProductData(tmp);
    setTimeout(() => setDummyLoading(false), 500);
  };

  const updateNewProductPrice = (v) => {
    let intValue = v.target.value;
    setDummyLoading(true);
    intValue = parseFloat(intValue);
    let tmp = addProductData;
    if (isNaN(intValue) || intValue <= 0) {
      setTimeout(() => setDummyLoading(false), 500);
      return;
    }
    tmp.unitPrice = intValue;
    tmp.total = multiply(tmp?.quantity ?? 1, intValue);
    setAddProductData(tmp);
    setTimeout(() => setDummyLoading(false), 500);
  };

  const updateNewProductTotal = (v) => {
    let intValue = v.target.value;
    setDummyLoading(true);
    intValue = parseFloat(intValue);
    let tmp = addProductData;
    if (isNaN(intValue) || intValue <= 0) {
      setTimeout(() => setDummyLoading(false), 500);
      return;
    }
    tmp.total = intValue;
    tmp.unitPrice = parseFloat((intValue / (tmp?.quantity ?? 1)).toFixed(2));
    setAddProductData(tmp);
    setTimeout(() => setDummyLoading(false), 500);
  };

  const updateShippingStatus = (v) => {
    setDummyLoading(true);
    if (v !== "Yes") {
      let tmp = addProductData;
      tmp.total = parseFloat((tmp.quantity * tmp.unitPrice).toFixed(2));
      tmp.shipped = v;
      setAddProductData(tmp);
    } else {
      setAddProductData((prev) => ({ ...prev, shipped: v }));
    }
    setTimeout(() => setDummyLoading(false), 500);
  };

  const closeAddProductModal = () => {
    setLoading(true);
    setAddProductModal(false);
    setAddServiceModal(false);
    setAddCreditModal(false);
    setAddProductData({
      itemId: null,
      productId: null,
      status: "",
      shipped: "N/A",
      description: "",
      unitPrice: 0,
      quantity: 1,
      total: 0,
      type: "Product",
      taxable: true,
      label: null,
    });
    setAddProductQuery([]);
    setDummyLoading(false);
    reloadData();
  };

  const addProductToInvoiceDisabled = () => {
    if (!addProductData.productId || !addProductData.quantity || !addProductData.unitPrice || !addProductData.total) {
      return true;
    } else if (addProductData.total <= 0 || isNaN(addProductData.total)) {
      return true;
    } else if (!addProductData.status || addProductData.status.length <= 0) {
      return true;
    } else if (dummyLoading) {
      return true;
    } else {
      return false;
    }
  };

  const addProductToInvoice = () => {
    setLoading(true);
    let tmp = addProductData;
    if (addProductData.itemId && validate(addProductData.itemId)) {
      UpdateInvoiceLineItem(invoiceId, addProductData.itemId, {
        productId: tmp.productId,
        status: tmp.status,
        label: tmp.label,
        description: tmp.description,
        shipped: tmp.shipped,
        unitPrice: tmp.unitPrice,
        quantity: tmp.quantity,
        total: tmp.total,
        taxable: tmp.taxable,
      })
        .then((res) => {
          toast.success(res?.data?.message ?? "Line item updated successfully");
          closeAddProductModal();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.response : "An error occurred");
          setLoading(false);
        });
    } else {
      AddInvoiceLineItem(invoiceId, tmp)
        .then((res) => {
          toast.success("Product added to invoice successfully");
          closeAddProductModal();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.response : "An error occurred");
          setLoading(false);
        });
    }
  };

  const renderAddProductModal = () => {
    return (
      <Modal
        open={addProductModal}
        onClose={() => closeAddProductModal()}
        onCancel={() => closeAddProductModal()}
        footer={[
          <div className="flex items-center justify-end w-full gap-4">
            <Button onClick={() => closeAddProductModal()}>Cancel</Button>
            <Button onClick={() => addProductToInvoice()} type="primary" disabled={addProductToInvoiceDisabled()}>
              {addProductData.itemId && validate(addProductData.itemId) ? "Update" : "Add to Invoice"}
            </Button>
          </div>,
        ]}
        title={addProductData.itemId && validate(addProductData.itemId) ? "Update invoice line item" : "Add a product"}
        centered
        destroyOnClose
        width={680}
      >
        <div className="flex flex-col items-start justify-start w-full gap-4 py-2">
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Find your product</p>
            <Select
              placeholder="Search Products"
              onChange={(v) => updateProduct(v)}
              onSelect={(v) => updateProduct(v)}
              defaultValue={addProductData.productId && addProductData.productId.length > 10 ? addProductData.productId : null}
              options={(addProductQuery || []).map((p) => ({
                label: `[${p.categoryCode}]${p.productNumber && p.productNumber.length > 0 ? " - " + p.productNumber : ""}${
                  p.item && p.item.length > 0 ? " - " + p.item : ""
                }${p.description && p.description.length > 0 ? " - " + p.description : ""}`,
                value: p.productId,
              }))}
              className="w-full mb-2 font-sans"
              notFoundContent="No products found, start typing to search"
              controls={false}
              showSearch
              filterOption={false}
              defaultActiveFirstOption={false}
              onSearch={handleProductSearch}
              allowClear
            />
          </div>
          <div className="grid w-full grid-cols-3 gap-x-4">
            <div className="flex flex-col items-start justify-start w-full gap-1">
              <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Unit Price</p>
              <InputNumber
                placeholder="Price"
                value={addProductData.unitPrice}
                onBlur={(v) => updateNewProductPrice(v)}
                className="w-full"
                disabled={!addProductData.productId || dummyLoading}
                controls={false}
                addonBefore="$"
              />
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-1">
              <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Quantity</p>
              <InputNumber
                placeholder="Quantity"
                value={addProductData.quantity}
                onBlur={(v) => updateNewProductQuantity(v)}
                className="w-full"
                controls={false}
                disabled={!addProductData.productId || dummyLoading}
              />
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-1">
              <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Total</p>
              <InputNumber
                placeholder="Total"
                value={addProductData.total}
                onBlur={(v) => updateNewProductTotal(v)}
                className="w-full"
                disabled={!addProductData.productId || dummyLoading}
                controls={false}
                addonBefore="$"
              />
            </div>
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Product Status</p>
            <Select
              placeholder="Select Product Status"
              onChange={(v) => setAddProductData((prev) => ({ ...prev, status: v }))}
              onSelect={(v) => setAddProductData((prev) => ({ ...prev, status: v }))}
              defaultValue={addProductData.status && addProductData.status.length >= 0 ? addProductData.status : null}
              options={[
                { label: "Ordered", value: "Ordered" },
                { label: "Backordered", value: "Backordered" },
                { label: "Inventory", value: "Inventory" },
                { label: "Delivered", value: "Delivered" },
                { label: "Other", value: "" },
              ]}
              className="w-full mb-2 font-sans"
              allowClear
              controls={false}
              showSearch
              filterOption={filterOption}
              disabled={!addProductData.productId || dummyLoading}
            />
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Does this product require shipping/delivery to the customer?</p>
            <Select
              placeholder="Select Shipping Status"
              onChange={(v) => updateShippingStatus(v)}
              onSelect={(v) => updateShippingStatus(v)}
              defaultValue={addProductData.shipped && addProductData.shipped.length >= 0 ? addProductData.shipped : null}
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
                { label: "N/A", value: "N/A" },
              ]}
              className="w-full mb-2 font-sans"
              allowClear
              controls={false}
              showSearch
              filterOption={filterOption}
              disabled={!addProductData.productId || dummyLoading}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const deleteItem = (itemId) => {
    Modal.confirm({
      title: "Are you sure you want to remove this line item?",
      content: "This action cannot be undone",
      okText: "Yes, delete",
      okType: "danger",
      cancelText: "No, cancel",
      icon: <WarningTriangle className="px-2 -mt-1.5 text-2xl leading-none text-wbs-red" />,
      onOk: () => {
        setLoading(true);
        RemoveInvoiceLineItem(invoiceId, itemId)
          .then((res) => {
            toast.success(res?.data?.message ?? "Line item removed successfully");
            reloadData();
          })
          .catch((err) => {
            toast.error(err.response.data ? err.response.data.response : "An error occurred");
            setLoading(false);
          });
      },
      centered: true,
      maskClosable: true,
      width: 500,
    });
  };

  const editItem = (itemId) => {
    let item = invoiceData.items.find((i) => i.itemId === itemId);
    if (!item) {
      toast.error("Item not found, please try again");
      return;
    } else {
      if (item.type === "Product") {
        setAddProductData({ ...item, total: multiply(item.unitPrice, item.quantity) });
        setAddProductQuery([item.productData]);
        setAddProductModal(true);
      } else if (item.type === "Service" || item.type === "Other") {
        setAddProductData({ ...item });
        setAddServiceModal(true);
      } else if (item.type === "Credit") {
        setAddProductData(item);
        setAddCreditModal(true);
      }
    }
  };

  const renderType = (type) => {
    if (type === "Product") {
      return <p className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded">Product</p>;
    } else if (type === "Service") {
      return <p className="bg-indigo-100 text-indigo-800 text-xs font-medium px-2.5 py-0.5 rounded">Service</p>;
    } else if (type === "Credit") {
      return <p className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded">Credit</p>;
    } else {
      return <p className="bg-gray-100 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded">Other</p>;
    }
  };

  const addServiceToInvoiceDisabled = () => {
    if (!addProductData.label || !addProductData.description || !addProductData.unitPrice || !addProductData.total) {
      return true;
    } else if (addProductData.total <= 0 || isNaN(addProductData.total)) {
      return true;
    } else if (dummyLoading) {
      return true;
    } else {
      return false;
    }
  };

  const addServiceToInvoice = () => {
    setLoading(true);
    if (addProductData.itemId && validate(addProductData.itemId)) {
      UpdateInvoiceLineItem(invoiceId, addProductData.itemId, {
        label: addProductData.label,
        description: addProductData.description,
        unitPrice: addProductData.unitPrice,
        quantity: addProductData.quantity,
        total: addProductData.total,
      })
        .then((res) => {
          toast.success(res?.data?.message ?? "Line item updated successfully");
          closeAddProductModal();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.response : "An error occurred");
          setLoading(false);
        });
    } else {
      AddInvoiceLineItem(invoiceId, {
        label: addProductData.label,
        description: addProductData.description,
        unitPrice: addProductData.unitPrice,
        quantity: addProductData.quantity,
        total: addProductData.total,
        type: addProductData.type,
      })
        .then((res) => {
          toast.success(`${addProductData.type} added to invoice successfully`);
          closeAddProductModal();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.response : "An error occurred");
          setLoading(false);
        });
    }
  };

  const renderServiceAndOtherModal = () => {
    return (
      <Modal
        open={addServiceModal}
        onClose={() => closeAddProductModal()}
        onCancel={() => closeAddProductModal()}
        footer={[
          <div className="flex items-center justify-end w-full gap-4">
            <Button onClick={() => closeAddProductModal()}>Cancel</Button>
            <Button onClick={() => addServiceToInvoice()} type="primary" disabled={addServiceToInvoiceDisabled()}>
              {addProductData.itemId && validate(addProductData.itemId) ? "Update" : "Add to Invoice"}
            </Button>
          </div>,
        ]}
        title={
          addProductData.itemId && validate(addProductData.itemId)
            ? `Update ${addProductData.type.toLowerCase()}`
            : addProductData.type === "Service"
              ? "Add service"
              : "Add other charge"
        }
        centered
        destroyOnClose
        width={680}
      >
        <div className="flex flex-col items-start justify-start w-full gap-4 py-2">
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <p className="text-xs font-semibold leading-normal tracking-wide uppercase">
              {addProductData.type === "Service" ? "Service Provided" : "Charge Type"}
            </p>
            <Input
              placeholder={addProductData.type === "Service" ? "What service is this charge for?" : "What is this charge for?"}
              onChange={(e) => setAddProductData((prev) => ({ ...prev, label: e.target.value }))}
              defaultValue={addProductData && addProductData.label ? addProductData.label : null}
              className="w-full px-4 py-1.5 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
            />
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <p className="text-xs font-semibold leading-normal tracking-wide uppercase">
              {addProductData.type === "Service" ? "Service Description" : "Description"}
            </p>
            <Input.TextArea
              placeholder={addProductData.type === "Service" ? "Provide a description of the service" : "Provide a description of the charge"}
              onChange={(e) => setAddProductData((prev) => ({ ...prev, description: e.target.value }))}
              rows={4}
              defaultValue={addProductData && addProductData.description ? addProductData.description : null}
              autoSize={false}
              className="w-full !resize-none font-sans py-2 px-4 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
            />
          </div>
          <div className="grid w-full grid-cols-3 gap-x-4">
            <div className="flex flex-col items-start justify-start w-full gap-1">
              <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Unit Price</p>
              <InputNumber
                placeholder="Price"
                value={addProductData.unitPrice}
                onBlur={(v) => updateNewProductPrice(v)}
                className="w-full"
                disabled={dummyLoading}
                controls={false}
                addonBefore="$"
              />
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-1">
              <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Quantity</p>
              <InputNumber
                placeholder="Quantity"
                value={addProductData.quantity}
                onBlur={(v) => updateNewProductQuantity(v)}
                className="w-full"
                controls={false}
                disabled={dummyLoading}
              />
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-1">
              <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Total</p>
              <InputNumber
                placeholder="Total"
                value={addProductData.total}
                onBlur={(v) => updateNewProductTotal(v)}
                className="w-full"
                disabled={dummyLoading}
                controls={false}
                addonBefore="$"
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const addCreditToInvoice = () => {
    setLoading(true);
    if (addProductData.itemId && validate(addProductData.itemId)) {
      UpdateInvoiceLineItem(invoiceId, addProductData.itemId, {
        description: addProductData.description,
        unitPrice: addProductData.unitPrice,
        quantity: 1,
        total: addProductData.unitPrice,
        type: "Credit",
      })
        .then((res) => {
          toast.success(res?.data?.message ?? "Invoice credit updated successfully");
          closeAddProductModal();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.response : "An error occurred");
          setLoading(false);
        });
    } else {
      AddInvoiceLineItem(invoiceId, {
        description: addProductData.description,
        unitPrice: addProductData.unitPrice,
        quantity: 1,
        total: addProductData.unitPrice,
        type: "Credit",
      })
        .then((res) => {
          toast.success(`${addProductData.type} added to invoice successfully`);
          closeAddProductModal();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.response : "An error occurred");
          setLoading(false);
        });
    }
  };

  const addCreditToInvoiceDisabled = () => {
    if (!addProductData.description || !addProductData.unitPrice) {
      return true;
    } else if (addProductData.total <= 0 || isNaN(addProductData.total)) {
      return true;
    } else if (dummyLoading) {
      return true;
    } else {
      return false;
    }
  };

  const renderCreditModal = () => {
    return (
      <Modal
        open={addCreditModal}
        onClose={() => closeAddProductModal()}
        onCancel={() => closeAddProductModal()}
        footer={[
          <div className="flex items-center justify-end w-full gap-4">
            <Button onClick={() => closeAddProductModal()}>Cancel</Button>
            <Button onClick={() => addCreditToInvoice()} type="primary" disabled={addCreditToInvoiceDisabled()}>
              {addProductData.itemId && validate(addProductData.itemId) ? "Update" : "Add to Invoice"}
            </Button>
          </div>,
        ]}
        title={addProductData.itemId && validate(addProductData.itemId) ? `Update ${addProductData.type.toLowerCase()}` : "Add invoice credit"}
        centered
        destroyOnClose
        width={680}
      >
        <div className="flex flex-col items-start justify-start w-full gap-4 py-2">
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Credit description</p>
            <Input
              placeholder="What is this credit for?"
              onChange={(e) => setAddProductData((prev) => ({ ...prev, description: e.target.value }))}
              defaultValue={addProductData && addProductData.description ? addProductData.description : null}
              className="w-full px-4 py-1.5 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
            />
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Unit Price</p>
            <InputNumber
              placeholder="Price"
              value={addProductData.unitPrice}
              onBlur={(v) => updateNewProductPrice(v)}
              className="w-full"
              disabled={dummyLoading}
              controls={false}
              addonBefore="$"
            />
          </div>
        </div>
      </Modal>
    );
  };

  const closeNoteModal = () => {
    setNoteModal(false);
    setNoteData(null);
  };

  const submitNewNote = () => {
    if (noteData && noteData.length > 3) {
      AddNoteToInvoice(invoiceId, { note: noteData })
        .then((res) => {
          toast.success("Note added successfully");
          setLoading(true);
          closeNoteModal();
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.response : "An error occurred");
        });
    } else {
      toast.error("Please provide a more descriptive note");
    }
  };

  const renderNoteModal = () => {
    return (
      <Modal
        open={noteModal}
        onClose={() => closeNoteModal()}
        onCancel={() => closeNoteModal()}
        footer={[
          <div className="flex items-center justify-end w-full gap-4">
            <Button onClick={() => closeNoteModal()}>Cancel</Button>
            <Button onClick={() => submitNewNote()} type="primary" disabled={!noteData}>
              Add Note
            </Button>
          </div>,
        ]}
        title="Add a new internal note to invoice"
        centered
        destroyOnClose
        width={680}
      >
        <div className="flex flex-col items-start justify-start w-full gap-4 py-2">
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Note</p>
            <Input.TextArea
              placeholder="Enter your note here"
              onChange={(e) => setNoteData(e.target.value)}
              rows={4}
              autoSize={false}
              className="w-full !resize-none font-sans py-2 px-4 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
            />
          </div>
        </div>
      </Modal>
    );
  };

  const openEditSoldTo = () => {
    let tmp = {
      name: invoiceData.soldTo.name,
      contactPerson: invoiceData.soldTo.contactPerson,
      address: {
        address1: invoiceData.soldTo.address.address1,
        address2: invoiceData.soldTo.address.address2,
        city: invoiceData.soldTo.address.city,
        state: invoiceData.soldTo.address.state,
        zip: invoiceData.soldTo.address.zip,
      },
      phone: invoiceData.soldTo.phone,
      email: invoiceData.soldTo.email,
      addressString: `${invoiceData?.soldTo?.address?.address1}${
        invoiceData?.soldTo?.address?.address2?.length ? ` ${invoiceData?.soldTo?.address?.address2}` : ""
      }, ${invoiceData?.soldTo?.address?.city}, ${invoiceData?.soldTo?.address?.state} ${invoiceData?.soldTo?.address?.zip}`,
    };
    setSoldToData(tmp);
    setEditSoldToModal(true);
  };

  const handleAddressRetrieve = useCallback((res) => {
    let tmpFeature = res.features[0];
    let address = {
      address1: tmpFeature.properties.address_line1,
      address2: "",
      city: tmpFeature.properties.address_level2,
      state: tmpFeature.properties.address_level1,
      zip: tmpFeature.properties.postcode,
    };
    setSoldToData((prev) => ({ ...prev, addressString: tmpFeature.properties.full_address, address: address }));
  });

  const closeEditSoldTo = () => {
    setEditSoldToModal(false);
    setSoldToData({
      name: null,
      contactPerson: null,
      address: {
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        addressId: null,
      },
      phone: null,
      email: null,
    });
  };

  const submitSoldToUpdate = () => {
    let payload = {};
    if (soldToData.name && soldToData.name.length > 2 && soldToData.name !== invoiceData.soldTo.name) {
      payload.name = soldToData.name;
    }
    if (soldToData.contactPerson && soldToData.contactPerson.length > 2 && soldToData.contactPerson !== invoiceData.soldTo.contactPerson) {
      payload.contactPerson = soldToData.contactPerson;
    }
    if (soldToData.phone && soldToData.phone.length === 10 && soldToData.phone !== invoiceData.soldTo.phone && validator.isMobilePhone(soldToData.phone)) {
      payload.phone = soldToData.phone;
    }
    if (soldToData.email && soldToData.email.length > 4 && soldToData.email !== invoiceData.soldTo.email && validator.isEmail(soldToData.email)) {
      payload.email = soldToData.email;
    }
    if (soldToData.address.address1 && soldToData.address.address1.length > 2 && soldToData.address.address1 !== invoiceData.soldTo.address.address1) {
      payload.address = soldToData.address;
    }
    if (Object.keys(payload).length === 0) {
      toast("No changes detected, nothing to update");
      closeEditSoldTo();
    } else {
      UpdateInvoiceSoldTo(invoiceId, payload)
        .then((res) => {
          toast.success("Updated sold to information...");
          closeEditSoldTo();
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.response : "An error occurred");
          closeEditSoldTo();
        });
    }
  };

  const renderEditSoldToModal = () => {
    return (
      <Modal
        open={editSoldToModal}
        onClose={() => closeEditSoldTo()}
        onCancel={() => closeEditSoldTo()}
        footer={[
          <div className="flex items-center justify-end w-full gap-4">
            <Button onClick={() => closeEditSoldTo()}>Cancel</Button>
            <Button onClick={() => submitSoldToUpdate()} type="primary">
              Update
            </Button>
          </div>,
        ]}
        title="Edit Sold To Information"
        centered
        destroyOnClose
        width={680}
      >
        <div className="flex flex-col items-start justify-start w-full gap-4 py-2">
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Name</p>
            <Input
              placeholder="Name of the person or company you are selling to"
              onChange={(e) => setSoldToData((prev) => ({ ...prev, name: e.target.value }))}
              defaultValue={soldToData && soldToData.name ? soldToData.name : null}
              className="w-full px-4 py-1.5 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
            />
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Contact Person</p>
            <Input
              placeholder="Name of the contact person"
              onChange={(e) => setSoldToData((prev) => ({ ...prev, contactPerson: e.target.value }))}
              defaultValue={soldToData && soldToData.contactPerson ? soldToData.contactPerson : null}
              className="w-full px-4 py-1.5 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
            />
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Address</p>
            <AddressAutofill
              accessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              onRetrieve={(r) => handleAddressRetrieve(r)}
              options={{ language: "en", country: "US" }}
              className="w-full"
            >
              <Input
                placeholder="Start typing the address"
                value={soldToData.addressString}
                onChange={({ target: { value } }) => setSoldToData((prev) => ({ ...prev, addressString: value }))}
                autoComplete="address-line1"
                id="mapbox-autofill"
                className="w-full px-4 py-1.5 font-sans text-sm border-gray-300 rounded-md focus:border-blue-500 focus:ring-0"
              />
            </AddressAutofill>
          </div>

          <div className="flex flex-col items-start justify-start w-full gap-1">
            <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Phone Number</p>
            <MaskedInput
              defaultValue={soldToData?.phone}
              placeholder="Contact phone number"
              mask={"(000) 000-0000"}
              className="py-1 text-gray-900 border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 phoneInput"
              onChange={(e) => setSoldToData((prev) => ({ ...prev, phone: e.unmaskedValue }))}
            />
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Email Address</p>
            <Input
              placeholder="Contact email address"
              className="w-full px-4 py-1.5 font-sans text-sm border-gray-300 rounded-md focus:border-blue-500 focus:ring-0"
              type="email"
              defaultValue={soldToData?.email}
              onChange={(e) => setSoldToData((prev) => ({ ...prev, email: e.target.value }))}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const openEditDetails = () => {
    setDetailsData({
      dateInvoiced: invoiceData.dateInvoiced && dayjs(invoiceData.dateInvoiced).isValid() ? dayjs(invoiceData.dateInvoiced) : null,
      customerPo: invoiceData.customerPo,
    });
    setEditDetails(true);
  };
  const updateEditDetailsDate = (date) => {
    let dateVal = dayjs(date).startOf("day");
    dateVal = dateVal.toJSON();
    setDetailsData((prev) => ({ ...prev, dateInvoiced: dateVal }));
  };

  const closeEditDetails = () => {
    setEditDetails(false);
    setDetailsData({
      dateInvoiced: null,
      customerPo: null,
    });
  };

  const submitEditDetails = () => {
    let payload = {};
    if (
      detailsData.dateInvoiced &&
      dayjs(detailsData.dateInvoiced).isValid() &&
      detailsData.dateInvoiced !== invoiceData.dateInvoiced &&
      dayjs(detailsData.dateInvoiced).isBefore(dayjs())
    ) {
      payload.dateInvoiced = detailsData.dateInvoiced;
    }
    if (detailsData.customerPo && detailsData.customerPo !== invoiceData.customerPo) {
      payload.customerPo = detailsData.customerPo;
    }
    if (Object.keys(payload).length === 0) {
      toast("No changes detected, nothing to update");
      closeEditDetails();
    } else {
      UpdateInvoiceDetails(invoiceId, payload)
        .then((res) => {
          toast.success("Updated invoice details...");
          closeEditDetails();
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.response : "An error occurred");
          closeEditDetails();
        });
    }
  };

  const renderEditDetails = () => {
    return (
      <Modal
        open={editDetails}
        onClose={() => closeEditDetails()}
        onCancel={() => closeEditDetails()}
        footer={[
          <div className="flex items-center justify-end w-full gap-4">
            <Button onClick={() => closeEditDetails()}>Cancel</Button>
            <Button onClick={() => submitEditDetails()} type="primary">
              Update
            </Button>
          </div>,
        ]}
        title="Edit Invoice Information"
        centered
        destroyOnClose
        width={680}
      >
        <div className="flex flex-col items-start justify-start w-full gap-4 py-2">
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Invoice Date</p>
            <DatePicker
              placeholder="Select Invoice Date"
              format={"MM/DD/YYYY"}
              onChange={(date) => updateEditDetailsDate(date)}
              defaultValue={detailsData && detailsData.dateInvoiced && dayjs(detailsData.dateInvoiced).isValid() ? dayjs(detailsData.dateInvoiced) : null}
              className="w-full"
            />
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Customer's PO Number</p>
            <Input
              placeholder="Customer's PO Number"
              onChange={(e) => setDetailsData((prev) => ({ ...prev, customerPo: e.target.value }))}
              defaultValue={detailsData && detailsData.customerPo ? detailsData.customerPo : null}
              className="w-full px-4 py-1.5 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
            />
          </div>
        </div>
      </Modal>
    );
  };

  const openTermsAndTax = () => {
    setTermsAndTaxData({
      terms: invoiceData.terms,
      taxExempt: invoiceData.taxExempt,
      shipping: invoiceData.shipping,
    });
    setTermsAndTax(true);
  };

  const closeTermsAndTax = () => {
    setTermsAndTax(false);
    setTermsAndTaxData({
      terms: null,
      taxExempt: false,
      shipping: 0,
    });
  };

  const submitTermsAndTax = () => {
    let payload = {};
    if (termsAndTaxData.terms && termsAndTaxData.terms !== invoiceData.terms) {
      payload.terms = termsAndTaxData.terms;
    }
    if (termsAndTaxData.taxExempt !== invoiceData.taxExempt) {
      payload.taxExempt = termsAndTaxData.taxExempt;
    }
    if (termsAndTaxData.shipping !== invoiceData.shipping) {
      payload.shipping = termsAndTaxData.shipping;
    }
    if (Object.keys(payload).length === 0) {
      toast("No changes detected, nothing to update");
      closeTermsAndTax();
    } else {
      UpdateInvoiceTermsAndTax(invoiceId, payload)
        .then((res) => {
          toast.success("Updated invoice payment terms and tax...");
          closeTermsAndTax();
          reloadData();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.response : "An error occurred");
          closeEditDetails();
        });
    }
  };

  const renderTermsAndTax = () => {
    return (
      <Modal
        open={termsAndTax}
        onClose={() => closeTermsAndTax()}
        onCancel={() => closeTermsAndTax()}
        footer={[
          <div className="flex items-center justify-end w-full gap-4">
            <Button onClick={() => closeTermsAndTax()}>Cancel</Button>
            <Button onClick={() => submitTermsAndTax()} type="primary">
              Update
            </Button>
          </div>,
        ]}
        title="Edit Invoice Payment Terms and Tax"
        centered
        destroyOnClose
        width={680}
      >
        <div className="flex flex-col items-start justify-start w-full gap-4 py-2">
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Invoice Payment Terms</p>
            <Select
              placeholder="Select Payment Terms"
              defaultValue={termsAndTaxData.terms}
              onChange={(v) => setTermsAndTaxData((prev) => ({ ...prev, terms: v }))}
              onSelect={(v) => setTermsAndTaxData((prev) => ({ ...prev, terms: v }))}
              options={[
                { label: "NET 10", value: "NET 10" },
                { label: "NET 15", value: "NET 15" },
                { label: "NET 30", value: "NET 30" },
                { label: "NET 45", value: "NET 45" },
                { label: "NET 60", value: "NET 60" },
                { label: "NET 90", value: "NET 90" },
                { label: "Collect On Delivery", value: "COD" },
              ]}
              className="w-full font-sans"
              controls={false}
              showSearch
              filterOption={filterOption}
            />
          </div>
          <div className="flex items-center justify-between w-full gap-1">
            <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Tax Exempt</p>
            <Switch defaultChecked={termsAndTaxData.taxExempt} onChange={(v) => setTermsAndTaxData((prev) => ({ ...prev, taxExempt: v }))} />
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Shipping / Delivery Fee</p>
            <InputNumber
              placeholder="Shipping / Delivery Fee"
              defaultValue={termsAndTaxData.shipping}
              onChange={(v) => setTermsAndTaxData((prev) => ({ ...prev, shipping: v }))}
              className="w-full"
              controls={false}
              addonBefore="$"
            />
          </div>
        </div>
      </Modal>
    );
  };

  const closeLogPayment = () => {
    setLogPayment(false);
    setLogPaymentData({
      paymentAmount: null,
      paymentDate: null,
      paymentMethod: null,
    });
  };

  const submitLogPayment = () => {
    LogInvoicePayment(invoiceId, logPaymentData)
      .then((res) => {
        toast.success("Payment logged successfully");
        reloadData();
        closeLogPayment();
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.response : "An error occurred");
      });
  };

  const updatePaymentDate = (date) => {
    let dateVal = dayjs(date).startOf("day");
    dateVal = dateVal.toJSON();
    setLogPaymentData((prev) => ({ ...prev, paymentDate: dateVal }));
  };

  const logPaymentSubmitDisabled = () => {
    if (!logPaymentData.paymentMethod || !logPaymentData.paymentDate || !logPaymentData.paymentAmount) {
      return true;
    } else if (logPaymentData.paymentAmount <= 0 || isNaN(logPaymentData.paymentAmount)) {
      return true;
    } else if (dayjs(logPaymentData.paymentDate).isAfter(dayjs())) {
      return true;
    } else {
      return false;
    }
  };

  const disabledDate = (current) => {
    return current && dayjs(current).isAfter(dayjs());
  };

  const renderLogPayment = () => {
    return (
      <Modal
        open={logPayment}
        onClose={() => closeLogPayment()}
        onCancel={() => closeLogPayment()}
        footer={[
          <div className="flex items-center justify-end w-full gap-4">
            <Button onClick={() => closeLogPayment()}>Cancel</Button>
            <Button onClick={() => submitLogPayment()} type="primary" disabled={logPaymentSubmitDisabled()}>
              Log Payment
            </Button>
          </div>,
        ]}
        title="Log invoice payment"
        centered
        destroyOnClose
        width={680}
      >
        <div className="flex flex-col items-start justify-start w-full gap-4 py-2">
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Payment Method</p>
            <Select
              placeholder="Select a payment method"
              onChange={(v) => setLogPaymentData((prev) => ({ ...prev, paymentMethod: v }))}
              onSelect={(v) => setLogPaymentData((prev) => ({ ...prev, paymentMethod: v }))}
              options={[
                { label: "Cash", value: "Cash" },
                { label: "Card", value: "Card" },
                { label: "Check", value: "Check" },
                { label: "Wire Transfer", value: "Wire Transfer" },
                { label: "ACH", value: "ACH" },
                { label: "PayPal", value: "PayPal" },
                { label: "Other", value: "Other" },
                { label: "Refund", value: "Refund" },
                { label: "Credit", value: "Credit" },
              ]}
              className="w-full font-sans"
              controls={false}
              showSearch
              filterOption={filterOption}
            />
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Payment Date</p>
            <DatePicker
              placeholder="Select Payment Date"
              format={"MM/DD/YYYY"}
              onChange={(date) => updatePaymentDate(date)}
              className="w-full"
              disabledDate={disabledDate}
            />
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <p className="text-xs font-semibold leading-normal tracking-wide uppercase">Payment Amount</p>
            <InputNumber
              placeholder="Payment Amount"
              onChange={(v) => setLogPaymentData((prev) => ({ ...prev, paymentAmount: v }))}
              className="w-full"
              controls={false}
              addonBefore="$"
              addonAfter="USD"
              step={0.01}
              min={0}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const collectPayment = () => {
    //Open modal to pick between collecting card payment and collecting a payment with terminal
    Modal.confirm({
      title: "Collect Payment",
      content: "How would you like to collect payment?",
      centered: true,
      width: 620,
      footer: [
        <div className="flex items-center justify-end w-full gap-2 mt-2">
          <Button onClick={() => toast("This feature is not yet available, come back later!")}>Enter Card</Button>
          <Button onClick={() => toast("This feature is not yet available, come back later!")}>Terminal Payment</Button>
        </div>,
      ],
      destroyOnClose: true,
      maskClosable: true,
      onCancel: () => {},
      onOk: () => {},
    });
  };

  const openCustomerNotes = () => {
    if (customer?.notes?.length > 0) {
      setCustomerNotesModal(true);
    } else {
      toast("This customer has no notes");
    }
  };

  const renderCustomerNotesModal = () => {
    return (
      <Modal
        open={customerNotesModal}
        onClose={() => setCustomerNotesModal(false)}
        onCancel={() => setCustomerNotesModal(false)}
        title="Customer Notes"
        centered
        destroyOnClose
        width={680}
        footer={null}
      >
        <div className="flex flex-col items-start justify-start w-full gap-4 py-2 max-h-[50vh] overflow-y-auto">
          {(customer?.notes || []).map((note, idx) => (
            <CustomerNote index={idx} note={note} />
          ))}
        </div>
      </Modal>
    );
  };

  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <div className="flex-grow flow-root w-full h-full px-2 mb-2">
        <div className="inline-block w-full h-full min-w-full py-2 align-middle">
          {loading ? (
            <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">Loading...</p>
          ) : (
            <div className="flex flex-col items-center justify-start w-full h-full">
              <div className="flex flex-row items-center justify-between w-full mb-2 border-b border-gray-200">
                <div className="flex flex-row items-center justify-start gap-2">
                  <div className="flex flex-col items-start justify-center mr-4">
                    <p className="text-xl font-semibold">Invoice {invoiceData.invoiceNo}</p>
                    <p className="text-sm text-gray-500 font-base">Status: {invoiceData.invoiceStatus}</p>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-end gap-2">
                  <Button onClick={() => openCustomerNotes()}>Customer Notes</Button>
                  <Button onClick={() => window.open(`/customers/${invoiceData.customerId}`, "_blank")}>Open Customer</Button>
                  <Button onClick={() => navigate("/operations/invoices")}>Go back</Button>
                </div>
              </div>
              <div className="grid items-start justify-center w-full grid-cols-3 gap-4 p-2 h-fit">
                <div className="flex flex-col items-start justify-start w-full h-full gap-2 p-6 font-sans bg-white rounded-md shadow-md shadow-gray-200">
                  <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-xl font-bold text-black">Sold To</p>
                    <Button onClick={() => openEditSoldTo()}>Edit</Button>
                  </div>
                  <div className="flex flex-col items-start justify-start flex-1 w-full">
                    <div className="flex flex-row items-center justify-between w-full mt-4">
                      <p className="font-semibold text-slate-800">Customer:</p>
                      <p className="text-xs text-right">{invoiceData?.customerLabel}</p>
                    </div>
                    <div className="flex flex-row items-center justify-between w-full">
                      <p className="font-semibold text-slate-800">Name:</p>
                      <p className="text-xs text-right">{invoiceData?.soldTo?.name}</p>
                    </div>
                    <div className="flex flex-row items-center justify-between w-full">
                      <p className="font-semibold text-slate-800">Contact Person:</p>
                      <p className="text-xs text-right">{invoiceData?.soldTo?.contactPerson}</p>
                    </div>
                    <div className="flex flex-row items-center justify-between w-full">
                      <p className="font-semibold text-slate-800">Address:</p>
                      <p className="text-xs text-right">
                        {invoiceData?.soldTo?.address?.address1}
                        {invoiceData?.soldTo?.address?.address2?.length ? ` ${invoiceData?.soldTo?.address?.address2}` : " "},{" "}
                        {invoiceData?.soldTo?.address?.city},{invoiceData?.soldTo?.address?.state} {invoiceData?.soldTo?.address?.zip}
                      </p>
                    </div>
                    <div className="flex flex-row items-center justify-between w-full">
                      <p className="font-semibold text-slate-800">Phone Number:</p>
                      <p className="text-xs text-right">{formatPhoneNumber(invoiceData?.soldTo?.phone)}</p>
                    </div>
                    <div className="flex flex-row items-center justify-between w-full">
                      <p className="font-semibold text-slate-800">Email Address:</p>
                      <p className="text-xs text-right">{validator.isEmail(invoiceData?.soldTo?.email) ? invoiceData?.soldTo?.email : "Not Provided"}</p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start w-full h-full gap-2 p-6 font-sans bg-white rounded-md shadow-md shadow-gray-200">
                  <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-xl font-bold text-black">Invoice Details</p>
                    <Button onClick={() => openEditDetails()}>Edit</Button>
                  </div>
                  <div className="flex flex-col items-start justify-start flex-1 w-full">
                    <div className="flex flex-row items-center justify-between w-full mt-4">
                      <p className="font-semibold text-slate-800">Invoice Date:</p>
                      <p className="text-xs text-right">{dayjs(invoiceData?.dateInvoiced).format("MM/DD/YYYY")}</p>
                    </div>
                    <div className="flex flex-row items-center justify-between w-full">
                      <p className="font-semibold text-slate-800">Customer's PO#:</p>
                      <p className="text-xs text-right">
                        {invoiceData?.customerPo && invoiceData?.customerPo?.length > 0 ? invoiceData?.customerPo : "Not provided"}
                      </p>
                    </div>
                    <div className="flex flex-row items-center justify-between w-full">
                      <p className="font-semibold text-slate-800">Created by:</p>
                      <p className="text-xs text-right">{invoiceData?.invoicedBy}</p>
                    </div>
                    <div className="flex flex-row items-center justify-between w-full">
                      <p className="font-semibold text-slate-800">Created at:</p>
                      <p className="text-xs text-right">{dayjs(invoiceData.createdAt).format("MM/DD/YYYY hh:mm A")}</p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start w-full h-full gap-2 p-6 font-sans bg-white rounded-md shadow-md shadow-gray-200">
                  <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-xl font-bold text-black">Payment Terms and Tax</p>
                    <Button onClick={() => openTermsAndTax()}>Edit</Button>
                  </div>
                  <div className="flex flex-col items-start justify-start flex-1 w-full">
                    <div className="flex flex-row items-center justify-between w-full mt-4">
                      <p className="font-semibold text-slate-800">Due Date:</p>
                      <p className="text-xs text-right">{dayjs(invoiceData?.dueDate).format("MM/DD/YYYY")}</p>
                    </div>
                    <div className="flex flex-row items-center justify-between w-full">
                      <p className="font-semibold text-slate-800">Payment Terms:</p>
                      <p className="text-xs text-right">{invoiceData?.terms}</p>
                    </div>
                    <div className="flex flex-row items-center justify-between w-full">
                      <p className="font-semibold text-slate-800">Tax Exempt:</p>
                      <p className="text-xs text-right">{invoiceData?.taxExempt ? "Yes" : "No"}</p>
                    </div>
                    <div className="flex flex-row items-center justify-between w-full">
                      <p className="font-semibold text-slate-800">Shipping/Delivery Fee:</p>
                      <p className="text-xs text-right">{formatCurrency(invoiceData.shipping)}</p>
                    </div>
                    <div className="flex flex-row items-center justify-between w-full">
                      <p className="font-semibold text-slate-800">Payments to date:</p>
                      <p className="text-xs text-right">{formatCurrency(invoiceData.payments.reduce((acc, item) => acc + item.paymentAmount, 0))}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid items-start justify-center w-full grid-cols-3 gap-4 p-2 h-fit">
                <div className="flex flex-col items-start justify-start w-full h-full col-span-3 gap-2 p-6 mt-4 font-sans bg-white rounded-md shadow-md shadow-gray-200">
                  <div className="flex items-center justify-between w-full">
                    <p className="text-xl font-bold text-black">Line Items</p>
                    <Dropdown menu={menuProps}>
                      <Button icon={<ChevronDown size={16} className="mt-0.5 -mr-1" />} iconPosition="end">
                        Add Line Items
                      </Button>
                    </Dropdown>
                  </div>
                  <Table dataSource={invoiceData.items} rowKey="itemId" pagination={false} className="w-full mt-4" loading={loading}>
                    <Table.Column
                      title="Type"
                      dataIndex="type"
                      key="type"
                      width={75}
                      render={(v) => <div className="flex items-center justify-center">{renderType(v)}</div>}
                    />
                    <Table.Column
                      title="Product Category"
                      dataIndex="product"
                      key="categoryName"
                      render={(v, r) => (r.type === "Product" ? `[${r.productData.categoryCode}] - ${r.productData.categoryName}` : `${r.type} Charge`)}
                    />
                    <Table.Column title="Description" dataIndex="description" key="description" className="truncate" width={300} />
                    <Table.Column align="right" title="Unit Price" dataIndex="unitPrice" key="unitPrice" render={(v) => formatCurrency(v)} />
                    <Table.Column align="right" title="Quantity" dataIndex="quantity" key="quantity" />
                    <Table.Column align="right" title="Total" dataIndex="total" key="total" render={(v) => formatCurrency(v)} />
                    <Table.Column
                      dataIndex="itemId"
                      key="itemId"
                      render={(v) => (
                        <div className="flex items-center justify-end gap-2">
                          <Button onClick={() => deleteItem(v)} icon={<Trash size={16} />} danger />
                          <Button
                            onClick={() => editItem(v)}
                            icon={<Edit size={16} className="text-gray-400 transition-all duration-200 group-hover:text-blue-500" />}
                            className="group"
                          />
                        </div>
                      )}
                    />
                  </Table>
                </div>
              </div>
              <div className="grid items-start justify-center w-full grid-cols-2 gap-4 p-2 h-fit">
                <div className="flex flex-col items-start justify-start w-full h-full gap-2 p-6 mt-4 font-sans bg-white rounded-md shadow-md shadow-gray-200">
                  <div className="flex items-center justify-between w-full">
                    <p className="text-xl font-bold text-black">Payments</p>
                    <div className="flex items-center justify-end gap-2">
                      <Button onClick={() => setLogPayment(true)}>Log</Button>
                      <Button onClick={() => collectPayment()}>Collect</Button>
                    </div>
                  </div>
                  {invoiceData.payments.length > 0 &&
                    invoiceData.payments.filter((n) => n.deleted !== true).map((pmt, index) => <Payment key={index} data={pmt} />)}
                </div>
                <div className="flex flex-col items-start justify-start w-full h-full gap-2 p-6 mt-4 font-sans bg-white rounded-md shadow-md shadow-gray-200">
                  <p className="text-xl font-bold text-black">Summary</p>
                  <div className="flex flex-row items-center justify-between w-full pt-4 mt-4 border-t border-gray-300">
                    <p className="font-semibold text-slate-800">Sale Amount</p>
                    <p>{formatCurrency(getTotal())}</p>
                  </div>
                  <div className="flex flex-row items-center justify-between w-full">
                    <p className="font-semibold text-slate-800">Shipping / Delivery fees</p>
                    <p>{formatCurrency(getShippingTotal())}</p>
                  </div>
                  <div className="flex flex-row items-center justify-between w-full">
                    <p className="font-semibold text-slate-800">Sales Tax</p>
                    <p>{formatCurrency(invoiceData.salesTax)}</p>
                  </div>
                  <div className="flex flex-row items-center justify-between w-full pt-1 border-t border-gray-200">
                    <p className="font-semibold text-slate-800">Subtotal</p>
                    <p>{formatCurrency(getTotal() + invoiceData.salesTax + getShippingTotal())}</p>
                  </div>
                  <div className="flex flex-row items-center justify-between w-full pt-1 border-t border-gray-200">
                    <p className="text-base font-bold text-black">Total</p>
                    <p className="font-semibold text-black">{formatCurrency(invoiceData.total)}</p>
                  </div>
                  <div className="flex flex-row items-center justify-between w-full pt-1 border-t border-gray-200">
                    <p className="font-semibold text-slate-800">Payments Made</p>
                    <p>{formatCurrency(invoiceData.payments.reduce((acc, item) => (!item.deleted ? acc + item.paymentAmount : acc), 0))}</p>
                  </div>
                  <div className="flex flex-row items-center justify-between w-full">
                    <p className="font-semibold text-slate-800">Amount Due</p>
                    <p>{formatCurrency(invoiceData.payments.reduce((acc, item) => !item.deleted && acc - item.paymentAmount, invoiceData.total))}</p>
                  </div>
                </div>
              </div>
              <div className="grid items-start justify-center w-full grid-cols-3 gap-4 p-2 h-fit">
                <div className="flex flex-col items-start justify-start w-full h-full col-span-3 gap-2 p-6 mt-4 font-sans bg-white rounded-md shadow-md shadow-gray-200">
                  <div className="flex items-center justify-between w-full mb-2">
                    <p className="text-xl font-bold text-black">Notes</p>
                    <Button onClick={() => setNoteModal(true)}>New Note</Button>
                  </div>
                  {invoiceData.notes.length > 0 && invoiceData.notes.filter((n) => n.deleted !== true).map((note, index) => <Note key={index} note={note} />)}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {renderAddProductModal()}
      {renderServiceAndOtherModal()}
      {renderCreditModal()}
      {renderNoteModal()}
      {renderEditSoldToModal()}
      {renderEditDetails()}
      {renderTermsAndTax()}
      {renderLogPayment()}
      {renderCustomerNotesModal()}
    </div>
  );
};

export default OpenInvoice;
