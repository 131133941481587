import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AddNoteToCustomer, GetACustomerByID } from "../../../actions/customer";
import { City, WarningTriangle } from "iconoir-react";
import { Button, Input, Modal } from "antd";
import CustomerGeneralInfo from "./cards/generalInfo";
import CustomerCreditInfo from "./cards/creditInfo";
import CustomerDefaultContact from "./cards/defaultContact";
import CustomerDefaultAddress from "./cards/defaultAddress";
import CustomerInvoiceHistory from "./cards/invoiceHistory";
import CustomerNote from "./cards/note";

const CustomerNotes = () => {
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [noteModal, setNoteModal] = useState(false);
  const [noteData, setNoteData] = useState(null);

  const { customerId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    reloadData();
  }, [customerId]);

  const tabs = [
    { name: "General Info", href: "", current: false },
    { name: "Contacts", href: "/contacts", current: false, mock: true },
    { name: "Addresses", href: "/addresses", current: false, mock: true },
    { name: "Shipping", href: "/shipping", current: false, mock: true },
    { name: "Credit", href: "/credit", current: false, mock: true },
    { name: "Pricing", href: "/pricing", current: false, mock: true },
    { name: "Notes", href: "/notes", current: true, mock: false },
    { name: "History", href: "/history", current: false, mock: true },
    { name: "Reports", href: "/reports", current: false, mock: true },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const reloadData = () => {
    setLoading(true);
    GetACustomerByID(customerId)
      .then((res) => {
        setCustomer(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 700);
      })
      .catch((err) => {
        toast.error(err.message);
        navigate("/customers");
      });
  };

  const handleTabChange = (tab) => {
    if (tab.mock) {
      toast("This feature is not yet available, check again soon!");
    } else {
      navigate(`/customers/${customerId}${tab.href}`);
    }
  };

  const closeNotes = () => {
    setNoteModal(false);
    setNoteData(null);
  };

  const submitNote = () => {
    if (!noteData || noteData.length < 5) {
      toast.error("Note must be at least 5 characters long.");
      return;
    }
    setLoading(true);
    AddNoteToCustomer(customerId, { note: noteData })
      .then((res) => {
        toast.success("Note added successfully!");
        closeNotes();
        reloadData();
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  };

  const renderNoteModal = () => {
    return (
      <Modal
        open={noteModal}
        onCancel={() => closeNotes()}
        onClose={() => closeNotes()}
        title="Add Note"
        onOk={() => submitNote()}
        centered
        destroyOnClose
        width={550}
      >
        <Input.TextArea
          placeholder="Type your note here..."
          onChange={(e) => setNoteData(e.target.value)}
          rows={6}
          defaultValue={noteData}
          autoSize={false}
          className="w-full !resize-none font-sans py-2 px-4 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
        />
      </Modal>
    );
  };

  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <div className="flex-grow flow-root w-full px-2">
        <div className="inline-block w-full min-w-full py-2 align-middle">
          {loading ? (
            <p className="w-full text-sm font-semibold text-center text-gray-500 uppercase">Loading...</p>
          ) : (
            <div className="flex flex-col items-center justify-start w-full h-full">
              <div className="flex flex-row items-center justify-between w-full pb-4 border-b border-gray-200">
                <div className="flex flex-row items-center justify-start gap-2">
                  <div className="flex flex-col items-start justify-center mr-4">
                    <p className="text-xl font-semibold">
                      {customer.customerNo} - {customer.name}
                    </p>
                    <p className="text-sm font-semibold text-gray-500">Primary Contact: {customer.contacts.find((c) => c.primary)?.name || "Not set"}</p>
                  </div>
                  {customer.holdStatus !== "No Credit Hold" && (
                    <div className="center relative inline-block select-none whitespace-nowrap rounded-md bg-red-200 border border-red-300 shadow-sm py-1.5 px-3.5 align-baseline font-sans text-xs font-semibold uppercase leading-none text-red-600 tracking-wide">
                      <div className="absolute w-5 h-5 -translate-y-1/2 top-2/4 left-2.5">
                        <WarningTriangle className="w-auto h-5" strokeWidth={2} />
                      </div>
                      <div className="mt-px ml-5">HOLD: {customer.holdStatus}</div>
                    </div>
                  )}
                  {customer.company && (
                    <div className="center relative inline-block select-none whitespace-nowrap rounded-md bg-yellow-200/70 border border-yellow-300 shadow-sm py-1.5 px-3.5 align-baseline font-sans text-xs font-semibold uppercase leading-none text-yellow-600 tracking-wide">
                      <div className="absolute w-5 h-5 -translate-y-1/2 top-2/4 left-2.5">
                        <City className="w-auto h-5" strokeWidth={2} />
                      </div>
                      <div className="mt-px ml-5">Company</div>
                    </div>
                  )}
                </div>
                <div className="flex flex-row items-center justify-end gap-2">
                  <Button
                    onClick={() =>
                      window.open(
                        `/sale?customerId=${customerId}&customerNo=${customer.customerNo}&name=${customer.name}&primaryContact=${
                          customer.contacts.find((c) => c.primary)?.name
                        }&zipCode=${customer.addresses.find((a) => a.primary)?.zip}`,
                        "_blank",
                      )
                    }
                  >
                    New Sale
                  </Button>
                  <Button onClick={() => toast("This feature is not yet available, check again soon!")}>History</Button>
                  <Button onClick={() => toast("This feature is not yet available, check again soon!")}>Reports</Button>
                </div>
              </div>
              <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                  Select a tab
                </label>
                <select
                  id="tabs"
                  name="tabs"
                  className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-wbs-red focus:outline-none focus:ring-wbs-red sm:text-sm"
                  defaultValue={tabs.find((tab) => tab.current).name}
                >
                  {tabs.map((tab) => (
                    <option key={tab.name}>{tab.name}</option>
                  ))}
                </select>
              </div>
              <div className="hidden w-full sm:block">
                <div className="border-b border-gray-200">
                  <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                    {tabs.map((tab) => (
                      <p
                        onClick={() => handleTabChange(tab)}
                        className={classNames(
                          tab.current
                            ? "border-wbs-red text-wbs-red hover:border-slate-950 hover:text-slate-950"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer transition-all duration-200",
                        )}
                        aria-current={tab.current ? "page" : undefined}
                      >
                        {tab.name}
                      </p>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="grid w-full grid-cols-1 gap-4 mt-4">
                <div className="flex items-center justify-between w-full">
                  <h2 className="text-lg font-semibold text-gray-900">Customer notes</h2>
                  <Button onClick={() => setNoteModal(true)}>Add Note</Button>
                </div>
                {customer.notes.length > 0 ? (
                  <div className="flex flex-col items-center justify-start w-full gap-4 px-2">
                    {customer.notes.map((note, index) => (
                      <CustomerNote key={index} note={note} />
                    ))}
                  </div>
                ) : (
                  <div className="flex items-center justify-center w-full py-5">
                    <p className="text-base font-semibold text-slate-600">
                      No notes added -{" "}
                      <span
                        onClick={() => setNoteModal(true)}
                        className="text-blue-600 underline transition-all duration-200 cursor-pointer underline-offset-4 hover:text-blue-400"
                      >
                        Add Note
                      </span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {renderNoteModal()}
    </div>
  );
};

export default CustomerNotes;
