import { useState, useEffect } from "react";
import { Button, Skeleton } from "antd";
import GaugeComponent from "react-gauge-component";
import { formatCurrency, priceTiers } from "../../../../data/utils";
import { GetCreditInfo } from "../../../../actions/customer";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const CustomerCreditInfo = ({ customer }) => {
  const [loading, setLoading] = useState(true);
  const [creditInfo, setCreditInfo] = useState(null);
  const [noCredit, setNoCredit] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!customer) return;
    if (customer.creditLimit === 0) {
      setCreditInfo({
        creditLimit: 0,
        availableCredit: 0,
        outstandingBalance: 0,
      });
      setNoCredit(true);
      setTimeout(() => setLoading(false), 700);
    } else {
      GetCreditInfo(customer.customerId).then((res) => {
        setCreditInfo(res.data);
        setTimeout(() => setLoading(false), 700);
      });
    }
  }, []);

  const calculateUsage = () => {
    let usage = 0;
    let limit = 0;
    if (!noCredit && creditInfo) {
      limit = creditInfo.creditLimit ?? 0;
      usage = (100 * creditInfo.outstandingBalance ?? 0) / limit;
    }
    return usage;
  };

  const renderUsageChart = () => {
    if (!loading) {
      if (!noCredit) {
        return (
          <div className="flex flex-col items-center justify-center w-1/2 h-full">
            <GaugeComponent
              arc={{
                subArcs: [
                  {
                    limit: 20,
                    color: "#5BE12C",
                    showTick: true,
                  },
                  {
                    limit: 40,
                    color: "#F5CD19",
                    showTick: true,
                  },
                  {
                    limit: 60,
                    color: "#F58B19",
                    showTick: true,
                  },
                  {
                    limit: 80,
                    color: "#EA4228",
                    showTick: true,
                  },
                  {
                    limit: 100,
                    color: "#b91c1c",
                    showTick: true,
                  },
                ],
                cornerRadius: 5,
              }}
              value={calculateUsage() > 100 ? 100 : calculateUsage()}
              type="semicircle"
              labels={{
                valueLabel: {
                  style: { fontSize: "35px", fill: "#3d4044", textShadow: "none", fontFamily: "Inter", fontWeight: 600 },
                  hide: true,
                },
              }}
              pointer={{
                color: "#e5e7eb",
              }}
              style={{
                height: "auto",
                width: "100%",
              }}
            />
            <p className={`text-lg ${calculateUsage() > 90 ? "text-red-600 font-bold" : "text-slate-800 font-semibold"}`}>
              Credit Usage: {calculateUsage()}%{calculateUsage() > 90 && customer.holdStatus === "No Credit Hold" ? ` [HIGH]` : ``}
              {customer.holdStatus === "Overridden" ? ` [OVERRIDDEN]` : ``}
              {customer.holdStatus === "On Hold" ? ` [ON HOLD]` : ``}
            </p>
          </div>
        );
      } else {
        return (
          <div className="flex flex-col items-center justify-center w-1/2 h-full">
            <p className="text-base font-semibold text-slate-800">Line Of Credit Not Provided</p>
          </div>
        );
      }
    }
  };

  return (
    <div className="flex flex-col items-start justify-start w-full gap-2 p-6 mt-4 font-sans bg-white rounded-md shadow-md shadow-gray-200">
      <div className="flex flex-row items-center justify-between w-full">
        <p className="text-xl font-bold text-black">Credit Information</p>
        {/* <Button onClick={() => navigate(`/customers/${customer.customerId}/credit`)} disabled={loading}> */}
        <Button onClick={() => toast("This feature is not yet available, check again soon!")} disabled={loading}>
          View / Edit
        </Button>
      </div>
      {loading ? (
        <div className="flex flex-row items-center justify-center w-full h-full gap-2">
          <Skeleton active />
        </div>
      ) : (
        <div className="flex flex-row items-center justify-between w-full h-full gap-2 mt-4">
          <div className="flex flex-col items-center justify-center w-1/2 h-full">
            <div className="flex flex-row items-center justify-between w-full">
              <p className="font-semibold text-slate-800">Credit Limit</p>
              <p>{formatCurrency(customer.creditLimit)}</p>
            </div>
            {!noCredit && (
              <>
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">Credit Usage</p>
                  <p>{formatCurrency(creditInfo.outstandingBalance)}</p>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">Available Credit</p>
                  <p>{!noCredit && creditInfo && creditInfo.availableCredit ? formatCurrency(creditInfo.availableCredit) : formatCurrency(0)}</p>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="font-semibold text-slate-800">Credit Hold</p>
                  <p>
                    {customer.holdStatus === "No Credit Hold" ? customer.holdStatus : <span className="font-bold text-red-500">{customer.holdStatus}</span>}
                  </p>
                </div>
              </>
            )}
            <div className="flex flex-row items-center justify-between w-full">
              <p className="font-semibold text-slate-800">Default Price Tier</p>
              <p>{priceTiers(customer.priceCode)}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
              <p className="font-semibold text-slate-800">Credit Check</p>
              <p>{customer.creditCheck.references.length} References</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
              <p className="font-semibold text-slate-800">Credit Notes</p>
              <p>{customer.creditCheck.notes.length} Notes</p>
            </div>
          </div>
          {renderUsageChart()}
        </div>
      )}
    </div>
  );
};

export default CustomerCreditInfo;
